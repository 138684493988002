import { useMemo, useEffect, useCallback, useState } from "react";
import MapCanvas from "./MapCanvas";
import T, { useT } from "../../components/languages/t";
import UpArrow from "../../img/up.svg";
import DownArrow from "../../img/down.svg";
import { Redirect, Route, Switch, useLocation, useRoute } from "wouter";
import VehiclesLive from "./VehiclesLive";
import ZonesLive from "./ZonesLive";
import { atom, useRecoilState } from "recoil";
import { AppStore } from "../../store/store";

export const LiveVehicleState = atom({
  key: "LiveVehicleState",
  default: [],
})

export const MarkerPopupState = atom({
  key: "MarkerPopupState",
  default: {
    type: "UNDEFINED",
    marker: null,
    coords: [],
    data: {},
    fly: false
  },
})

const { FM_API_URL } = process.env;

const Map = () => {
  const { t } = useT();
  const [appState, setAppState] = useRecoilState(AppStore)
  const [vehicleState, setVehicleState] = useRecoilState(LiveVehicleState)
  const [isContainerOpen, setContainerOpen] = useState(true);

  useEffect(() => {
    if (appState.token) {
      fetch(`${FM_API_URL}/parkings/live?s=10000`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + appState.token,
        },
      }).then(async (r) => {
        const resp = await r.json()
        if (resp.success) {
          setVehicleState(resp.data)
        }
      })
    }
  }, [appState.token]);


  const handleToggleContainer = () => {
    setContainerOpen(!isContainerOpen)
  }

  const openContainer = () => {
    setContainerOpen(true)
  }

  return <div className="flex w-full relative h-full">
    <div className="flex">
      <div className="flex flex-col z-20 bg-white m-10 rounded-md min-w-[500px] max-w-[760px] shadow-lg overflow-hidden"
        style={{
          maxHeight: "calc(100% - 80px)",
          height: isContainerOpen ? "auto" : "87px"
        }}
      >
        <div className="flex flex-col bg-white max-h-[200px] relative p-3 rounded-md">
          <nav className="flex w-full flex-1">
            <ul
              className="-mb-px  w-full flex justify-center items-center text-sm font-medium divide-solid rounded-lg shadow-[0px_0px_4px_rgba(0,0,0,0.1)]"
              id="linkContainer"
            >
              <TabLink to="/map/vehicles" onClick={openContainer}>
                <T>Vehicles</T>
              </TabLink>

              <TabLink to="/map/zones" onClick={openContainer}>
                <T>Zones</T>
              </TabLink>
              <div
                className="p-4 transition-transform duration-300 ease-in-out transform pointer-events-auto cursor-pointer"
                onClick={handleToggleContainer}
                style={{
                  background: "#F9FAFB",
                  transition: "transform 0.3s ease-in-out",
                  height: "58px",
                }}
              >
                {isContainerOpen ? <UpArrow /> : <DownArrow />}
              </div>
            </ul>
          </nav>
        </div>
        {isContainerOpen &&
          <Switch>
            <Route path="/map/vehicles/:rest*">
              <VehiclesLive />
            </Route>
            <Route path="/map/zones/:rest*" component={ZonesLive} />
            <Route><Redirect to="/map/vehicles" /></Route>
          </Switch>}
      </div>
    </div>
    <div className="l-0 z-10 absolute w-full h-full">
      <MapCanvas />
    </div>
  </div>
}

export default Map

function TabLink({ to, onClick, children }) {
  const isSpinnTheme = process.env.THEME_CONFIG === "Spinn";
  const [_, navigate] = useLocation();

  const [isActive] = useRoute(to + '/:rest*');

  return (
    <li className="w-full">
      <div
        className={`cursor-pointer   font-semibold flex items-center justify-center text-lg gap-2 p-4 text-[#1b1c20] ${isSpinnTheme ? "hover:text-[#5342CB]" : "hover:text-[#e94f0a]"}  border-b-2 border-r-[1px] border-r-[#D1D5DB] 
        ${isSpinnTheme && isActive ? "border-[#5342CB]" : ""
          }
        ${isActive && !isSpinnTheme ? "border-[#e94f0a]" : ""
          } 
         
        hover:border-gray-200`}
        onClick={() => {
          onClick()
          navigate(to)
        }}
      >
        {children}
      </div>
    </li>
  );
}
