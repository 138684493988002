{
  "pleaseWaitSuspense": "Palun oota",
  "dashboardMenuItem": "Status",
  "logoutMenuItem": "Logga ut",
  "codesMenuItem": "Tilltr\u00e4deskoder",
  "logMenuItem": "Loggbok",
  "usersMenuItem": "Anv\u00e4ndare",
  "errorOpeningLock": "Kunde inte \u00f6ppna l\u00e5set!",
  "dashboardHeading": "L\u00e5sstatus",
  "codesHeading": "Tillträdeskoder",
  "addNewCodeBtnTxt": "Lägg till ny kod",
  "statusColumn": "Status",
  "typeColumn": "Typ",
  "codeColumn": "Kod",
  "commentColumn": "Kommentar",
  "zoneColumn": "Område",
  "lockColumn": "Lås",
  "locksColumn": "Lås",
  "logsHeading": "Loggbok",
  "resultColumn": "Resultat",
  "timeColumn": "Tid",
  "usersHeading": "Användare",
  "addNewUserBtnTxt": "Lägg till ny användare",
  "nameColumn": "Namn",
  "emailColumn": "E-postadress",
  "providerNameColumn": "Tillhör gruppen",
  "mayManageColumn": "Kan förvalta grupperna",
  "searchPlaceholder": "Sök...",
  "lockLastSeenAt": "Senast sedd",
  "queryDoneAt": "Kontrollerat",
  "stopStatusQuery": "Avsluta kontroll",
  "startStatusQuery": "Påbörja kontroll",
  "openLockBtnTxt": "Öppna låset",
  "dd.MM.yyyy 'kell' kk:mm": "dd.MM.yyyy 'klockan' kk:mm",
  "dd.MM.yyyy 'kell' kk:mm:ss": "dd.MM.yyyy 'klockan' kk:mm:ss",
  "ONLINE_ERROR": "Offline",
  "ONLINE_WARNING": "Online",
  "ONLINE_SUCCESS": "Online",
  "UNKNOWN_LOCK_STATE": "--",
  "CLOSED_LOCK_STATE": "Stängd",
  "OPEN_LOCK_STATE": "Öppen",
  "tableFooterShowRows": "Visa",
  "tableFooterPage": "Sida",
  "ENABLED": "Aktiverad",
  "SINGLE_USE": "Engångs",
  "DISABLED": "Inaktiverad",
  "MSISDN": "Mobil",
  "PIN" : "Pin",
  "RFID": "RFID",
  "ACCEPT": "ACCEPTERAD",
  "DENY": "FÖRNEKAD",
  "ERROR": "FEL",
  "btnDeleteTxt": "Radera",
  "orTxt": "eller",
  "closeLinkTxt": "stäng",
  "deleteConfirmationTxt": "Vill du verkligen radera?",
  "resetLinkTxt": "återställ",
  "saveBtnTxt": "Spara",
  "idField": "ID",
  "statusField": "Status",
  "typeField": "Typ",
  "codeField": "Kod",
  "commentField": "Kommentar",
  "zoneField": "Område",
  "lockField": "Lås",
  "locksField": "Lås",
  "resultField": "Resultat",
  "timeField": "Tid",
  "nameField": "Namn",
  "emailField": "E-postadress",
  "providerNameField": "Tillhör gruppen",
  "mayManageField": "Kan förvalta grupperna",
  "validFromField": "Giltig från",
  "validToField": "Giltig till",
  "updatedAtField": "Uppdaterad vid",
  "createdAtField": "Skapad vid",
  "dataField": "Uppgifter",
  "passwordField": "Lösenord",
  "zonePlaceholderTxt": "Välj åmrådet där koden är giltig",
  "codePlaceholderTxt": "Skriv in koden enligt instruktionerna nedan",
  "providerPlaceholderTxt": "Välj den grupp som koden tillhör",
  "validToPlaceholderTxt": "Lämna fältet tomt om du vill att koden ska vara giltig för alltid",
  "commentPlaceholderTxt": "Lägg till en beskrivning här så att du senare vet vilken kod det är",
  "providerHelpTxt": "Gruppen bestämmer vem som äger koden och detta avgör i sin tur vem som har rätt till att ändra koden",
  "providerNamePlaceholderTxt": "Välj grupp",
  "emailPlaceholderTxt": "E-post adressen används för att logga in",
  "namePlaceholderTxt": "Ange namnet på användaren",
  "passwordPlaceholderTxt": "Fyll i textfältet för att ändra på lösenordet",
  "mayManagePlaceholderTxt": "Välj de grupper som användaren får managera",
  "typeHelpTxt": "Typen avgör hur låset kan öppnas:<1><2> <strong> Mobil </strong> - Du kan öppna låset genom att ringa till mobilnumret på kontrollern eller genom att ange de sista fyra siffrorna i ditt mobilnumer på knappsatsen </2> <2> <strong> PIN </strong> - Kunden kan bara låsa upp med en PIN-kod </2> <2> <strong> RFID </strong> - Kunden kan bara låsa upp med ett RFID -band/kort </2></1>",
  "statusHelpTxt": "Koden kan bara användas när statusen är <1>AKTIVERAD</1>",
  "userStatusHelpTxt": "Användaren kan bara logga in då statusen är <1>AKTIVERAD</1>",
  "locksHelpTxt": "Först efter du har valt åmrådet kan du välja vilka lås som koden ska öppna",
  "codeHelpTxt": "Om typen ovan är: <1> <0> <strong> Mobil </strong> - Så måste koden vara ett mobilnummer med en landskod, t.ex. +461234567 </0> <1> <strong> PIN </strong> - fyrsiffrigt nummer </1> <2> <strong> RFID </strong> - åttasiffrig kod t.ex. E27694A2 </2> </1>",
  "lastSignInField": "Senast inloggad vid",
  "lastSignInIPField": "Senaste IP",
  "providerNameHelpTxt": "Gruppen bestämmer vem användaren tillhör och detta avgör i sin tur vem som har rätt att ändra på användarens uppgifter"

}
