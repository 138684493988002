import * as React from 'react';

export function useValidate(state, schema) {

  const [errors, setErrors] = React.useState({})

  const validateFields = schema.describe().fields;
  const data = Object.assign({}, state.data, state.changes);

  React.useEffect(() => {
    // Validate only in edit mode
    const validationErrors = {}

      const validatedPromises = [];
      for (const f in validateFields) {
        validatedPromises.push(schema.validateAt(f, data))
      }
      Promise.allSettled(validatedPromises).then((results) => {
        for (const r of results) {
          if (r.status === 'rejected') {
            validationErrors[String(r.reason.path)] = r.reason.message;
          }
        }
      });

    setErrors(validationErrors);

  }, [
    JSON.stringify(state.data),
    JSON.stringify(state.changes)
  ])

  return { errors }

}
