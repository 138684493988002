const _temp0 = require("../../flags/dk.png");
const _temp1 = require("../../flags/en.png");
const _temp2 = require("../../flags/et.png");
const _temp3 = require("../../flags/fi.png");
const _temp4 = require("../../flags/lv.png");
const _temp5 = require("../../flags/no.png");
const _temp6 = require("../../flags/pl.png");
const _temp7 = require("../../flags/ru.png");
const _temp8 = require("../../flags/sv.png");
module.exports = {
  "dk": _temp0,
  "en": _temp1,
  "et": _temp2,
  "fi": _temp3,
  "lv": _temp4,
  "no": _temp5,
  "pl": _temp6,
  "ru": _temp7,
  "sv": _temp8
}