const FormDetails = ({ className, data, editMode }) => {

   return <div className={className}>
    {Object.keys(data).map((k, i) => <dl key={i} className={data[k].className}>
      {data[k].display || editMode && data[k].edit ? <>
        <dt key={`title_${i}`} className="mb-2 font-semibold leading-none text-gray-900">{data[k].label}{data[k].required ? <span className="ml-0.5 text-red-500">*</span>: null}</dt>
      <dd key={`display_${i}`} className="mb-2 font-light text-gray-500">
        {editMode && data[k].edit ? data[k].edit : data[k].display}
      </dd>
      </>: null }
    </dl>)}
  </div>
}

export default FormDetails
