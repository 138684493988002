import React, { useEffect, useMemo, useState } from "react";
import { useT } from "../../components/languages/t";
import { Table } from "../../components/table/table";
import { useList } from "../../useList";
import { useLocation, useRoute } from "wouter";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import differenceInMinutes from "date-fns/differenceInMinutes";
import TableHeader from "../../components/table/TableHeader";
import { useSearch } from "../../components/form/useSearch";
import SearchInput from "./SearchInput";
import { tFixed } from "../../i18n";
import TableFilter from "../../components/table/TableFilter";
import { useFilter } from "../../components/form/useFilter";
import { AppStore } from "../../store/store";
import { useRecoilState, useRecoilValue } from "recoil";

const re = /(\d{3,4})(\d{4})(\d{4})(\d{4})/;
const re2 = new RegExp(/([a-z])(?=[0-9])/gi);
const re3 = new RegExp(/([0-9])(?=[a-z])/gi);

const ParkingHistory = ({ showHistory, toggleHistory }) => {
  const { t, i18n } = useT();

  // Define the dropdown filter options

  const { list, handleListQuery } = useList("/parkings/list");

  const [fleets, setFleets] = useState({});
  const appState = useRecoilValue(AppStore);
  const { FM_API_URL } = process.env;
  useEffect(() => {
    (async () => {
      const res = await fetch(`${FM_API_URL}/fleets/all`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + appState.token,
        },
      });
      const resp = await res.json();
      const fleets = Object.values(resp.data || {}).reduce((r, v) => {
        r[v.id] = v.name;
        return r;
      }, {});
      setFleets(fleets);
    })();
  }, []);

  const filterOptions = {
    // session_ended_at: {
    //   title: t("Time Period"),
    //   options: {
    //     session_ended_at: ["YES"],
    //   },
    // },
    fleet_id: {
      title: t("Fleet"),
      options: fleets,
    },
    operator: {
      title: t(["parkingOperatorColumn", "Parking Operator"]),
      options: {
        "ee.europark": t("Europark Eesti"),
        "ee.yt": t("Ühisteenused"),
      },
    },
  };
  const { query, handleSearch, handleSearchEnter, clearSearch } = useSearch(
    "/parkings/list",
    handleListQuery
  );

  const { filters, handleFilterChange } = useFilter(
    "/parkings/list",
    (params) => {
      handleListQuery(params);
      handleListQueryWithSearch(params);
    },
    filterOptions
  );

  const handleListQueryWithSearch = (params) => {
    handleListQuery({
      ...params,
      search: query,
      session_ended_at: filters.session_ended_at,
      fleet_id: filters.fleet_id,
      operator: filters.operator,
    });

    console.log(filters, "FILTERS", list);
  };

  const [_, navigate] = useLocation();
  const [match, params] = useRoute("/parkings/:id");

  const columns = useMemo(() => {
    return [
      {
        header: t("Plate N"),
        id: "subject",
        accessorFn: (r) => {
          return (
            <span className="whitespace-nowrap tabular-nums">
              {r.subject
                .replace(/\s/g, "")
                .replace(re2, "$1 ")
                .replace(re3, "$1 ")}
            </span>
          );
        },
      },
      {
        header: t("Zone"),
        id: "locality_name",
        accessorKey: "locality_name",
      },
      {
        header: t("Parking Started"),
        id: "session_started_at",
        accessorFn: (r) => {
          if (!r.session_started_at) {
            return "--";
          }
          return format(parseISO(r.session_started_at), "dd.MM.yyyy HH:mm");
        },
      },
      {
        header: t("Parking Ended"),
        id: "session_ended_at",
        accessorFn: (r) => {
          if (!r.session_ended_at) {
            return "--";
          }
          return format(parseISO(r.session_ended_at), "dd.MM.yyyy HH:mm");
        },
      },
      {
        header: t("Total Parking"),
        id: "totalParking",
        accessorFn: (r) => {
          if (!r.session_started_at || !r.session_ended_at) {
            return "--";
          }

          const startedAt = parseISO(r.session_started_at);
          const endedAt = parseISO(r.session_ended_at);

          const totalMinutes = differenceInMinutes(endedAt, startedAt);
          const hours = Math.floor(totalMinutes / 60);
          const remainingMinutes = totalMinutes % 60;

          if (hours > 0) {
            return `${hours} ${hours === 1 ? t("hour") : t("hours", {
              count: hours,
            })} ${t("and")} ${remainingMinutes} ${t("minute", {
              count: remainingMinutes,
            })}`;
          }

          return `${totalMinutes} ${t("minute", { count: totalMinutes })}`;
        },
      },
      { header: t("Price"), id: "price", accessorKey: null },
      {
        header: t("Client"),
        id: "operator",
        accessorKey: "operator",
      },
    ];
  }, [i18n.language]);

  const onRowClick = (row) => {
    // Define the logic when a row is clicked
    navigate(`/parking/${row.id}`);
  };

  const getRowStyle = (session_ended_at) => {
    return session_ended_at.original.session_ended_at === null
      ? { fontWeight: "bold" }
      : {};
  };

  return (
    <>
      <div className="top-0 bg-[#FFFFFF] rounded-lg">
        <TableHeader title={t("Parking History")} />
        {/* Filter Section */}
        <div className="p-4">
          <SearchInput
            className=""
            query={query}
            handleSearch={handleSearch}
            handleSearchChange={handleFilterChange}
            handleSearchEnter={handleSearchEnter}
            clearSearch={clearSearch}
            isParkingHistoryOpen={showHistory}
            toggleParkingHistory={toggleHistory}
          />

          <div className="tableFilter">
            <TableFilter
              filters={filters}
              onFilterChange={handleFilterChange}
              filterOptions={filterOptions}
            />
          </div>
        </div>
      </div>
      {/* Table Section */}
      <div className="tableClass">
        <Table
          data={list.data}
          columns={columns}
          totalHits={list.totalHits}
          page={list.page}
          lastPage={list.totalHits >= 0 ? list.lastPage + 1 : 0}
          pageSize={list.pageSize}
          sortBy={""}
          sortDesc={false}
          fetchData={(p) => {
            handleListQueryWithSearch(p);
          }}
          onRowClick={onRowClick}
          selectedItemId={params && params.id}
          getRowStyle={getRowStyle}
        />
      </div>
    </>
  );
};

export default ParkingHistory;
