import React, {useRef, useEffect, useState, useCallback} from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import {AppStore} from "../../store/store";
import {useRecoilState, useRecoilValue} from "recoil";
import {LiveVehicleState, MarkerPopupState} from "./Map";
import "./map.less"
import VehiclePopupContent from "./VehiclePopupContent";
import {createPortal} from "react-dom";
import {createRoot} from "react-dom/client";
import { centerOfMass, feature, getCoord } from '@turf/turf';
import ZonePopupContent from "./ZonePopupContent";

const style = "klokantech-basic";
const { MAPS_URL, FM_API_URL } = process.env;

const MapCanvas = ({className}) => {

  const [appState, setAppState] = useRecoilState(AppStore)
  const [markerState, setMarkerState] = useRecoilState(MarkerPopupState)
  const mapRef = useRef();
  const mapObj = useRef();

  const mapPopupRef = useRef();
  const mapPopupRoot = useRef();
  const mapPoupPortalContainer = useRef();

  const vehicleState = useRecoilValue(LiveVehicleState)

  //
  // Fn for getting vehicle state in map
  //
  const getVehicleStateRef = useRef();
  //const getVehicleState = useCallback(() => {
  //  return vehicleState
  //}, [vehicleState])

  //
  // Load vehicles into map source
  //
  useEffect(() => {

    getVehicleStateRef.current = () => {
       return vehicleState
    }

    if(mapObj.current) {
      if(mapObj.current.loaded && mapObj.current.getSource('vehicles-src')) {
        const vehicleFeatures = genVehicleSrc(vehicleState);
        mapObj.current.getSource('vehicles-src').setData({
          'type': 'FeatureCollection',
          'features': vehicleFeatures
        });
      }
    }

  }, [
    vehicleState, mapObj.current
  ])

  //
  // Display marker if needed
  //
  useEffect(() => {
    if(mapObj.current && mapObj.current.loaded && mapPopupRef.current && markerState.coords.length > 1) {
      const pEl = mapPopupRef.current.getElement();
      if (pEl) {
        pEl.classList.remove('hide-popup');
      }
      mapPopupRef.current.setLngLat(markerState.coords);

      if (markerState.type === 'VEHICLE') {
        mapPopupRoot.current.render(
          createPortal(
            <VehiclePopupContent
              data={markerState.data}
            />,
            mapPoupPortalContainer.current
          ))
      } else if (markerState.type === 'ZONE') {
        mapPopupRoot.current.render(
          createPortal(
            <ZonePopupContent setMarkerState={setMarkerState}
              data={markerState.data}
            />,
            mapPoupPortalContainer.current
          ))
      }

      if(markerState.fly) {
        mapObj.current.flyTo({
          center: markerState.coords,
          essential: false
        });
      }

      console.log('--MarkerState CHANGED', markerState, )
    } else if (mapPopupRef.current) {
      const pEl = mapPopupRef.current.getElement();
      if (pEl) {
        pEl.classList.add('hide-popup');
      }
    }
  }, [markerState, mapObj.current, mapPopupRef.current])

  console.log('---- vehicleState -----', vehicleState)

  // Visible layers
  const [layers, setLayers] = useState(['localities'])

  // Default viewport
  const [viewport, setViewport] = useState({
    latitude: 59.433,
    longitude: 24.545,
    zoom: 10
  });

  useEffect(() => {

    //
    // Only setup map if the have the appstate
    //
    if (appState.token) {
      console.log('INIT MAP!!')

      //
      // Init Map DOM element
      //
      mapObj.current = new maplibregl.Map({
        container: mapRef.current,
        style: `${MAPS_URL}/styles/${style}/style.json`,
        hash: false,
        center: [viewport.longitude, viewport.latitude],
        zoom: viewport.zoom,
        preserveDrawingBuffer: true,
        transformRequest: (url, resourceType) => {
          if (resourceType === 'Tile' && url.startsWith(`${FM_API_URL}`)) {
            return {
              url: url,
              headers: {
                'Authorization': `Bearer ${appState.token}`
              }
            }
          }
        }
      });

      //
      // Load data and layers
      //
      mapObj.current.once('load', function () {

        //
        // Marker popup
        //
        const markerHeight = 36, markerRadius = 40, linearOffset = 0;
        const popupOffsets = {
          'top': [0, 0],
          'top-left': [0,0],
          'top-right': [0,0],
          'bottom': [0, -markerHeight],
          'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
          'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
          'left': [markerRadius, (markerHeight - markerRadius) * -1],
          'right': [-markerRadius, (markerHeight - markerRadius) * -1]
        };
        const mapPopup = new maplibregl.Popup({
          closeButton: false,
          closeOnClick: false,
          className: "popupContainer",
          focusAfterOpen: true,
          offset: popupOffsets
        });
        mapPopup.addTo(mapObj.current);
        mapPoupPortalContainer.current = document.createElement("div");
        mapPopupRoot.current = createRoot(mapPoupPortalContainer.current);
        mapPopup.setDOMContent(mapPoupPortalContainer.current);
        mapPopupRef.current = mapPopup;

        // Remove popup if clicked on map
        mapObj.current.on('click', (e) => {
          setMarkerState({
            type: "UNKNOWN",
            coords: [],
            data: {},
            fly: false
          })
        });


        //
        // Zones MVT Layer
        //

        // All zones MVT data
        if(!mapObj.current.getSource('localities')) {
          mapObj.current.addSource('localities', {
            'id': 'localities',
            'type': 'vector',
            'tiles': [`${FM_API_URL}/mvt/{z}/{x}/{y}`],
            'promoteId': 'id'
          })
        }
        // All zones layer
        mapObj.current.addLayer({
          'id': 'localities',
          'type': 'fill',
          'source': 'localities',
          'source-layer': 'localities',
          'layout': {},
          'paint': {
            'fill-color': ['case',
              ["in", ['get', 'id'], ["literal", ['ee.yt.KESKLINN15','ee.yt.SYDALINN15','ee.yt.VANALINN15','ee.yt.A15','ee.yt.B90','ee.yt.C15']]], 'rgb(234,176,2)', // If city zone
              ["==", 'ee.europark', ['get', 'operator']], 'rgb(33,54,187)', // if operator europark
              ["==", 'ee.yt', ['get', 'operator']], 'rgb(236,103,7)', // if operator yhisteenused
              'rgb(84,84,84)' // default
            ],
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.6, 0.3],
          }
        });

        //
        // Vehicles layer
        //
        const vehicleFeatures = genVehicleSrc(vehicleState);
        mapObj.current.addSource('vehicles-src', {
          type: 'geojson',
          data: {
            'type': 'FeatureCollection',
            'features': vehicleFeatures
          },
          promoteId: 'id',
          cluster: true,
          clusterMaxZoom: 12, // Maximum zoom level for clustering
          clusterRadius: 20, // Cluster radius in pixels
        });
        //Clusters
        mapObj.current.addLayer({
          id: 'vehicle-clusters',
          source: 'vehicles-src',
          filter: ['==', 'cluster', true],
          type: 'circle',
          // TODO: Make this look nice
          paint: {
            "circle-color": "#337FE1", // Cluster color
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              5,
              30,
              10,
              50,
            ],
            "circle-pitch-alignment": "viewport",
            "circle-opacity": 0.7,
          },
        });
        mapObj.current.addLayer({
          id: 'vehicle-cluster-texts',
          source: 'vehicles-src',
          filter: ['==', 'cluster', true],
          type: 'symbol',
          paint: {
            "text-color": "#ffffff", // Set text color
            "text-halo-color": "#000000", // Set text halo color
            "text-halo-width": 1, // Set text halo width
          },
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["Open Sans Semibold"],
            "text-size": 14,
            "text-rotation-alignment": "viewport",
            "text-ignore-placement": true,
            "symbol-avoid-edges": true,
          },
        });

        // Non clustered markers
        mapObj.current.addLayer({
          'id': 'vehicles',
          'source': 'vehicles-src',
          'filter': ['!=', 'cluster', true],
          'type': 'circle',
          'paint': {
            'circle-radius': 2,
            'circle-color': '#000000'
          }
        });
        // Non clustered icons
        const makeIconEl = (props) => {
          const vehicleEl = document.createElement("div");
          vehicleEl.classList.add("vehicle-map-icon");
          const fleet_id_cls = String(props.fleet_id).replace('.','_')
          vehicleEl.classList.add("vehicle-fleet-"+ fleet_id_cls);
          return vehicleEl;
        }

        //
        // Vehicle Markers
        //
        const markers = {};
        let markersOnScreen = {};
        const updateIcons = () => {
          const newMarkers = {};
          const features = mapObj.current.querySourceFeatures('vehicles-src');

          for (let i = 0; i < features.length; i++) {
            const coords = features[i].geometry.coordinates;
            const props = features[i].properties;
            const id = features[i].id;
            if (props.cluster) continue;

            let marker = markers[id];
            if (!marker) {
              const el = makeIconEl(props);
              marker = markers[id] = new maplibregl.Marker({
                element: el
              }).setLngLat(coords);

              el.addEventListener("click", (e) => {
                e.preventDefault();
                e.stopPropagation();
                setMarkerState({
                  type: "VEHICLE",
                  coords: coords,
                  data: Object.assign({}, props, {'price_reminder_threshold': appState.price_reminder_threshold }),
                  fly: false
                })
              });

            }
            newMarkers[id] = marker;

            if (!markersOnScreen[id]) marker.addTo(mapObj.current);

          } // for

          // for every marker we've added previously, remove those that are no longer visible
          for (const oid in markersOnScreen) {
            if (!newMarkers[oid]) markersOnScreen[oid].remove();
          }
          markersOnScreen = newMarkers;


        }

        mapObj.current.on('data', (e) => {
          if (e.sourceId !== 'vehicles-src' || !e.isSourceLoaded) return;

          mapObj.current.on('move', updateIcons);
          mapObj.current.on('moveend', updateIcons);
          updateIcons();
        });

        console.log('--2-- vehicleState ---2--', vehicleFeatures, vehicleState)

        // Actions for All zones layer
        // When the user moves their mouse over the state-fill layer, we'll update the
        // feature state for the feature under the mouse.
        let hoveredStateId = null;
        mapObj.current.on('mousemove', 'localities', (e) => {
          if (e.features.length > 0) {
            //console.log('features', e.features[0].properties)
            if (hoveredStateId) {
              mapObj.current.setFeatureState(
                {source: 'localities', sourceLayer: 'localities', id: hoveredStateId},
                {hover: false}
              );
            }
            hoveredStateId = e.features[0].id;
            mapObj.current.setFeatureState(
              {source: 'localities', sourceLayer: 'localities', id: hoveredStateId},
              {hover: true}
            );
          }
        });

        // When the mouse leaves the state-fill layer, update the feature state of the
        // previously hovered feature.
        mapObj.current.on('mouseleave', 'localities', () => {
          if (hoveredStateId) {
            mapObj.current.setFeatureState(
              {source: 'localities', sourceLayer: 'localities', id: hoveredStateId},
              {hover: false}
            );
          }
          hoveredStateId = null;
        });

        // Localities popup click
        mapObj.current.on('click', 'localities', (e) => {
          if (e.features.length > 0) {
            const props = e.features[0].properties;
            const coords = getCoord(centerOfMass(feature(e.features[0].geometry)))

            // Get current sessions in zone
            const sessions = ((getVehicleStateRef.current && getVehicleStateRef.current()) || []).filter((v) => {
              return v.locality_id == props.id
            });

            setMarkerState({
              type: "ZONE",
              coords: coords,
              data: Object.assign({}, props, {'sessions': sessions, 'price_reminder_threshold': appState.price_reminder_threshold }),
              fly: false
            })
          }
        });


      })


    } // If token exists
  }, [appState.token]);

  return <div
      ref={mapRef}
      className={className}
      style={{ height: "100%" }}
    />
}

function genVehicleSrc(vehicleState) {
  return vehicleState.map((v) => {
    return {
      'type': 'Feature',
      'properties': {
        'id': v.session_id,
        'fleet_id': v.client_id,
        'subject': v.subject,
        'session_started_at': v.session_started_at,
        'price_net': v.price_net,
        'locality_id': v.locality_id,
        'locality_code': v.locality_code,
        'locality_name': v.locality_name
      },
      'geometry': {
        'type': 'Point',
        'coordinates': [v.subject_location && v.subject_location.coordinates && v.subject_location.coordinates[0][1],v.subject_location && v.subject_location.coordinates && v.subject_location.coordinates[0][0]]
      }
    };
  })
}

export default MapCanvas;
