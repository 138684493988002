import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import Logo from "../../img/EPLogo.svg";
import SpinnLogo from "../../img/spinn-logo.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Languages } from "../../components/languages/languages";
import { useT } from "../../components/languages/t";
import { AppStore } from "../../store/store";

const { FM_API_URL, PRICE_THRESHOLD, THEME_CONFIG } = process.env;

const logUserIn = async (data) => {

  const res = await fetch(`${FM_API_URL}/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    // If the response status is not OK (2xx)
    const error = new Error(`HTTP error! Status: ${res.status}`);
    error.status = res.status;
    throw error;
  }

  return await res.json();
};

const Login = () => {
  const { t, i18n } = useT();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [rememberMeChecked, setRememberMeChecked] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [error, setError] = useState(null);

  const [appState, setAppState] = useRecoilState(AppStore);
  const isSpinnTheme = THEME_CONFIG === "Spinn";

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Invalid email address"))
      .required(t("Email is required")),
    password: Yup.string().required(t("Password is required")),
  });

  console.log("i18n", i18n);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      setError(null);

      if (!values.email || !values.password) {
        setError(t("Please enter both email and password."));
        setIsLoading(false);
        return;
      }

      try {
        const response = await logUserIn({
          sub: values.email,
          pass: values.password,
        });

        if (response.success && response.token) {
          handleLoginSuccess(response);

          if (localStorage.getItem("rememberMe") === "true") {
            localStorage.setItem("token", response.token);
            localStorage.setItem("userEmail", values.email);
          }
        }

        if (!appState.language) {
          i18n.changeLanguage(response.languages[0]);
        }

        setIsLoading(false);
      } catch (e) {
        handleError(e);
        setIsLoading(false);
      }
    },
  });

  const handleError = async (e) => {
    if (e.status === 403) {
      setError(t("Invalid credentials. Please check your email or password."));
    } else if (e.status === 400) {
      const validationErrors = await e.response.json();
      if (validationErrors.email) {
        setEmailError(t("Wrong email. Please check your email address."));
      }
      if (validationErrors.password) {
        setPasswordError(t("Wrong password. Please check your password."));
      }
    } else {
      setError(t("An error occurred during login. Please try again."));
    }

    // ... (existing code)
  };

  const handleLoginSuccess = (response) => {
    if (!response.languages) {
      response.languages = ["et", "en", "ru"];
    }

    setAppState({
      ...appState,
      token: response.token,
      adm: response.adm,
      email: email,
      id: response.id,
      logo: response.logo,
      name: response.name,
      role: response.role,
      languages: response.languages,
      language: appState.language || response.languages[0],
      fleets: response.fleets,
      operators: response.operators,
      price_reminder_threshold: (response.price_reminder_threshold || PRICE_THRESHOLD),
      can_view_sessions: response.can_view_sessions || false
    });
  };

  useEffect(() => {

    localStorage.setItem("rememberMe", rememberMeChecked);

    // Check if "Remember Me" is checked and there is a stored token
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const storedToken = localStorage.getItem("token");

    if (rememberMe && storedToken) {
      // Use the stored token to log in automatically
      logUserIn({ token: storedToken })
        .then((response) => {
          if (response.success) {
            handleLoginSuccess(response);
          }
        })
        .catch((error) => {
          // Handle the error if auto-login fails
          console.log("Auto-login failed", error);
        });
    }
  }, [rememberMeChecked]);

  return (
    <section className="bg-gradient-to-br from-neutral-50 to-blue-100">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="flex w-full sm:max-w-md justify-between">
          {isSpinnTheme ? (
              <SpinnLogo />
          ) :  <Logo /> }
          <Languages />
        </div>
        <div className="w-full bg-white rounded-lg  mt-10 shadow sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-2xl leading-7 font-semi-bold leading-tight tracking-tight text-[#1B1C20] md:text-2xl">
              {t("Sign in to your account")}
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={formik.handleSubmit}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-[#1B1C20]"
                >
                  {t("Your email")}
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className={`bg-gray-50 border border-gray-300 text-[#1B1C20] sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${formik.touched.email && formik.errors.email
                      ? "border-red-500 bg-red-50"
                      : ""
                    }`}
                  placeholder="name@email.com"
                  required={true}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="w-96 pt-2 text-red-600 text-sm font-semibold font-['Proxima Nova'] leading-tight">
                    {formik.errors.email}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-[#1B1C20]"
                >
                  {t("Password")}
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder=""
                  className={`bg-gray-50 border border-gray-300 text-[#1B1C20] sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${formik.touched.password && formik.errors.password
                      ? "border-red-500 bg-red-50"
                      : ""
                    }`}
                  required={true}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password && (
                  <p class="w-96 pt-2 text-red-600 text-sm font-semibold font-['Proxima Nova'] leading-tight">
                    {formik.errors.password}
                  </p>
                )}
              </div>
              {/* {emailError && (
                <p className="text-red-500 text-sm mt-2">{emailError}</p>
              )}
              {passwordError && (
                <p className="text-red-500 text-sm mt-2">{passwordError}</p>
              )} */}
              {error && (
                <div class=" p-4 bg-rose-100 rounded-lg justify-start items-center gap-3 inline-flex">
                  <div class="grow shrink basis-0 flex-col justify-start items-start gap-3 inline-flex">
                    <div class="self-stretch flex-col justify-start items-start gap-1.5 flex">
                      <div class="self-stretch text-zinc-900 text-sm font-semibold font-['Proxima Nova'] leading-tight">
                        {error}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                      checked={rememberMeChecked}
                      onChange={(e) => setRememberMeChecked(e.target.checked)}
                    />
                  </div>
                  <div className="ml-3 text-sm font-semibold font-['Proxima Nova']">
                    <label htmlFor="remember" className="text-[#1B1C20]">
                      {t("Remember me")}
                    </label>
                  </div>
                </div>
                {/* <a
                  href="#"
                  className="text-sm font-medium text-primary-600 hover:underline"
                >
                  {t("Forgot password?")}
                </a> */}
              </div>
              <button
                type="submit"
                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {t("Sign in")}
              </button>
            </form>
          </div>
        </div>
        <div className="absolute bottom-10 flex gap-2 gap-x-1 items-center">
          {!isSpinnTheme && (
              <><p>{t("Powered By")}</p><SpinnLogo /></>
          )}
        </div>
      </div>
    </section>
  );
};

export default Login;
