import {useList} from "../../useList";
import {useSearch} from "../../components/form/useSearch";
import {useFilter} from "../../components/form/useFilter";
import {useT} from "../../components/languages/t";
import {useLocation, useRoute} from "wouter";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import {useMemo} from "react";
import differenceInMinutes from "date-fns/differenceInMinutes";
import TableHeader from "../../components/table/TableHeader";
import SearchInput from "../../components/formElements/SearchInput";
import TableFilter from "../../components/table/TableFilter";
import {Table} from "../../components/table/table";
import VehicleSpinner from "../../components/spinner/VehicleSpinner";
import {AppStore} from "../../store/store";
import {useRecoilValue} from "recoil";
import {displayTimeDifference} from "../map-new/VehiclesLive";
import {useFleets} from "../../useFleets";

const { FM_API_URL, PRICE_THRESHOLD } = process.env;

const re = /(\d{3,4})(\d{4})(\d{4})(\d{4})/;
const re2 = new RegExp(/([a-z])(?=[0-9])/gi);
const re3 = new RegExp(/([0-9])(?=[a-z])/gi);

const ParkingList = () => {

  const { t, i18n } = useT();
  const appState = useRecoilValue(AppStore)
  const fleetsNoperators = useFleets()
  const { list, handleListQuery} = useList('/parkings/list');
  const { query, handleSearch, handleSearchEnter, clearSearch } = useSearch('/parkings/list', handleListQuery)

  const fleets = useMemo(() => { return Object.values(fleetsNoperators || {}).reduce((r, v) => {
    if(v.type === 'FLEET') {
      r[v.id] = v.name;
    }
    return r;
  }, {}); },[fleetsNoperators])

  const operators = useMemo(() => { return Object.values(fleetsNoperators || {}).reduce((r, v) => {
    if(v.type === 'OPERATOR') {
      r[v.id] = v.name;
    }
    return r;
  }, {}); },[fleetsNoperators])

  const filterOpts = {
    client_id: {
      title: t("Fleet"),
      options: fleets,
    },
    operator: {
      title: t(["parkingOperatorColumn", "Parking Operator"]),
      options: operators
    }
  }

  const { filters, handleFilterChange } = useFilter('/parkings/list', handleListQuery, filterOpts)
  const [_, navigate] = useLocation();
  const [match, params] = useRoute("/parkings/:sessionId");

  const cols = useMemo(() => {
    return [
      {
        header: t(["plateColumn", "Number Plate"]),
        id: "subject",
        accessorFn: (r) => {
          return (
            <span className="whitespace-nowrap tabular-nums">
              {r.subject
                .replace(/\s/g, "")
                .replace(re2, "$1 ")
                .replace(re3, "$1 ")}
            </span>
          );
        },
      },
      {
        header: t(["parkingOperatorColumn", "Parking Operator"]),
        id: "operator",
        accessorFn: (r) => {
          return operators[r.operator]
        },
      },
      {
        header: t(["zoneColumn", "Zone"]),
        id: "locality_name",
        accessorKey: "locality_name",
      },
      {
        header: t("Parking Started"),
        id: "session_started_at",
        accessorFn: (r) => {
          if (!r.session_started_at) {
            return "--";
          }
          return format(parseISO(r.session_started_at), "dd.MM.yyyy HH:mm");
        },
      },
      {
        header: t("Parking Ended"),
        id: "session_ended_at",
        accessorFn: (r) => {
          if (!r.session_ended_at) {
            return "--";
          }
          return format(parseISO(r.session_ended_at), "dd.MM.yyyy HH:mm");
        },
      },
      {
        header: t(["parkedTimeColumn", "Parked Time"]),
        id: "time_diff_minutes",
        minWidth: "8ch",
        accessorFn: (r) => {
          if (!r.session_started_at || !r.session_ended_at) {
            return "--";
          }
          const endedAt = r.session_ended_at ? parseISO(r.session_ended_at) : new Date();
          return displayTimeDifference(r.session_started_at, endedAt, t)
        }
      },
      {
        header: t(["priceNetColumn", "Netto"]),
        id: "price_net",
        minWidth: "8ch",
        accessorFn: (r) => {
          return <div
            className={`w-max px-3 py-2 rounded ${
              r.price_net !== null &&
              r.price_net > (appState.price_reminder_threshold || PRICE_THRESHOLD)
                ? "text-[#ef4444] bg-[#fef2f2]"
                : ""
            }`}
          >
            {/* Display the calculated net price with VAT */}
            {r.price_net !== null ? (
              <p>{r.price_net} €</p>
            ) : (
              <p>0.00 €</p>
            )}
          </div>
        }
      },
      {
        header: t(["fleetColumn", "Fleet"]),
        id: "fleet_name",
        accessorKey: "fleet_name",
      },
    ];
  }, [i18n.language, operators])

  const onRowClick = () => {

  }

  return <><TableHeader title={t("Parking Sessions")}/>
    <div className="flex-[0_0_auto] border-b px-4 py-4 border-gray-100">
      <SearchInput query={query} handleSearch={handleSearch} handleSearchEnter={handleSearchEnter}
                   clearSearch={clearSearch} />
    </div>
    <TableFilter filters={filters} onFilterChange={handleFilterChange}  />
    <Table data={list.data}
           columns={cols}
           totalHits={list.totalHits}
           page={list.page}
           lastPage={list.totalHits >= 0 ? list.lastPage + 1: 0}
           pageSize={list.pageSize}
           sortBy={""}
           sortDesc={false}
           filters={list.filters}
           search={list.search}
           fetchData={(p) => {
             return handleListQuery(p)
           }}
           onRowClick={onRowClick}
           selectedItemId={params && params.id}
           spinner={VehicleSpinner}
    />
  </>

}

export default ParkingList
