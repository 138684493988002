import { useT } from "../../components/languages/t";
import FormTitle from "../../components/form/FormTitle";
import Form from "../../components/form/Form";
import { useCallback, useEffect } from 'react';
import FormDetails from "../../components/form/FormDetails";
import { useState, useMemo } from 'react'
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { DateTime } from 'luxon';
import {
  AppStore,
  ResourceDataStore,
  ResourceState,
} from "../../store/store";
import { atom, useRecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import { useLocation } from "wouter";
import { useList, useReloadList } from "../../useList";
import Datepicker from "tailwind-datepicker-react"
import { useFleets } from "../../useFleets";
import * as y from "yup";
import SelectBox from "../../components/formElements/Select";
import "./reports.less";
import FormButtonRow from "../../components/form/FormButtonRow";
import { useValidate } from "../../components/form/useValidate";
import VehicleSpinner from "../../components/spinner/VehicleSpinner";

const re = /(\d{3,4})(\d{4})(\d{4})(\d{4})/
const re2 = new RegExp(/([a-z])(?=[0-9])/ig);
const re3 = new RegExp(/([0-9])(?=[a-z])/ig);

const { FM_API_URL } = process.env;


const ReportsDetails = (props, resourcePath) => {
  const { t } = useT();
  const fleetsNoperators = useFleets()

  const fleets = useMemo(() => {
    return Object.values(fleetsNoperators || {}).reduce((r, v) => {
      if (v.type === 'FLEET') {
        r[v.id] = v.name;
      }
      return r;
    }, {});
  }, [fleetsNoperators])

  const operators = useMemo(() => {
    return Object.values(fleetsNoperators || {}).reduce((r, v) => {
      if (v.type === 'OPERATOR') {
        r[v.id] = v.name;
      }
      return r;
    }, {});
  }, [fleetsNoperators])


  const [state, setState] = useRecoilState(ResourceState(resourcePath))
  const [isLoading, setIsLoading] = useState(false);
  const [storeData, setStoreData] = useRecoilState(ResourceDataStore(resourcePath))
  const [editMode, setEditMode] = useState(false)
  const [_, navigate] = useLocation();

  useEffect(() => {
    if (props.params.reportId !== "generate") {
      fetchReportData(props.params.reportId); // Fetch report data when reportId changes
      setEditMode(false);
      setState();
    } else {
      setState();
      setEditMode(true);
    }
  }, [props.params.reportId]);

  const fetchReportData = async (id) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${FM_API_URL}/reports/${id}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + appState.token,
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      const reportData = await response.json();
      setState({ data: reportData, changes: {} });
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const adjustDateToISO = (key, value) => {
    if (key === "reportFrom" || key === "reportTo") {
      let date;
      if (typeof value === 'string') {
        date = DateTime.fromISO(value);
      } else {
        date = DateTime.fromJSDate(value);
      }

      if (date.isValid) {
        const isoDate = date.toISO();
        setState((prevState) => ({
          ...prevState,
          [key]: isoDate,
        }));
      } else {
        console.error('Invalid date:', value);
        setState((prevState) => ({
          ...prevState,
          [key]: null,
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };
  const handleChanges = useCallback((fieldName) => {
    return (evOrValue) => {

      let key = null;
      let value = null;

      if (!evOrValue.target && fieldName) {
        key = fieldName
        value = evOrValue
      } else if (evOrValue.target) {
        const target = evOrValue.target;
        if (fieldName) {
          key = fieldName
          value = target.value
        } else if (target.name) {
          key = target.name
          value = target.value
        }
      }
      adjustDateToISO(key, value);

    }
  }, [resourcePath])

  const handleReset = useCallback(() => {
    setState({})
  }, [resourcePath])


  /// DATAA!!!!
  const dataChanges = state.changes.data && state.changes.data.data;
  const data = Object.assign({ sessions_merged: false, cityZones: false }, dataChanges || state.changes, state.data);
  console.log(data);

  const appState = useRecoilValue(AppStore)
  const handleClose = (state) => {
    setShowFrom(state)
    setShowTo(state)
  }

  const [showFrom, setShowFrom] = useState(false);
  const [showTo, setShowTo] = useState(false);
  const { list: reports, reloadListQuery } = useReloadList('/reports/list')

  const downloadReport = () => {
    setIsLoading(true);
    fetch(`${FM_API_URL}/reports/download/${data.id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + appState.token,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        return response.blob(); // Get the response as a blob
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report-${data.id}.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link); // Cleanup
        window.URL.revokeObjectURL(url); // Cleanup
      })
      .catch(error => {
        console.error("Error making download request:", error);
        // Handle any error response data if needed
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = async () => {
    console.log("isLoading before fetch:", isLoading);
    setIsLoading(true);
    console.log("isLoading after setIsLoading(true):", isLoading);
    const { fleet_id, ...postData } = data;
    try {
      const response = await fetch(`${FM_API_URL}/reports/generate/${data.fleet_id}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + appState.token,
        },
        body: JSON.stringify(postData)
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      const result = await response.json();
      reloadListQuery();
      navigate("/reports");
    } catch (error) {
      console.error("Error making POST request:", error);
      alert(t("Report generation failed. Please try again later.") + "\r\n\r\n" + error);
    } finally {
      setIsLoading(false);
    }
  };


  const reportSchema = y.object({
    fleet_id: y.string().required().label("Fleet"),
    operator: y.string().required().label("Operator"),
    // cityZones: y.boolean().required().label("City Zones"),
    reportFrom: y.date().required().label("Date From"),
    reportTo: y.date().required().label("Date To"),
    // sessions_merged: y.boolean().required().label("Sessions Merged"),
  });


  const optionsFrom = {
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    icons: false,
    clearBtnText: "Clear",
    maxDate: new Date("2030-01-01"),
    minDate: new Date("1950-01-01"),
    datepickerClassNames: "datePickerFrom",
    theme: {
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      input: "",
      inputIcon: "",
      selected: "bg-blue-500 text-white hover:text-black hover:bg-blue-200",
    },
    inputDateFormatProp: {
      day: "numeric",
      month: "numeric",
      year: "numeric"
    }
  }

  const optionsTo = {
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    icons: false,
    clearBtnText: "Clear",
    maxDate: new Date("2030-01-01"),
    datepickerClassNames: "datePickerTo",
    minDate: data.reportFrom ? new Date(new Date(data.reportFrom).getTime() + (32 * 24 * 60 * 1000)) : new Date("1950-01-01"),
    theme: {
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "pointer-events-none bg-gray-100",
      input: "",
      inputIcon: "",
      selected: "bg-blue-500 text-white hover:text-black hover:bg-blue-200",
    },
    inputDateFormatProp: {
      day: "numeric",
      month: "numeric",
      year: "numeric"
    }
  }

  const { errors } = useValidate(state, reportSchema)

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickedInsideFrom = event.target.closest(".datePickerFrom");
      const isClickedInsideTo = event.target.closest(".datePickerTo");

      if (!isClickedInsideFrom && !isClickedInsideTo) {
        setShowFrom(false);
        setShowTo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowFrom, setShowTo]);

  if (isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center max-h-full min-h-full bg-white p-6 place-items-center">
        <VehicleSpinner />
      </div>
    );
  }

  console.log("isLoading:", isLoading);
  return <Form state={state}>
    {editMode && (
      <FormButtonRow state={state}
        editMode={editMode}
        onEditMode={() => {
          setEditMode(!editMode);
          navigate("/reports");
        }}
        onCancel={handleReset}
        errors={errors}
        onSave={() => {
          reportSchema.validate(data).then(() => {
            handleSubmit();

          });
        }}
      ></FormButtonRow>)}
    {editMode && (
      <FormTitle className="p-4" title={t("Generate Report")} />
    )}
    <FormDetails editMode={editMode} data={{
      reportFrom: {
        label: t("Date from"),
        edit: (
          <div className="row whitespace-nowrap relative flex w-full min-h-[40px] rounded border border-gray-300 px-2 text-left shadow-sm focus-within:border-primary-300 focus-within:ring focus-within:ring-primary-200 focus-within:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50">
            <Datepicker options={optionsFrom} onChange={handleChanges("reportFrom")} show={showFrom} setShow={handleClose}>
              <input type="text" className="w-full inline-block flex-1 border-none focus:ring-0 outline-none py-2 px-3 text-sm leading-5 text-gray-900 placeholder:italic placeholder:text-slate-400 placeholder:text-sm invalid:text-red-500 valid:text-green-500" placeholder={t("Select Date From")} value={data.reportFrom ? data.reportFrom : ""} onFocus={() => setShowFrom(true)} readOnly />
            </Datepicker>
          </div>
        ),
        required: true,
        errorMessage: errors["reportFrom"],
      },
      reportTo: {
        label: t("Date to"),
        edit: (
          <div className="row whitespace-nowrap relative block flex w-full min-h-[40px] rounded border border-gray-300 px-2 text-left shadow-sm focus-within:border-primary-300 focus-within:ring focus-within:ring-primary-200 focus-within:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50">
            <Datepicker options={optionsTo} onChange={handleChanges("reportTo")} show={showTo} setShow={handleClose}>
              <input type="text" className="w-full inline-block flex-1 border-none focus:ring-0 outline-none py-2 px-3 text-sm leading-5 text-gray-900 placeholder:italic placeholder:text-slate-400 placeholder:text-sm invalid:text-red-500 valid:text-green-500" placeholder={t("Select Date To")} value={data.reportTo ? data.reportTo : ""} onFocus={() => setShowTo(true)} readOnly />
            </Datepicker>
          </div>
        ),
        required: true,
        errorMessage: errors["reportTo"],
      },
      fleet_id: {
        label: t("Belongs to fleet"),
        edit: (
          <SelectBox
            id="fleet_id"
            value={data.fleet_id ? data.fleet_id : ""}
            placeholder="Please the role"
            options={fleets || []}
            onChange={handleChanges("fleet_id")}
            isInvalid={!!errors["fleet_id"]}
            isTouched={!!state.changes["fleet_id"]}
          />
        ),
        required: true,
        errorMessage: errors["fleet_id"],
      },

      operator: {
        label: t("Belongs to operator"),
        edit: (
          <SelectBox
            id="operator"
            value={data.operator ? data.operator : ""}
            placeholder="Please the role"
            options={operators || []}
            onChange={handleChanges("operator")}
            isInvalid={!!errors["operator"]}
            isTouched={!!state.changes["operator"]}
          />
        ),
        required: true,
        errorMessage: errors["operator_id"],
      },
      cityZones: {
        label: t("Select city zones"),
        edit: (
          <div>
            <input
              type="checkbox"
              id="cityZones"
              checked={data.cityZones || false}
              value={data.cityZones === false}
              onChange={(e) => handleChanges("cityZones")(e.target.checked ? true : false)}
            />
            <label htmlFor="cityZones"> {t("City zones")}
            </label>
          </div>
        ),
        required: true,
        errorMessage: errors["cityZones"],
      },
      sessions_merged: {
        label: t("Sessions merged"),
        edit: (
          <div>
            <input
              type="checkbox"
              id="sessions_merged"
              checked={data.sessions_merged || false}
              value={data.sessions_merged ? data.sessions_merged : ""}
              onChange={(e) => handleChanges("sessions_merged")(e.target.checked ? true : false)}
            />
            <label htmlFor="sessions_merged"> {t("Sessions merged")}
            </label>
          </div>
        ),
        required: true,
        errorMessage: errors["sessions_merged"],
      },

    }} />
    {!editMode && (
      <fieldset>
        <legend className="sr-only">{t("Reports")}</legend>
        <div>
          <FormDetails editMode={!editMode} data={{
            date_from: {
              label: t("Date from"),
              display:
                data.date_from
                  ? format(parseISO(data.date_from), "dd.MM.yyyy HH:mm")
                  : 'N/A',
            },
            date_to: {
              label: t("Date to"),
              display:
                data.date_to
                  ? format(parseISO(data.date_to), "dd.MM.yyyy HH:mm")
                  : 'N/A',
            },
            fleet_id: {
              label: t("Belongs to fleet"),
              display: data.fleet_id,
            },
            operator_id: {
              label: t("Belongs to operator"),
              display: data.operator_id ? data.operator_id : 'N/A',
            },
            city_zones: {
              label: t("City zones"),
              display: String(data.city_zones)
            },
            generated_at: {
              label: t("Generated at"),
              display: data.generated_at
                ? format(parseISO(data.generated_at), "dd.MM.yyyy HH:mm")
                : 'N/A',
            },
            id: {
              label: t("ID"),
              display: String(data.id),
            },
            sessions_merged: {
              label: t("Sessions merged"),
              display: String(data.sessions_merged),
            },

          }}
          >
          </FormDetails>
          <button onClick={downloadReport} className="h-11 inline-flex items-center rounded gap-0.5 px-2 py-2.5 text-center text-sm font-medium text-blue-500 transition-all hover:bg-gray-100 focus:ring focus:ring-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400">
            {t("Report Download")}
          </button>
        </div>
      </fieldset>
    )}
  </Form>
}

export default ReportsDetails;
