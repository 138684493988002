import {useEffect, useMemo, useState} from 'react';
import {useT} from "../../components/languages/t";
import {Table} from "../../components/table/table";
import {useList} from "../../useList";
import {useLocation, useRoute} from "wouter";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import isFuture from "date-fns/isFuture";
import TableHeader from "../../components/table/TableHeader";
import {useSearch} from "../../components/form/useSearch";
import SearchInput from "../../components/formElements/SearchInput";
import {useFilter} from "../../components/form/useFilter";
import {AppStore} from "../../store/store";
import {useRecoilState, useRecoilValue} from "recoil";
import {tFixed} from "../../i18n";
import TableFilter from "../../components/table/TableFilter";
import VehicleSpinner from "../../components/spinner/VehicleSpinner";
import {useFleets} from "../../useFleets";

const re = /(\d{3,4})(\d{4})(\d{4})(\d{4})/
const re2 = new RegExp(/([a-z])(?=[0-9])/ig);
const re3 = new RegExp(/([0-9])(?=[a-z])/ig);

export function fineStatusBadge(r, t) {

  let color = "bg-red-50 text-red-500"
  let status_txt = t("Payment Overdue")
  if (r.is_parking_fee === true) {
    color = "bg-purple-50 text-purple-500"
    status_txt = t("Parking Fee")
  } else if (r.status === 'PAID') {
    color = "bg-green-50 text-green-500"
    status_txt = t("Fine is paid. Thank you!")
  } else if(r.status === 'CLOSED' || r.status === 'EXPIRED') {
    color = "bg-green-50 text-green-500"
    status_txt = t("The fine is closed.")
  } else if(r.status === 'REVOKED' || r.status === 'REVOKED_INSPECTOR') {
    color = "bg-green-50 text-green-500"
    status_txt = t("The fine has been revoked.")
  } else if(isFuture(parseISO(r.due_at))) {
    color = "bg-orange-50 text-orange-500"
    status_txt = t("Payment due soon")
  }
  return <span
    className={`whitespace-nowrap inline-flex items-center gap-1 rounded px-3 py-2 text-xs font-semibold ${color}`}>
            {status_txt}
          </span>
}

export function debtCollectionBadge(r, t) {
  let color = "bg-green-50 text-green-500"
  let txt = t("No");

  if(r.debt_collection_at) {
    color = "bg-red-50 text-red-500"
    txt = format(parseISO(r.debt_collection_at), 'dd.MM.yyyy HH:mm')
  }

  return <span
    className={`whitespace-nowrap inline-flex items-center gap-1 rounded px-3 py-2 text-xs font-semibold ${color}`}>
            {txt}
          </span>
}

export function receiverBadge(r, t) {
  let color = "bg-red-50 text-red-500"
  let txt = t("No");

  if(r.receiver_data_at && r.receiver_name && r.receiver_email && r.receiver_mobile) {
    color = "bg-green-50 text-green-500"
    txt = format(parseISO(r.receiver_data_at), 'dd.MM.yyyy HH:mm')
  }

  return <span
    className={`whitespace-nowrap inline-flex items-center gap-1 rounded px-3 py-2 text-xs font-semibold ${color}`}>
            {txt}
          </span>
}

export function notificationBadge(r, t) {
  let color = "bg-red-50 text-red-500"
  let txt = t("No");

  if(r.notification_sent_at) {
    color = "bg-green-50 text-green-500"
    txt = format(parseISO(r.notification_sent_at), 'dd.MM.yyyy HH:mm')
  }

  return <span
    className={`whitespace-nowrap inline-flex items-center gap-1 rounded px-3 py-2 text-xs font-semibold ${color}`}>
            {txt}
          </span>
}

const FineList = () => {

  const { t, i18n } = useT();

  // Get all fleets
  const fleetsNoperators = useFleets()
  const fleets = useMemo(() => { return Object.values(fleetsNoperators || {}).reduce((r, v) => {
    if(v.type === 'FLEET') {
      r[v.id] = v.name;
    }
    return r;
  }, {}); },[fleetsNoperators])

  const filterOpts = {
    fleet_id: {
      title: t(["fleetColumn", "Fleet"]),
      options: fleets
    },
    status: {
      title: t(["statusColumn", "Status"]),
      options: {
        "NEW": t("Payment due soon"),
        "PAID": t("Paid"),
        "DEBTOR": t("Payment Overdue"),
        "CLOSED": t("Closed"),
        "REVOKED": t("Revoked"),
      }
    },
    debt_collection_at: {
      title: t(["atDebtCollectionColumn", "Sent to Debt Collection"]),
      options: {
        "YES": t("Yes"),
        "NO": t("No"),
      }
    },
    receiver_data_at: {
      title: t(["receiverDataColumn", "Fine Receicer Entered"]),
      options: {
        "YES": t("Yes"),
        "NO": t("No"),
      }
    },
    notification_sent_at: {
      title: t(["noticeSentColumn", "Fine Notice Sent"]),
      options: {
        "YES": t("Yes"),
        "NO": t("No"),
      }
    }
  }

  const { list, handleListQuery} = useList('/fines/list');
  const { query, handleSearch, handleSearchEnter, clearSearch } = useSearch('/fines/list', handleListQuery)
  const { filters, handleFilterChange } = useFilter('/fines/list', handleListQuery, filterOpts)
  const [_, navigate] = useLocation();
  const [match, params] = useRoute("/fines/:id");



  const cols = useMemo(() => {
    return [{
      header: t(["numberColumn", "Fine Number"]),
      id: "number",
      minWidth: "19ch",
      accessorFn: (r) => {
        return <span className="whitespace-nowrap tabular-nums">{r.number.replace(re,'$1 $2 $3 $4')}</span>
      }
    },
      {
        header: t(["plateColumn", "Number Plate"]),
        id: "subject",
        minWidth: "8ch",
        accessorFn: (r) => {
          return <span className="whitespace-nowrap tabular-nums">{r.subject.replace(/\s/g, '').replace(re2, "$1 ").replace(re3, "$1 ")}</span>
        }
      },
      {
        header: t(["fleetColumn", "Fleet"]),
        id: 'fleet_id',
        accessorKey: 'fleet_name',
      },
      {
        header: t(["statusColumn", "Status"]),
        id: "status",
        minWidth: "21ch",
        accessorFn: (r) => {
          return fineStatusBadge(r, t);
        }
      },
      {
        header: t(["atDebtCollectionColumn", "Sent to Debt Collection"]),
        id: "debt_collection_at",
        accessorFn: (r) => {
          return debtCollectionBadge(r, t);
        }
      },
      {
        header: t(["receiverDataColumn", "Fine Receicer Entered"]),
        id: "receiver_data_at",
        accessorFn: (r) => {
          return receiverBadge(r, t);
        }
      },{
        header: t(["noticeSentColumn", "Fine Notice Sent"]),
        id: "notification_sent_at",
        accessorFn: (r) => {
          return notificationBadge(r, t);
        }
      }
      ,{
        header: t(["issuedAtColumn", "Issued At"]),
        id: "issued_at",
        accessorFn: (r) => {
          if(!r.issued_at) {
            return '--';
          }
          return format(parseISO(r.issued_at), 'dd.MM.yyyy HH:mm');
        }
      }, {
        header: t(["locationColumn", "Location"]),
        accessorKey: "locality_name"
      }, {
        header: t(["fineAmountColumn", "Fine Amount"]),
        accessorKey: "amount_due",
        minWidth: "6ch",
      }, {
        header: t(["paidAmountColumn", "Paid Amount"]),
        accessorKey: "amount_paid",
        minWidth: "6ch",
      }
    ]
  }, [i18n.language])


  const onRowClick = (row) => {
    navigate(`/fines/${row.id}`);
  }

  console.log('list', list, 'FILTERS', filters);

  /*
              "number_plate": "234BCD",
            "created_at": "2023-01-30T10:09:22.919993",
            "active": "ENABLED",
            "description": "Test Car 2",
            "user_group_id": 1,
            "unpaid_fines_amount": 0
   */

  return <><TableHeader title={t("Fines")}/>
    <div className="flex-[0_0_auto] border-b px-4 py-4 border-gray-100">
      <SearchInput query={query} handleSearch={handleSearch} handleSearchEnter={handleSearchEnter}
                   clearSearch={clearSearch} />
    </div>
    <TableFilter filters={filters} onFilterChange={handleFilterChange}  />
    <Table data={list.data}
                  columns={cols}
                  totalHits={list.totalHits}
                  page={list.page}
                  lastPage={list.totalHits >= 0 ? list.lastPage + 1: 0}
                  pageSize={list.pageSize}
                  sortBy={""}
                  sortDesc={false}
                  filters={list.filters}
                  search={list.search}
                  fetchData={(p) => {
                    return handleListQuery(p)
                  }}
                  onRowClick={onRowClick}
                  selectedItemId={params && params.id}
                  spinner={VehicleSpinner}
    />
  </>
}

export default FineList;
