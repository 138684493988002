import * as React from 'react';
import {useCallback, useRef, useState} from "react";

import {atomFamily, useRecoilState, useRecoilValue} from "recoil";
import {
  AppStore,
  ListResourceQueryResult,
  ListQueryState
} from "./store/store";

const { FM_API_URL} = process.env;

const postToGetMap = {
  page: "p",
  pageSize:"s",
  sortBy:"o",
  sortDesc:"d",
  search:"q",
  filters:"f"
}

const queryLoading = [];

export function useList(resourcePath, method = 'POST') {

  const [list, setList] = useRecoilState(ListResourceQueryResult(resourcePath))
  const [queryState, setQueryState] = useRecoilState(ListQueryState(resourcePath))
  const [appState, setAppState] = useRecoilState(AppStore)
  const controller = useRef(new AbortController());

  queryLoading[resourcePath] = false;

  const signal = controller.signal;

  const handleListQuery = useCallback((params) => {

    setQueryState(params);

    return (async (params) => {

      if (queryLoading[resourcePath] === true) {
        controller.current.abort()
      }

      let rqUrl = `${FM_API_URL}${resourcePath}`
      let opts = {
        method: method,
        signal: signal,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + appState.token
        }
      }

      if( method === 'GET') {
        // Translate into query params
        const searchParams = new URLSearchParams(Object.keys(postToGetMap).reduce((r, v) => {
          if(!(params[v] === undefined || params[v] === null)) {
            r[postToGetMap[v]] = params[v] === Object(params[v]) ? encodeURIComponent(JSON.stringify(params[v])): params[v];
          }
          return r;
        }, {}));

        rqUrl = `${FM_API_URL}${resourcePath}?${searchParams.toString()}`
      } else {
        opts['body'] = JSON.stringify(params)
      }

      queryLoading[resourcePath] = true;

      return  new Promise((resolve, reject) => {
        fetch(rqUrl, opts).then((res) => {
          queryLoading[resourcePath] = false;

          if(res.status == 401) {
            setAppState({ ...appState, token: null })
          }

          res.json().then((resp) => {
            if (resp.success) {
              setList(resp)
              resolve(resp)
            }
          }).catch((e) => {
            reject(e)
          })

        }).catch((e) => {
          queryLoading[resourcePath] = false;
          reject(e)
        })

      })

    })(params);

  }, [resourcePath, method])

  const clearListQuery = useCallback(() => {
    setList({
      page: 0,
      pageSize: queryState.pageSize,
      sortBy: "",
      sortDesc: false,
      search: "",
      filters: {},
      data: [],
      related: {}
    })
  }, [resourcePath])

  return { list, handleListQuery, clearListQuery }

}

export function useReloadList(resourcePath) {
  const [list, setList] = useRecoilState(ListResourceQueryResult(resourcePath))
  const queryState = useRecoilValue(ListQueryState(resourcePath))
  const [appState, setAppState] = useRecoilState(AppStore)

  const reloadListQuery = () => {

    return (async (params) => {

      const res = await fetch(`${FM_API_URL}${resourcePath}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + appState.token
        },
        body: JSON.stringify(params)
      })
      const resp = await res.json()

      if(res.status == 401) {
        setAppState({ ...appState, token: null })
      }

      if (resp.success) {
        setList(resp)
      }

    })(queryState);

  }

  return { list, reloadListQuery }
}
