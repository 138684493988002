import History from "../../img/history.svg";
import SearchInput from "../../components/formElements/SearchInput";
import {Table} from "../../components/table/table";
import VehicleSpinner from "../../components/spinner/VehicleSpinner";
import {useT} from "../../components/languages/t";
import {useList} from "../../useList";
import {useSearch} from "../../components/form/useSearch";
import {useMemo} from "react";
import {MarkerPopupState} from "./Map";
import {useRecoilState} from "recoil";
import { centerOfMass, feature, getCoord } from '@turf/turf';
import {useLocation} from "wouter";
import {useFleets} from "../../useFleets";
import {useFilter} from "../../components/form/useFilter";
import TableFilter from "../../components/table/TableFilter";

const ZonesLive = () => {

  const { t, i18n } = useT();
  const [_, navigate] = useLocation();
  const fleetsNoperators = useFleets()
  const { list, handleListQuery } = useList('/parkings/liveByZone')
  const { query, handleSearch, handleSearchEnter, clearSearch } = useSearch(
    "/parkings/liveByZone",
    handleListQuery
  );
  const [markerState, setMarkerState] = useRecoilState(MarkerPopupState)

  const fleets = useMemo(() => { return Object.values(fleetsNoperators || {}).reduce((r, v) => {
    if(v.type === 'FLEET') {
      r[v.id] = v.name;
    }
    return r;
  }, {}); },[fleetsNoperators])

  const operators = useMemo(() => { return Object.values(fleetsNoperators || {}).reduce((r, v) => {
    if(v.type === 'OPERATOR') {
      r[v.id] = v.name;
    }
    return r;
  }, {}); },[fleetsNoperators])

  const filterOpts = {
    operator: {
      title: t(["parkingOperatorColumn", "Parking Operator"]),
      options: operators
    }
  }

  const { filters, handleFilterChange } = useFilter('/parkings/liveByZone', handleListQuery, filterOpts)

  const cols = useMemo(() => {
    return [
      {
        header: t(["zoneNameColumn", "Zone"]),
        id: "name",
        minWidth: "8ch",
        accessorKey: "name",
      },
      {
        header: t(["parkedColumn", "Vehicles Parked"]),
        id: "sessions",
        minWidth: "8ch",
        accessorFn: (r) => {
          return r.sessions.length
        },
      }
    ];
  }, [i18n.language]);

  const onRowClick = (v) => {

    const popData = {
      type: "ZONE",
      marker: null,
      coords: getCoord(centerOfMass(feature(v.geom))),
      data: v,
      fly: true
    }
    setMarkerState(popData)
  }

  return <div className="flex flex-1 h-[400px] flex-col mx-3 mb-1 relative">
    <div className="flex flex-col min-h-[140px] max-h-[140px]">
      <div className="flex flex-1 items-center justify-center pt-3 pb-3 px-5 gap-2 mt-3 border-t text-base">
        <div
          className="text-[#3B82F6] flex flex-row gap-2 items-center justify-center cursor-pointer"
          onClick={() => { navigate('/parking')}}
        >
          <History />
          {t("Show Parking History")}
        </div>
      </div>
      <SearchInput
        query={query}
        handleSearch={handleSearch}
        handleSearchEnter={handleSearchEnter}
        clearSearch={clearSearch}
      />
    </div>
    <TableFilter filters={filters} onFilterChange={handleFilterChange}  />
    <Table
      data={list.data}
      columns={cols}
      totalHits={list.totalHits}
      page={list.page}
      lastPage={list.totalHits >= 0 ? list.lastPage + 1 : 0}
      pageSize={list.pageSize}
      sortBy={""}
      sortDesc={false}
      filters={list.filters}
      search={list.search}
      fetchData={(p) => {
        return handleListQuery(p);
      }}
      onRowClick={onRowClick}
      spinner={VehicleSpinner}
    />
  </div>
}

export default ZonesLive;
