import React from 'react';
import { createRoot } from "react-dom/client";
import {
  RecoilRoot,
} from 'recoil';
import './i18n';
import { App } from "./App";

const theme = process.env.THEME_CONFIG || 'EuroPark';

// Conditional import based on the theme
const themeStyleSheet = theme === 'Spinn' ?
  import('../src/styling/spinn-fleet-management.less') :
  import('../src/styling/ep-fleet-management.less');

const root = createRoot(document.getElementById('app'));

themeStyleSheet.then(() => {
  root.render(
    <RecoilRoot>
      <App theme={theme} />
    </RecoilRoot>
  );
});
