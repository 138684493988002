import { useState, useEffect, useRef } from 'react';
import { Languages } from "../components/languages/languages";
import { useRoute, useLocation, Link as WLink } from "wouter";
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline'
import PlaceHolder from '../img/SideNav/user-circle.png';




export function SideNavTop({ appState }) {
  const [isOpen, setIsOpen] = useState(false);
  const [_, navigate] = useLocation();
  const containerRef = useRef(null);

  useEffect(() => {
    // Function to handle click outside the container
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        // Clicked outside the container, so close it
        setIsOpen(false);
      }
    }

    // Add event listener when component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  return (
    <>
      <div id="topNav" className="w-full h-20 items-center pl-7 pr-3.5 gap-4 py-3.5 bg-white flex-row justify-end items-end inline-flex" >
        <div className="w-[52px] h-[52px] flex-col justify-start items-start gap-2.5 inline-flex" ref={containerRef}>
          <button type="button" onClick={() => setIsOpen(!isOpen)} className={`h-[52px] ${isOpen ? "shadow border-2 border-gray-200" : "border-2 border-gray-100"}  px-3.5 bg-gray-100 rounded-full justify-start items-center gap-2 inline-flex"`}>
            <div className="w-6 h-6 relative"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g id="globe">
                <path id="Vector" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM10.289 19.793C9.1984 19.5439 8.17228 19.0685 7.27708 18.3976C6.38188 17.7267 5.63755 16.8753 5.0923 15.8985C4.54704 14.9217 4.21302 13.8412 4.11185 12.7271C4.01068 11.613 4.14462 10.4901 4.505 9.431L4.757 9.451C5.29011 9.7053 5.76197 10.0718 6.1403 10.5254C6.51863 10.9789 6.79449 11.5089 6.949 12.079C7.1152 12.7374 7.43449 13.3473 7.8809 13.8591C8.32731 14.3708 8.88822 14.77 9.518 15.024C10.726 15.581 10.979 17.143 10.289 19.793ZM14.079 10.5C14.0789 10.4201 14.0692 10.3405 14.05 10.263C13.869 9.44314 13.5102 8.67303 12.9989 8.00708C12.4876 7.34113 11.8363 6.79559 11.091 6.409C10.216 5.854 9.673 5.509 9.527 4.418C11.0839 3.89344 12.7647 3.86163 14.3403 4.32689C15.9159 4.79215 17.3098 5.73187 18.332 7.018C16.872 7.237 16.079 9.373 16.079 10.499C15.9722 10.6641 15.8231 10.7976 15.6473 10.8855C15.4714 10.9735 15.2752 11.0126 15.079 10.999C14.8829 11.0127 14.6868 10.9737 14.5109 10.886C14.335 10.7982 14.1859 10.6649 14.079 10.5ZM15.791 18.973L15.084 16.854C15.1181 16.3725 15.3252 15.9197 15.6671 15.5789C16.0089 15.2382 16.4624 15.0326 16.944 15L19.083 15.651C18.3437 17.0634 17.1967 18.2209 15.791 18.973Z" fill="#1F2A37" />
              </g>
            </svg>
            </div>
          </button>
          {isOpen && <div className="absolute z-50 top-20 p-4 bg-white rounded-lg justify-start items-center gap-3 inline-flex"><Languages /></div>}
        </div>
        <div className="w-max h-[52px] justify-start items-start inline-flex">
          <div className=" self-stretch pl-2.5 pr-3.5 bg-gray-100 rounded-full justify-between items-center flex">
            <div className="justify-start items-center gap-2 flex">
              <img className="w-8 h-8 relative rounded-[100px]" src={PlaceHolder} alt="User Profile image"/>
              <div className="flex-col justify-center items-start inline-flex">
                <div className="text-center text-slate-700 text-sm font-medium font-['Figtree'] leading-[14px]">{appState.name}</div>
              </div>
            </div>
            <div className="w-6 h-6 relative"></div>
          </div>
        </div>
        <button type="button"
          onClick={() => { navigate('/logout'); }}
          className="inline-flex items-center rounded gap-0.5 text-center text-sm font-medium text-blue-500 transition-all hover:bg-gray-100 focus:ring focus:ring-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400">
          <ArrowRightOnRectangleIcon className="h-[40px] w-[40px] text-gray-500 p-2" />
        </button>
      </div>

    </>
  );
}

