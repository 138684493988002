import { useState } from 'react';
import { PencilSquareIcon, CircleStackIcon, ChevronLeftIcon, TrashIcon } from '@heroicons/react/24/outline'
import {useT} from "../languages/t";
import Modal from "../modal/Modal";

const FormButtonRow = ({ state, editMode, onEditMode, onSave, onCancel, onDelete, deleteTitle, deleteMessage, errors }) => {

  const { t } = useT();

  const [showDelModal, setShowDelModal] = useState(false)

  if(!onEditMode) {
    onEditMode = () => {}
  }

  if(!onSave) {
    onSave = () => {}
  }

  if(!onCancel) {
    onCancel = () => {}
  }

  if(!deleteTitle) {
    deleteTitle = 'Delete?';
  }

  if(!deleteMessage) {
    deleteMessage = 'This action cannot be undone.';
  }

  if(!errors) {
    errors = {}
  }
  const hasErrors = Object.keys(errors).length > 0
  const hasChanges = Object.keys(state.changes).length > 0


  //console.log('FormButtonRow', state, ' showDelModal: ', showDelModal)

  return <div className="flex flex-none justify-between -mt-6 -mx-6 p-3 bg-gray-50 mb-6 border-b border-gray-100 min-h-[4.3rem]">
    {onDelete && showDelModal && <Modal open={showDelModal} setOpen={setShowDelModal} onConfirm={onDelete} title={deleteTitle} message={deleteMessage} />}
    {!editMode && <>
      <button onClick={onEditMode} type="button"
              className="h-11 inline-flex items-center rounded gap-0.5 px-2 py-2.5 text-center text-sm font-medium text-blue-500 transition-all hover:bg-gray-100 focus:ring focus:ring-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400">
        <PencilSquareIcon className="h-5 w-5 text-blue-500 mr-2" />{t("Edit")}
      </button>
      {onDelete && <button onClick={() => setShowDelModal(true)}>
        <TrashIcon className="h-5 w-5 text-red-500 mr-2" />
      </button>}
    </>
    }
    {editMode && <>
      <button onClick={() => { onCancel(); onEditMode()}} type="button" className="h-11 inline-flex items-center rounded gap-0.5 px-2 py-2.5 text-center text-sm font-medium text-blue-500 transition-all hover:bg-gray-100 focus:ring focus:ring-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400">
        <ChevronLeftIcon className="h-5 w-5 text-blue-500 mr-2" />{t("Back")}</button>
      <div className="whitespace-nowrap flex">
        <button onClick={onCancel} disabled={!hasChanges} className="inline-flex text-red-400 px-4 py-2.5 disabled:text-gray-300" >{t("cancel")}</button>
        <div className="text-gray-800 m-0 mr-4 py-2.5">{t("or")}</div>
        <button onClick={onSave} disabled={!hasChanges || hasErrors} type="button"
                className={`h-11 inline-flex items-center rounded border border-primary-100 bg-primary-100 px-4 py-2.5 text-center text-sm font-medium text-primary-600 transition-all hover:border-primary-200 hover:bg-primary-200 focus:ring focus:ring-primary-50 disabled:border-primary-50 disabled:bg-primary-50 disabled:text-primary-400 ${hasErrors && ' border-red-50 bg-red-50 text-red-300 disabled:border-red-50 disabled:bg-red-50 disabled:text-red-300'}`}>
          <CircleStackIcon className={`h-5 w-5 text-blue-500 mr-2 ${hasErrors && ' text-red-300 '}`} />{t("Save")}
        </button>
      </div>
    </>
    }
  </div>
}

export default FormButtonRow
