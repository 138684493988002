import T from "../components/languages/t";
import { Languages } from "../components/languages/languages";
import Logo from '../img/SideNav/LogoContainer.svg';
import { useRoute, useLocation, Link as WLink } from "wouter";
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline'
import { TopNav } from "./TopNav";
import Car from '../img/SideNav/car.svg';
import File from '../img/SideNav/file-export.svg';
import List from '../img/SideNav/list.svg';
import Map from '../img/SideNav/map-pin.svg';
import Ticket from '../img/SideNav/ticket.svg';
import User from '../img/SideNav/users-group.svg';

function Link(props) {
  const [isActive] = useRoute(props.to + '/:rest*');

  return (
    <WLink {...props}>
      <li className={`${isActive ? 'bg-violet-50 text-sky-95 activeLink' : 'bg-transparent text-white'} navigation-link transition duration-300 ease-linear select-none  p-2 w-full rounded-xl  hover:text-sky-950 flex-col justify-center items-center flex hover:bg-slate-400`} ><a className={`cursor-pointer flex flex-col items-center text-center text-xs font-medium font-['Figtree'] leading-[18px] justify-center gap-0.5`}>{props.children}</a></li>
    </WLink>
  );
}



export function SideNav({ appState }) {

  const [_, navigate] = useLocation();

  return (
    <>
      <div id="sideNav" className="bg-gradient-to-b w-[92px] h-full from-sky-950 to-indigo-900 flex-col justify-start items-start inline-flex">
        <a href="#" className="w-full  h-[79px]  py-3.5 flex-col justify-center items-center gap-2.5 inline-flex "><Logo /></a>
        <nav className="flex-1">
          <ul className="w-full rounded-xl flex-col justify-center items-center gap-0.5 flex">
            <div className="px-2 pt-2 flex-col justify-start items-start gap-3 inline-flex">
              {((appState.operators || []).length > 0  || appState.can_view_sessions) && <Link to={`/map`}>  <Map /><T>Map</T>   </Link>}
              {((appState.operators || []).length > 0  || appState.can_view_sessions) && <Link to={`/parking`}> <List /><T>Parking Sessions</T></Link>}
              {(appState.fleets || []).length > 0 && <Link to={`/fines`}> <Ticket /><T>Fines</T></Link>}
              {(appState.fleets || []).length > 0 && <Link to={`/vehicles`}>  <Car /><T>Vehicles</T></Link>}
              <Link to={`/users`}> <User /><T>Users</T></Link>
              {((appState.operators || []).length > 0  || appState.can_view_sessions) && <Link to={`/reports`}> <File /><T>Reports</T></Link>}
            </div>
          </ul>
        </nav>

      </div>
      {/* <div className="w-full pl-7 pr-3.5 py-3.5 bg-white flex-row justify-end items-end inline-flex" >
        <div>Logged in user</div>
          <Languages></Languages>
          <button type="button"
            onClick={() => { navigate('/logout'); }}
            className="-mr-6 ml-4 inline-flex items-center rounded gap-0.5 px-2 py-2.5 text-center text-sm font-medium text-blue-500 transition-all hover:bg-gray-100 focus:ring focus:ring-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400">
            <ArrowRightOnRectangleIcon className="h-[40px] w-[40px] text-gray-500 p-2" />
          </button>

      </div> */}
    </>
  );
}

