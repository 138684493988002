import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

const en = require('../dist/locales/en.json');
const et = require('../dist/locales/et.json');
const fi = require('../dist/locales/fi.json');
const sv = require('../dist/locales/sv.json');
const ru = require('../dist/locales/ru.json');

// Get saved language
const savedValue = localStorage.getItem('fm_app_state')
let language = 'et';
if (savedValue != null) {
  const appState = (JSON.parse(savedValue));
  language = appState.language || (appState.languages && appState.languages[0]) || 'et';
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}.json'
    },
    fallbackLng: 'et',
    lng: language,
    debug: false,
    resources: {
      "en": {
        "fleet-management-admin": en
      },
      "et": {
        "fleet-management-admin": et
      },
      "fi": {
        "fleet-management-admin": fi
      },
      "sv": {
        "fleet-management-admin": sv
      },
      "ru": {
        "fleet-management-admin": ru
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export const tFixed = i18n.getFixedT(language, 'fleet-management-admin');

export default i18n;
