import React, { useState } from "react";
import { useT } from "../../components/languages/t";
import ParkingZone from "../../img/parkZone.png"

const ZonePopupContent = ({ zone }) => {
  const [showAllPlates, setShowAllPlates] = useState(false);

  const { t } = useT();

  const showMorePlates = () => {
    // Implement the logic for showing more plates here
    setShowAllPlates((prevShowAllPlates) => !prevShowAllPlates);
  };

  return (
    <div>
      <div className="flex pr-12 pl-4 flex-row items-center bg-[#F9FAFB] rounded-t-lg">
        <img className="w-8 h-8" src={ParkingZone} alt="Zone Icon" />
        <div className="font-semibold flex items-center justify-center text-lg gap-2 p-4 text-[#1b1c20] font-medium">
          {zone.name}
        </div>
      </div>
      <div className="flex gap-2 px-3 py-4 text-base text-[#1b1c20] font-medium border-b">
        {zone.aadress}
      </div>
      <div className="flex text-[#6B7280] justify-between font-normal"></div>
      <div className="flex flex-col border-t-2 p-3 gap-y-3">
        <div className="flex justify-between text-base">
          <p>{t("Vehicles Parked")}</p> {zone.sessions.length}
        </div>
        <div className="flex flex-row flex-wrap gap-1">
          {/* Displaying parked vehicle plates without using dangerouslySetInnerHTML */}
          {zone.parkedVehiclePlate
            .slice(0, showAllPlates ? zone.parkedVehiclePlate.length : 3)
            .map((plate, index) => (
              <div key={index} className="px-2 py-1 bg-[#F9FAFB] text-xs">
                {plate}
              </div>
            ))}
          {/* Show more/less button logic */}
          {zone.parkedVehiclePlate.length > 3 && (
            <button
              className="px-2 py-1 bg-[#FFFFFF] rounded-2 text-xs text-[#3B82F6] font-medium"
              onClick={showMorePlates}
            >
              {showAllPlates
                ? ` - ${Math.max(0, zone.parkedVehiclePlate.length - 3)}`
                : ` + ${Math.max(0, zone.parkedVehiclePlate.length - 3)}`}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ZonePopupContent;
