import { useEffect, useState, useCallback } from "react";
import T, { useT } from "../../components/languages/t";
import { useResource } from "../../useResource";
import Form from "../../components/form/Form";
import FormTitle from "../../components/form/FormTitle";
import FormDetails from "../../components/form/FormDetails";
import UserStatusBadge from "./userStatusBadge";
import FormButtonRow from "../../components/form/FormButtonRow";
import TextArea from "../../components/formElements/TextArea";
import TextInput from "../../components/formElements/TextInput";
import SelectBox from "../../components/formElements/Select";
import TagInput from "../../components/formElements/TagInput";
import { useReloadList } from "../../useList";
import MultiSelect from "../../components/formElements/MultiSelect";
import { useLocation } from "wouter";
import * as y from "yup";
import { useValidate } from "../../components/form/useValidate";
import VehicleSpinner from "../../components/spinner/VehicleSpinner";
import FormSaveMessage from "../../components/form/FormSaveMessage";
import {ResourceDataStore} from "../../store/store";
import {useRecoilState} from "recoil";

const userRoles = {
  CUSTOMER_SERVICE: "Customer Service",
  MANAGER: "Manager",
  ADMIN: "Administrator",
};

const user_statuses = {
  INACTIVE: UserStatusBadge("INACTIVE"),
  ACTIVE: UserStatusBadge("ACTIVE"),
};

const userSchema = y.object({
  status: y.string().oneOf(["INACTIVE", "ACTIVE"]),
  name: y.string().required().min(3).label("Name"),
  login_email: y.string().email().required().label("Contact/Login Email"),
  fleets: y.array().nullable(),
  operators: y.array().nullable(),
  belongs_to: y.string().required().label("Belongs to"),
})/* FIXME: below not working
  .shape({
  fleet_id: y.string().nullable().label("Belongs to").when('operator_id', {
    is: null, // alternatively: (val) => val == true
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  operator_id: y.string().nullable().label("Belongs to").when('fleet_id', {
    is: null, // alternatively: (val) => val == true
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
}, [['fleet_id', 'operator_id']]);
*/

const UserDetail = (props) => {
  const { t } = useT();
  const [_, navigate] = useLocation();

  const { state, handleChanges, handleReset, handleSave, handleDelete, isLoading, setState } =
    useResource(`/users/${props.params.userId}`);

  let stateValidate = state

  // Fix for belongs_to validation
  if(state.data.id) {
    stateValidate = {
      ...state,
      data: {...state.data, ...{'belongs_to': state.data.fleet_id || state.data.operator_id}}
    }

  }

  const data = Object.assign({}, state.data, state.changes);

  // console.log(data, "data");
  const { list: users, reloadListQuery } = useReloadList("/users/list");

  const { errors } = useValidate(stateValidate, userSchema);

  const fleets_and_operators = Object.values(state.related.fleets || {}).reduce((r, v) => {
    r[ v.type + ':' + v.id] = v.name;
    return r;
  }, {});

  const fleets = Object.values(state.related.fleets || {}).reduce((r, v) => {
    if(v.type === 'FLEET') {
      r[v.id] = v.name;
    }
    return r;
  }, {});

  const operators = Object.values(state.related.fleets || {}).reduce((r, v) => {
    if(v.type === 'OPERATOR') {
      r[v.id] = v.name;
    }
    return r;
  }, {});

  const [editMode, setEditMode] = useState(false);
  const [serverResult, setServerResult] = useState(null);

  const belongsTo = (data.fleet_id ? 'FLEET:' + data.fleet_id: null) ||  (data.operator_id ? 'OPERATOR:' + data.operator_id: null) || '';
  const handleBelongsTo = useCallback((value) => {
    const m = value.match(/(\w+):(.+)/)
    if(m) {
      if (m[1] === 'FLEET') {
        setState({fleet_id: m[2], operator_id: null, belongs_to: m[2]})
      } else if (m[1] === 'OPERATOR') {
        setState({operator_id: m[2], fleet_id: null, belongs_to: m[2]})
      }
    }

  },[])

  useEffect(() => {
    if (props.params.userId === "new" && editMode === false) {
      setEditMode(true);
    }
    setServerResult(null)
   }, [props.params.userId]);

  if (Object.keys(data).length === 0 || isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center max-h-full min-h-full bg-white p-6 place-items-center">
        <VehicleSpinner />
      </div>
    );
  }

 //console.log('UserDetail', 'state:', state, 'errors:', errors)

  return (
    <Form state={state}>
      <FormButtonRow
        state={state}
        errors={errors}
        editMode={editMode}
        onEditMode={() => {
          setEditMode(!editMode);
        }}
        onSave={() => {
          return handleSave().then(() => {
            reloadListQuery();
          }).catch((e) => {
            setServerResult({ title: t('Error saving User'), error: e })
          });
        }}
        onCancel={handleReset}
        onDelete={() => {
          handleDelete().then(() => {
            reloadListQuery();
            navigate("/users");
          });
        }}
      ></FormButtonRow>
      <FormTitle title={data.name} subtitle={userRoles[data.role]} />
      <FormSaveMessage {...serverResult} />
      <FormDetails
        editMode={editMode}
        data={{
          status: {
            label: t("Status"),
            edit: (
              <SelectBox
                id="status"
                value={data.status}
                placeholder="Please select a status"
                options={user_statuses}
                onChange={(v) => {
                  handleChanges("status")(v);
                }}
              />
            ),
            display: UserStatusBadge(data.status),
            required: true,
            errorMessage: errors["status"],
          },
          name: {
            label: t("Name"),
            edit: (
              <TextInput
                id="name"
                value={data.name}
                placeholder="Please fill the name"
                onChange={handleChanges("name")}
                isInvalid={!!errors["name"]}
                isTouched={!!state.changes["name"]}
              />
            ),
            required: true,
            errorMessage: errors["name"],
          },
          belongs_to: {
            label: t("Belongs to"),
            display: (
              <div className="space-x-2">
                <div className="inline-flex items-center gap-1 rounded bg-gray-100 px-2 py-1 my-2 text-xs font-semibold text-gray-600">
                  {fleets_and_operators[belongsTo]}
                </div>
              </div>
            ),
            edit: (
              <SelectBox
                id="belongs_to"
                value={belongsTo}
                placeholder="Please the role"
                options={fleets_and_operators}
                onChange={handleBelongsTo}
                isInvalid={!!errors["fleet_id"] || !!errors["operator_id"] || !!errors["belongs_to"]}
                isTouched={!!state.changes["fleet_id"] || !!state.changes["operator_id"]}
              />
            ),
            required: true,
            errorMessage: errors["fleet_id"],
          },
          role: {
            label: t("Role"),
            edit: (
              <SelectBox
                id="role"
                value={data.role}
                placeholder="Please the role"
                options={userRoles}
                onChange={handleChanges("role")}
              />
            ),
            required: true,
            errorMessage: errors["role"],
          },
          description: {
            label: t("Description"),
            display: data.description,
            edit: (
              <TextArea
                id="description"
                value={data.description || ''}
                placeholder="Please fill in a description"
                onChange={handleChanges("description")}
                isTouched={!!state.changes["description"]}
              />
            ),
          },
          login_email: {
            label: t("Contact/Login Email"),
            display: data.login_email,
            edit: (
              <TextInput
                id="login_email"
                value={data.login_email}
                placeholder="Please fill in a contact email"
                onChange={handleChanges("login_email")}
                isInvalid={!!errors["login_email"]}
                isTouched={!!state.changes["login_email"]}
              />
            ),
            required: true,
            errorMessage: errors["login_email"],
          },
          password: {
            label: t("Password"),
            edit: (
              <TextInput
                id="password"
                value={data.password}
                placeholder="Fill in the field to change password"
                onChange={handleChanges("password")}
                isTouched={!!state.changes["password"]}
              />
            ),
          },
          contact_phone: {
            label: t("Contact Phone"),
            display: data.contact_phone,
            edit: (
              <TextInput
                id="contact_phone"
                value={data.contact_phone}
                placeholder="Please fill in a contact phone"
                onChange={handleChanges("contact_phone")}
                isTouched={!!state.changes["contact_phone"]}
              />
            ),
          },
          fleets: {
            label: t("Fleets"),
            display: (
              <div className="space-x-2">
                {(data.fleets || []).map((v) => (
                  <div
                    key={v}
                    className="inline-flex items-center gap-1 rounded bg-gray-100 px-2 py-1 my-2 text-xs font-semibold text-gray-600"
                  >
                    {fleets[v] ? fleets[v] : v}
                  </div>
                ))}
              </div>
            ),
            //"edit": <TagInput id="fleets" value={data.fleets} placeholder="Add fleets" onChange={handleChanges('fleets')} />
            edit: (
              <MultiSelect
                id="fleets"
                value={data.fleets || []}
                placeholder="Please choose a fleet"
                options={fleets || []}
                onChange={(v) => {
                  //handleChanges('fleet_name')(fleets[v]);
                  handleChanges("fleets")(v);
                }}
                isInvalid={!!errors["fleets"]}
                isTouched={!!state.changes["fleets"]}
              />
            ),
            required: true,
            errorMessage: errors["fleets"],
          },
          operators: {
            label: t("Parking Operators"),
            display: (
              <div className="space-x-2">
                {(data.operators || []).map((v) => (
                  <div
                    key={v}
                    className="inline-flex items-center gap-1 rounded bg-gray-100 px-2 py-1 my-2 text-xs font-semibold text-gray-600"
                  >
                    {operators[v] ? operators[v] : v}
                  </div>
                ))}
              </div>
            ),
            //"edit": <TagInput id="fleets" value={data.fleets} placeholder="Add fleets" onChange={handleChanges('fleets')} />
            edit: (
              <MultiSelect
                id="fleets"
                value={data.operators || []}
                placeholder="Please choose a fleet"
                options={operators}
                onChange={(v) => {
                  //handleChanges('fleet_name')(fleets[v]);
                  handleChanges("operators")(v);
                }}
                isInvalid={!!errors["operators"]}
                isTouched={!!state.changes["operators"]}
              />
            ),
            required: true,
            errorMessage: errors["operators"],
          },


        }}
      />

      <fieldset className="border-2 border-solid border-gray-100 p-3">
        <legend className="p-2 font-semibold leading-none text-gray-900">
          <T>Send daily notifications</T>
        </legend>
        {(data.daily_notifications_to || []).length > 0 || editMode ?
        <div className="-mt-2">
          <div className="whitespace-nowrap align-middle">
            <label
              htmlFor="daily_notifications_to"
              className="block mb-2 leading-none text-gray-900"
            >
              <T>to email(s)</T>
            </label>
          </div>
          <div className="">
            {editMode ? (
              <TagInput
                id="daily_notifications_to"
                placeholder="your@email.com"
                pattern=".+@.+\.[A-Za-z]+$"
                value={data.daily_notifications_to || []}
                onChange={handleChanges("daily_notifications_to")}
              />
            ) : (
              <div className="space-x-2">
                {(data.daily_notifications_to || []).map((v) => (
                  <div
                    key={v}
                    className="inline-flex items-center gap-1 rounded bg-gray-100 px-2 py-1 my-2 text-xs font-semibold text-gray-600"
                  >
                    {v}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="mt-4 whitespace-nowrap align-middle">
            <label
              htmlFor="send_daily_notifications_at"
              className="block mb-2 leading-none text-gray-900"
            >
              <T>at times</T>
            </label>
          </div>
          <div className="">
            {editMode ? (
              <TagInput
                id="send_daily_notifications_at"
                placeholder="22:00"
                pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
                value={data.send_daily_notifications_at || []}
                onChange={handleChanges("send_daily_notifications_at")}
              />
            ) : (
              <div className="space-x-2">
                {(data.send_daily_notifications_at || []).map((v) => (
                  <div
                    key={v}
                    className="inline-flex items-center gap-1 rounded bg-gray-100 px-2 py-1 my-2 text-xs font-semibold text-gray-600"
                  >
                    {v}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div> : <div className="-mt-3 text-gray-400"><T>No notifications configured</T></div>}
      </fieldset>
      <fieldset className="border-2 border-solid border-gray-100 p-3">
        <legend className="p-2 font-semibold leading-none text-gray-900">
          <T>Send immediate notifications</T>
        </legend>
        {(data.immediate_notifications_to || []).length > 0 || editMode ?
        <div className="-mt-2">
          <div className="whitespace-nowrap align-middle">
            <label
              htmlFor="immediate_notifications_to"
              className="block mb-2 leading-none text-gray-900"
            >
              <T>to email(s)</T>
            </label>
          </div>
          <div className="">
            {editMode ? (
              <TagInput
                id="immediate_notifications_to"
                placeholder="your@email.com"
                pattern=".+@.+\.[A-Za-z]+$"
                value={data.immediate_notifications_to || []}
                onChange={handleChanges("immediate_notifications_to")}
              />
            ) : (
              <div className="space-x-2">
                {(data.immediate_notifications_to || []).map((v) => (
                  <div
                    key={v}
                    className="inline-flex items-center gap-1 rounded bg-gray-100 px-2 py-1 my-2 text-xs font-semibold text-gray-600"
                  >
                    {v}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div> : <div className="-mt-3 text-gray-400"><T>No notifications configured</T></div>}
      </fieldset>
      <fieldset className="border-2 border-solid border-gray-100 p-3">
        <legend className="p-2 font-semibold leading-none text-gray-900">
          <T>Send price threshold alerts</T>
        </legend>
        {(data.send_price_reminders_to || []).length > 0 || editMode ?
          <div className="-mt-2">
            <div className="whitespace-nowrap align-middle">
              <label
                htmlFor="send_price_reminders_to"
                className="block mb-2 leading-none text-gray-900"
              >
                <T>to email(s)</T>
              </label>
            </div>
            <div className="">
              {editMode ? (
                <TagInput
                  id="send_price_reminders_to"
                  placeholder="your@email.com"
                  pattern=".+@.+\.[A-Za-z]+$"
                  value={data.send_price_reminders_to || []}
                  onChange={handleChanges("send_price_reminders_to")}
                />
              ) : (
                <div className="space-x-2">
                  {(data.send_price_reminders_to || []).map((v) => (
                    <div
                      key={v}
                      className="inline-flex items-center gap-1 rounded bg-gray-100 px-2 py-1 my-2 text-xs font-semibold text-gray-600"
                    >
                      {v}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="mt-4 whitespace-nowrap align-middle">
              <label
                htmlFor="price_reminder_threshold"
                className="block mb-2 leading-none text-gray-900"
              >
                <T>if the price exceeds</T>
              </label>
            </div>
            <div className="">
              {editMode ? (
                <TextInput type="number" id="price_reminder_threshold"
                       placeholder={t('for example 15')}
                           value={data.price_reminder_threshold}
                           onChange={handleChanges("price_reminder_threshold")}
                />
              ) : (
                <div
                  className="inline-flex items-center gap-1 rounded bg-gray-100 px-2 py-1 my-2 text-xs font-semibold text-gray-600"
                >
                  {data.price_reminder_threshold} €
                </div>
              )}
            </div>
          </div> : <div className="-mt-3 text-gray-400"><T>No notifications configured</T></div>}
      </fieldset>
    </Form>
  );
};

//    <pre><code>{JSON.stringify(data, null, 2)}</code></pre>

export default UserDetail;
