import React from "react";
import { useT } from "../../components/languages/t";

const SearchHistory = ({
  searchHistory,
  isInputActive,
  currentSearch,
  suggestions,
  handleSuggestionClick,
}) => {
  if (!isInputActive || !currentSearch) {
    return null; // Don't render the search history if the input is not active or there is no current search query
  }

  const { t } = useT();

  const matchingHistory = searchHistory.filter((entry) =>
    entry.toLowerCase().includes(currentSearch.toLowerCase())
  );

  if (matchingHistory.length === 0) {
    return null; // No matching history, don't render anything
  }
  console.log("loaded");
  return (
    <>
      {isInputActive && searchHistory.length > 0 && (
        <div className="mt-2 w-full flex gap-2 flex-col border border-[#D1D5DB] p-4 bg-white rounded-lg shadow border border-gray-300 gap-3 inline-flex">
          <p className="text-neutral-700 text-sm font-semibold 14/500/Normal font-['Proxima Nova'] leading-tight">
            {t("Recent")}
          </p>
          <div className="pl-5">
            {suggestions.map((suggestion, index) => (
              <div
                key={index}
                className="text-gray-500 text-sm font-normal font-['Proxima Nova'] leading-tight"
              >
                {suggestion.subject ? (
                  <>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSuggestionClick(suggestion);
                      }}
                    >
                      {suggestion.subject}
                    </a>
                   
                  </>
                ) : (
                  <>{suggestion.location}</>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SearchHistory;
