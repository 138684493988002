import {forwardRef, memo } from 'react'
const TextInput = memo(forwardRef(function TextInput({ id, value, placeholder, onChange, type, required, isInvalid, isTouched}, ref) {

  if (!type) {
    type = "text"
  }

  const extraCls = isInvalid ? ' border-red-300 focus:border-red-300 focus:ring-red-200 focus:ring-opacity-50 ': (isTouched && ' border-green-400 focus:border-green-400 focus:ring-green-200 focus:ring-opacity-50 ');

  return <input type={type}
                id={id}
                ref={ref}
           className={`block w-full rounded border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500  placeholder:italic placeholder:text-slate-400 placeholder:text-sm ${extraCls}`}
           placeholder={placeholder} value={value || ''} onChange={onChange}/>

}), (oldProps, newProps) => {
  //console.log(oldProps.id, 'oldProps.id === newProps.id', oldProps.id === newProps.id, 'oldProps.value === newProps.value', oldProps.value === newProps.value)
  return (oldProps.id === newProps.id && JSON.stringify(oldProps.value) === JSON.stringify(newProps.value) && oldProps.isInvalid === newProps.isInvalid && oldProps.isTouched === newProps.isTouched)
})

export default TextInput;
