import { useMemo, useEffect, useCallback } from "react";
import {AppStore, ListResourceQueryResult} from "../../store/store";
import {useRecoilState, useRecoilValue} from "recoil";
import VehicleSpinner from "../../components/spinner/VehicleSpinner";
import {Table} from "../../components/table/table";
import {useT} from "../../components/languages/t";
import SearchInput from "../../components/formElements/SearchInput";
import {useSearch} from "../../components/form/useSearch";
import History from "../../img/history.svg";
import differenceInMinutes from "date-fns/differenceInMinutes";
import parseISO from "date-fns/parseISO";
import {useList} from "../../useList";
import {MarkerPopupState} from "./Map";
import {useLocation} from "wouter";
import {useFleets} from "../../useFleets";
import {useFilter} from "../../components/form/useFilter";
import TableFilter from "../../components/table/TableFilter";

const { FM_API_URL, PRICE_THRESHOLD } = process.env;
const re2 = new RegExp(/([a-z])(?=[0-9])/gi);
const re3 = new RegExp(/([0-9])(?=[a-z])/gi);



const VehiclesLive = () => {

  const { t, i18n } = useT();
  const [_, navigate] = useLocation();
  const appState = useRecoilValue(AppStore)
  const fleetsNoperators = useFleets()
  const [markerState, setMarkerState] = useRecoilState(MarkerPopupState)
  const { list, handleListQuery } = useList('/parkings/live')
  const { query, handleSearch, handleSearchEnter, clearSearch } = useSearch(
    "/parkings/live",
    handleListQuery
  );

  const fleets = useMemo(() => { return Object.values(fleetsNoperators || {}).reduce((r, v) => {
    if(v.type === 'FLEET') {
      r[v.id] = v.name;
    }
    return r;
  }, {}); },[fleetsNoperators])

  const operators = useMemo(() => { return Object.values(fleetsNoperators || {}).reduce((r, v) => {
    if(v.type === 'OPERATOR') {
      r[v.id] = v.name;
    }
    return r;
  }, {}); },[fleetsNoperators])

  const filterOpts = {
    client_id: {
      title: t("Fleet"),
      options: fleets,
    }
  }

  const { filters, handleFilterChange } = useFilter('/parkings/live', handleListQuery, filterOpts)

  const cols = useMemo(() => {
    return [
      {
        header: t(["plateColumn", "Number Plate"]),
        id: "subject",
        minWidth: "8ch",
        accessorFn: (r) => {
          return (
            <span className="whitespace-nowrap tabular-nums">
              {r.subject
                .replace(/\s/g, "")
                .replace(re2, "$1 ")
                .replace(re3, "$1 ")}
            </span>
          );
        },
      },
      /*
      {
        header: t(["fleetColumn", "Fleet"]),
        id: "fleet_id",
        accessorKey: "fleet_name",
      },
       */
      {
        header: t(["zoneCodeColumn", "Zone Code"]),
        id: "locality_code",
        minWidth: "8ch",
        accessorKey: "locality_code",
      },
      {
        header: t(["zoneColumn", "Zone Address"]),
        id: "locality_name",
        minWidth: "8ch",
        accessorKey: "locality_name",
      },
      {
        header: t(["parkedTimeColumn", "Parked Time"]),
        id: "session_started_at",
        minWidth: "8ch",
        accessorFn: (r) => {
          return displayTimeDifference(r.session_started_at, new Date(), t)
        }
      },
      {
        header: t(["priceNetColumn", "Netto"]),
        id: "price_net",
        minWidth: "8ch",
        accessorFn: (r) => {
          return <div
            className={`w-max px-3 py-2 rounded ${
              r.price_net !== null &&
              r.price_net > (appState.price_reminder_threshold || PRICE_THRESHOLD)
                ? "text-[#ef4444] bg-[#fef2f2]"
                : ""
            }`}
          >
            {/* Display the calculated net price with VAT */}
            {r.price_net !== null ? (
              <p>{r.price_net} €</p>
            ) : (
              <p>0.00 €</p>
            )}
          </div>
        }
      },
    ];
  }, [i18n.language, appState.price_reminder_threshold]);



  const onRowClick = (v) => {
    const popData = {
      type: "VEHICLE",
      marker: null,
      coords: [v.subject_location.coordinates[0][1],v.subject_location.coordinates[0][0]],
      data: {
        'id': v.session_id,
        'fleet_id': v.client_id,
        'subject': v.subject,
        'session_started_at': v.session_started_at,
        'price_net': v.price_net,
        'locality_code': v.locality_code,
        'locality_name': v.locality_name,
        'price_reminder_threshold': appState.price_reminder_threshold
      },
      fly: true
    }
    setMarkerState(popData)
  }

  //console.log('VehiclesLive', list, appState, 'pt:', appState.price_reminder_threshold || PRICE_THRESHOLD)

  return <div className="flex flex-1 h-[400px] flex-col mx-3 mb-1 relative">
    <div className="flex flex-col min-h-[140px] max-h-[140px]">
      <div className="flex flex-1 items-center justify-center pt-3 pb-3 px-5 gap-2 mt-3 border-t text-base">
        <div
          className="text-[#3B82F6] flex flex-row gap-2 items-center justify-center cursor-pointer"
          onClick={() => { navigate('/parking')}}
        >
          <History />
          {t("Show Parking History")}
        </div>
      </div>
      <SearchInput
        query={query}
        handleSearch={handleSearch}
        handleSearchEnter={handleSearchEnter}
        clearSearch={clearSearch}
      />
    </div>
    <TableFilter filters={filters} onFilterChange={handleFilterChange}  />
      <Table
        data={list.data}
        columns={cols}
        totalHits={list.totalHits}
        page={list.page}
        lastPage={list.totalHits >= 0 ? list.lastPage + 1 : 0}
        pageSize={list.pageSize}
        sortBy={""}
        sortDesc={false}
        filters={list.filters}
        search={list.search}
        fetchData={(p) => {
          return handleListQuery(p);
        }}
        onRowClick={onRowClick}
        spinner={VehicleSpinner}
      />
  </div>
}

export const displayTimeDifference = (startedAt, updatedTime, t) => {
  const differenceInMinutesValue = differenceInMinutes(
    updatedTime,
    parseISO(startedAt)
  );

  if (differenceInMinutesValue >= 60) {
    const days = Math.floor(differenceInMinutesValue / (24 * 60));
    const hours = Math.floor((differenceInMinutesValue % (24 * 60)) / 60);
    const remainingMinutes = differenceInMinutesValue % 60;

    if (remainingMinutes === 0) {
      if (days > 0) {
        return `${days} ${days === 1 ? t("day") : t("days")} ${hours} ${
          hours === 1 ? t("hour") : t("hours")
        } ${t("ago")}`;
      } else {
        return `${hours} ${hours === 1 ? t("hour") : t("hours")} ${t("ago")}`;
      }
    } else {
      if (days > 0) {
        return `${days} ${days === 1 ? t("day") : t("days")} ${hours} ${
          hours === 1 ? t("hour") : t("hours")
        } ${t("and")} ${remainingMinutes} ${
          remainingMinutes === 1 ? t("minute") : t("minutes")
        } ${t("ago")}`;
      } else {
        return `${hours} ${hours === 1 ? t("hour") : t("hours")} ${t(
          "and"
        )} ${remainingMinutes} ${
          remainingMinutes === 1 ? t("minute") : t("minutes")
        } ${t("ago")}`;
      }
    }
  } else {
    return `${differenceInMinutesValue} ${
      differenceInMinutesValue === 1 ? t("minute") : t("minutes")
    } ${t("ago")}`;
  }
};

export default VehiclesLive;
