{
  "Page": "Lehekülg",
  "show": "näita",
  "Edit": "Muuda",
  "Back": "Tagasi",
  "cancel": "tühista",
  "or": "või",
  "Save": "Salvesta",
  "Fines": "Trahvid",
  "Vehicles": "Sõidukid",
  "Number Plate": "Sõiduk",
  "Fleet": "Autopark",
  "Total Fines": "Trahvid kokku",
  "Unpaid Fines": "Maksmata trahvid",
  "Amount due": "Summa kokku",
  "Amount paid": "Makstud summa",
  "Fine Number": "Trahv",
  "Issued At": "Väljastatud kp.",
  "Location": "Asukoht",
  "Fine Amount": "Hind",
  "Paid Amount": "Makstud summa",
  "Users": "Kasutajad",
  "New User": "Uus kasutaja",
  "New Vehicle": "Uus sõiduk",
  "Status": "Olek",
  "Name": "Nimi",
  "Role": "Roll",
  "Description": "Kirjeldus",
  "Contact/Login Email": "Kontakt/Sisselogimise epost",
  "Password": "Salasõna",
  "Contact Phone": "Kontakttelefon",
  "Fleets": "Autopark(id)",
  "Login Email": "Epost",
  "Last Sign In": "Sisselogis viimati",
  "Sign In IP": "IP-lt",
  "Send daily notifications": "Igapäevased teated saadetakse",
  "to email(s)": "emailidele",
  "at times": "kellaaegadel",
  "Send immediate notifications": "Saada teated koheselt",
  "Due At": "Maksetähtaeg",
  "Reason": "Põhjus",
  "Details": "Kirjeldus",
  "Amount Due": "Makstav summa",
  "Amount Paid": "Makstud summa",
  "Parking Area": "Asukoht",
  "Images": "Pildid",
  "Quick search...": "otsi...",
  "Belongs to fleet": "Kuulub autopargile",
  "Delete": "Kustuta",
  "Delete?": "Kustuta?",
  "This action cannot be undone.": "Seda toimingut ei ole võimalik tagasi võtta.",
  "Sent to Debt Collection": "Saadetud inkassole",
  "Receiver Info": "Trahvi saaja andmed",
  "Name of fine receiver": "Trahvi saaja nimi",
  "Please fill in the full name of the fine receiver": "Sisestage trahvi saaja täisnimi",
  "Email of fine receiver": "Trahvi saaja e-post",
  "We will send a copy of the fine to this email address": "Saadame trahvi koopia sellele e-posti aadressile",
  "Contact mobile of fine receiver": "Trahvi saaja kontakt telefon",
  "Alternatively payment reminders are sent to this number": "Alternatiivselt saadetakse sellele numbrile maksemeeldetuletused",
  "The fine will be sent to the customer after 30 minutes after pressing the button. When the fine has been sent the receiver details cannot be changed.": "Trahv saadetakse kliendile 30 minuti pärast peale nupu vajutamist. Kui trahv on saadetud, ei saa saaja andmeid muuta.",
  "Save and Send the Fine": "Salvesta ja saada trahv",
  "Fine Receicer Entered": "Trahvi saaja",
  "No": "Ei",
  "Payment Overdue": "Maksetähtaeg ületatud",
  "Fine is paid. Thank you!": "Trahv on makstud. Täname!",
  "The fine is closed.": "Trahv on suletud",
  "The fine has been revoked.": "Trahv on tühistatud.",
  "Payment due soon": "Ootab maksmist",
  "No receiver events yet": "Sündmused puuduvad",
  "Event": "Sündmus",
  "Date": "Kuupäev",
  "By": "Kellelt",
  "Active": "Aktiivne",
  "Inactive": "Inaktiivne",
  "Deleted": "Kustutatud",
  "Zones": "Tsoonid",
  "Zone": "Tsoon",
  "Vehicles Parked": "Sõidukid pargitud",
  "Plate N": "Plaadi Nr",
  "Current Price": "Praegune Hind",
  "Show Parking History": "Näita parkimise ajalugu",
  "Vehicle Number / Zone": "Sõiduki Number / Tsoon",
  "Parking History": "Parkimise Ajalugu",
  "Parking Started": "Parkimine Alustatud",
  "Parking Ended": "Parkimine Lõpetatud",
  "Total Parking": "Kokku pargitud",
  "Price": "Hind",
  "Time Period": "Ajaperiood",
  "Parking Operator": "Parkimise Operaator",
  "Map": "Kaart",
  "No matching vehicles found": "Ühtegi sobivat sõidukit ei leitud",
  "Recent": "Viimased",
  "No matching zones found": "Ühtegi sobivat tsooni ei leitud",
  "Total": "Kokku",
  "Client": "Klient",
  "minute": "m",
  "hour": "t",
  "and": "",
  "Parked Time": "Pargitud Aeg",
  "minutes": "m",
  "hours": "t",
  "ago": "",
  "Fine Notice Sent": "Trahviteade saadetud",
  "Yes": "Jah",
  "Comment": "Komentaar",
  "Parking Fee": "Parkimistasu",
  "Choose a row to the left": "Vali vasakult rida",
  "day": "p",
  "days": "p",
  "Zone Address": "Tsooni Aadress",
  "Zone Code": "Tsooni kood",
  "Sign in to your account": "Logi sisse oma kasutajasse",
  "Your email": "Sinu Email",
  "Remember me": "Mäleta mind",
  "Forgot password?": "Unustasid parooli?",
  "Sign in": "Logi sisse",
  "Powered By": "Powered By",
  "Invalid email address": "Vigane emaili aadress",
  "Email is required": "Email on kohustuslik",
  "Password is required":"Parool on kohustuslik",
  "Please enter both email and password.":"Palun sisesta email ja parool",
  "Invalid credentials. Please check your email or password.":"Vigane sisestus. Kontrollige oma eposti või parooli",
  "Wrong email. Please check your email address.":"Vale Eamil. Palun kontrolli oma emaili aadressi.",
  "Wrong password. Please check your password.": "Vale parool. Palun kontrolli oma parooli",
  "An error occurred during login. Please try again.": "Sisselogimisel ilmnes viga. Palun proovige uuesti.",
  "Netto": "Neto",
  "No notifications configured": "Teated on seadistamata",
  "Send price threshold alerts": "Saada teateid hinnaläve ületamise kohta",
  "if the price exceeds": "juhul kui hind ületab",
  "Discount Expires At": "Soodustus kehtib kuni",
  "Discount Amount": "Soodushind",
  "Full Amount": "Täishind",
  "Parking Sessions": "Parkimised",
  "Date from": "Kuupäevast",
  "Date to": "Kuupäevani",
  "Belongs to operator": "Kuulub operaatorile",
  "City zones": "Linnatsoonid",
  "Generated at": "Genereeritud",
  "Sessions merged": "Parkimised ühendatud",
  "Report Download": "Raporti allalaadimine",
  "Generate new Report": "Genereeri uus raport",
  "Operator": "Operaator",
  "Reports": "Raportid",
  "Generate Report": "Genereeri Raport",
  "Select city zones": "Vali linnatsoonid",
  "Select Date From": "Vali kuupäevast",
  "Select Date To": "Vali kuupäevani",
  "Contact Mobile": "Kontakt telefon",
  "ID Code": "Id kood",
  "Optional ID code of fine receiver": "Trahvi saaja isikukood (vabatahtlik)",
  "If available please fill in the Estonian ID code": "Kui võimalik, palun täitke Eesti isikukood.",
  "Belongs to": "Kuulub",
  "Parking Operators": "Parkimise Operaatorid",
  "Fine Printout": "Väljastatud trahv",
  "Download": "Lae alla",
  "Report generation successful.": "Raporti genereerimine õnnestus.",
  "Report generation failed. Please try again later.": "Raporti genereerimine ebaõnnestus. Palun proovige hiljem uuesti."
}
