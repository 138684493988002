import MultiSelect from "../formElements/MultiSelect";
import Select from "../formElements/Select";
import {useRoute } from "wouter";

const TableFilter = ({filters, onFilterChange}) => {

  if(!onFilterChange) {
    onFilterChange = (key) => {
      return () => {}
    }
  }

  return <div className="flex flex-row justify-between border-none min-h-[4.3rem] items-center gap-4 px-4 py-2">
    {Object.entries(filters).map(([key, f],i) => {
      if(Object.keys(f.options).length === 0) {
        return null;
      }

      const value = f.value ? f.value : []
      const isMultiselect = Array.isArray(value);

      return <div className="w-full" key={i}>
        <label className="font-light text-gray-700 text-sm">{f.title}</label>

        {isMultiselect ? (<MultiSelect id={key} value={value} options={f.options}  onChange={onFilterChange(key)} />) :
          <Select id={key} value={value} options={f.options}  onChange={onFilterChange(key)} />
        }
      </div>

    })}
  </div>
}

export default TableFilter
