{
  "Page": "Page",
  "show": "show",
  "Edit": "Edit",
  "Back": "Back",
  "cancel": "cancel",
  "or": "or",
  "Save": "Save",
  "Fines": "Fines",
  "Vehicles": "Vehicles",
  "Number Plate": "Number Plate",
  "Fleet": "Fleet",
  "Total Fines": "Total Fines",
  "Unpaid Fines": "Unpaid Fines",
  "Amount due": "Amount due",
  "Amount paid": "Amount paid",
  "Fine Number": "Fine Number",
  "Issued At": "Issued At",
  "Location": "Location",
  "Fine Amount": "Fine Amount",
  "Paid Amount": "Paid Amount",
  "Users": "Users",
  "New User": "New User",
  "New Vehicle": "New Vehicle",
  "Status": "Status",
  "Name": "Name",
  "Role": "Role",
  "Description": "Description",
  "Contact/Login Email": "Contact/Login Email",
  "Password": "Password",
  "Contact Phone": "Contact Phone",
  "Fleets": "Fleets",
  "Login Email": "Login Email",
  "Last Sign In": "Last Sign In",
  "Sign In IP": "Sign In IP",
  "Send daily notifications": "Send daily notifications",
  "to email(s)": "to email(s)",
  "at times": "at times",
  "Send immediate notifications": "Send immediate notifications",
  "Due At": "Due At",
  "Reason": "Reason",
  "Details": "Details",
  "Amount Due": "Amount Due",
  "Amount Paid": "Amount Paid",
  "Parking Area": "Parking Area",
  "Images": "Images",
  "Quick search...": "Quick search...",
  "Belongs to fleet": "Belongs to fleet",
  "Delete": "Delete",
  "Delete?": "Delete?",
  "This action cannot be undone.": "This action cannot be undone.",
  "Sent to Debt Collection": "Sent to Debt Collection",
  "Receiver Info": "Fine Receiver Info",
  "Name of fine receiver": "Name of fine receiver",
  "Please fill in the full name of the fine receiver": "Please fill in the full name of the fine receiver",
  "Email of fine receiver": "Email of fine receiver",
  "We will send a copy of the fine to this email address": "We will send a copy of the fine to this email address",
  "Contact mobile of fine receiver": "Contact mobile of fine receiver",
  "Alternatively payment reminders are sent to this number": "Alternatively payment reminders are sent to this number",
  "The fine will be sent to the customer after 30 minutes after pressing the button. When the fine has been sent the receiver details cannot be changed.": "The fine will be sent to the customer after 30 minutes after pressing the button. When the fine has been sent the receiver details cannot be changed.",
  "Save and Send the Fine": "Save and Send the Fine",
  "Fine Receicer Entered": "Fine Receicer Entered",
  "No": "No",
  "Payment Overdue": "Payment Overdue",
  "Fine is paid. Thank you!": "Fine is paid. Thank you!",
  "The fine is closed.": "The fine is closed.",
  "The fine has been revoked.": "The fine has been revoked.",
  "Payment due soon": "Payment due soon",
  "No receiver events yet": "No receiver events yet",
  "Event": "Event",
  "Date": "Date",
  "By": "By",
  "Active": "Active",
  "Inactive": "Inactive",
  "Deleted": "Deleted",
  "Zones": "Zones",
  "Zone": "Zone",
  "Vehicles Parked": "Vehicles Parked",
  "Plate N": "Plate N",
  "Current Price": "Current Price",
  "Show Parking History": "Show Parking History",
  "Vehicle Number / Zone": "Vehicle Number / Zone",
  "Parking History": "Parking History",
  "Parking Started": "Parking Started",
  "Parking Ended": "Parking Ended",
  "Total Parking": "Total Parking",
  "Price": "Price",
  "Time Period": "Time Period",
  "Parking Operator": "Parking Operator",
  "Map": "Map",
  "No matching vehicles found": "No matching vehicles found",
  "Recent": "Recent",
  "No matching zones found": "No matching zones found",
  "Total": "Total",
  "Client": "Client",
  "minute": "m",
  "hour": "h",
  "and": "",
  "Parked Time": "Parked Time",
  "minutes": "m",
  "hours": "h",
  "ago": "",
  "Fine Notice Sent": "Fine Notice Sent",
  "Yes": "Yes",
  "Comment": "Comment",
  "Parking Fee": "Parking Fee",
  "Choose a row to the left" : "Choose a row to the left",
  "days": "d",
  "day": "d",
  "Zone Address": "Zone Address",
  "Zone Code": "Zone Code",
  "Sign in to your account": "Sign in to your account",
  "Your email": "Your email",
  "Remember me": "Remember me",
  "Forgot password?": "Forgot password?",
  "Sign in": "Sign in",
  "Powered By": "Powered By",
  "Invalid email address": "Invalid email address",
  "Email is required": "Email is required",
  "Password is required":"Password is required",
  "Please enter both email and password.":"Please enter both email and password.",
  "Invalid credentials. Please check your email or password.":"Invalid credentials. Please check your email or password.",
  "Wrong email. Please check your email address.":"Wrong email. Please check your email address.",
  "Wrong password. Please check your password.": "Wrong password. Please check your password.",
  "An error occurred during login. Please try again.": "An error occurred during login. Please try again.",
  "Netto": "Netto",
  "No notifications configured": "No notifications configured",
  "Send price threshold alerts": "Send price threshold alerts",
  "if the price exceeds": "if the price exceeds",
  "Discount Expires At": "Discount Expires At",
  "Discount Amount": "Discount Amount",
  "Full Amount": "Full Amount",
  "Parking Sessions": "Parking Sessions",
  "Date from": "Date from",
  "Date to": "Date to",
  "Belongs to operator": "Belongs to operator",
  "City zones": "City zones",
  "Generated at": "Generated at",
  "Sessions merged": "Sessions merged",
  "Report Download": "Report Download",
  "Generate new Report": "Generate new Report",
  "Operator": "Operator",
  "Reports": "Reports",
  "Generate Report": "Generate Report",
  "Select city zones": "Select city zones",
  "Select Date From": "Select Date From",
  "Select Date To": "Select Date To",
  "Contact Mobile": "Contact Mobile",
  "ID Code": "ID Code",
  "Optional ID code of fine receiver": "Optional ID code of fine receiver",
  "If available please fill in the Estonian ID code": "If available please fill in the Estonian ID code",
  "Belongs to": "Belongs to",
  "Parking Operators": "Parking Operators",
  "Fine Printout": "Fine Printout",
  "Download": "Download",
  "Report generation successful.": "Report generation successful.",
  "Report generation failed. Please try again later.": "Report generation failed. Please try again later."
}
