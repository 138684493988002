import React from 'react';
import { useTranslation } from 'react-i18next';

export default function T({ k, children }) {
  const { t } = useTranslation('fleet-management-admin', { useSuspense: false });

  if (k == null) {
    return <>{t([children])}</>
  }

  return <>{t([k, children])}</>
}

export function useT() {
  return useTranslation('fleet-management-admin', { useSuspense: false });
}
