import { BackspaceIcon } from '@heroicons/react/24/solid'
import {useT} from "../languages/t";

export default function SearchInput({query, handleSearch, handleSearchEnter, clearSearch, className}) {

  const { t } = useT();

  if(!className) {
    className = 'bg-[#F9FAFB] rounded-md overflow-hidden border border-[#D1D5DB] ';
  }

  return <div className={className} >
    <div>
      <div className="group relative">
        <input type="text"
               className="block w-full border-transparent bg-transparent px-10 transition-all focus:border-primary-200 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 placeholder:italic placeholder:text-slate-300 "
               value={query}
               onChange={handleSearch}
               onKeyPress={handleSearchEnter}
               placeholder={t("Quick search...")}/>
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2.5 text-gray-500">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
            <path fillRule="evenodd"
                  d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                  clipRule="evenodd"/>
          </svg>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center px-2.5">
          <button type="button" onClick={clearSearch}><BackspaceIcon className={`h-5 w-5 ${query ? 'text-blue-400' : 'text-gray-200'}`} /></button>
        </div>
        {/*
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2.5">
          <span
            className="rounded border px-1.5 text-sm text-gray-400 shadow-sm transition-all group-hover:border-primary-500 group-hover:text-primary-500"><kbd>⌘</kbd> <kbd>K</kbd></span>
        </div>
         */}
      </div>
    </div>
  </div>

}
