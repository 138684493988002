import {forwardRef, memo,  useRef, useState} from 'react'
//import { useMask } from '@react-input/mask';

const defaultCompleteKeys = ['Enter', 'Tab', ',', ' '];

const TagInput = memo(forwardRef(function TagInput({id, value, onChange, placeholder, pattern, mask, completeKeys = defaultCompleteKeys}, ref) {

  const [isValid, setValid] = useState(null)

  const inputRef = useRef(null);
  //const inputRef = useMask({ mask: '_____@____.___', showMask: true, replacement: "_" });
  //if (/.+@.+\.[A-Za-z]+$/.test(email)) {
  //if (e.target.value === "" || re.test(e.target.value)) {

  if(!value) {
    value = []
  }

  if(!onChange || {}.toString.call(onChange) !== '[object Function]') {
    onChange = () => {}
  }


  const onKeyDown = (event) => {
    const { key } = event;

    if (completeKeys.includes(key)) {
      let { value: newTagValue } = inputRef.current;
      newTagValue = newTagValue.trim();

      event.preventDefault();

      if (!newTagValue.length) {
        return;
      }

      if (value.includes(newTagValue)) {
        return;
      }

      inputRef.current.value = '';
      onChange([...value, newTagValue]);
    }

    if (key === 'Backspace') {
      const { value: newTagValue } = inputRef.current;

      if (!newTagValue.trim().length) {
        onChange(value.slice(0, value.length - 1));
      }
    }
  };

  function removeTag(removedTag) {
    onChange(value.filter((tag) => tag !== removedTag));
  }

  function focusOnInput() {
    inputRef.current.focus();
  }

  //console.log('TagInput', id, value, onChange, placeholder)

  return (
          <div onClick={focusOnInput} className="row whitespace-nowrap relative block flex w-full min-h-[40px] rounded border border-gray-300 px-2 text-left shadow-sm focus-within:border-primary-300 focus-within:ring focus-within:ring-primary-200 focus-within:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50">
            {value.length > 0 && (
              <div className="inline-block space-x-2">
                {value.map((v, i) => (
                  <span
                    key={i}
                    className="inline-flex items-center gap-1 rounded bg-gray-100 px-2 py-1 my-2 text-xs font-semibold text-green-600">
                  {v}
                  <button onClick={() => removeTag(v)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-3 w-3 opacity-80">
                      <path
                        d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
                    </svg>
                  </button>
                </span>
                ))}
              </div>
            )}
            <input
              ref={inputRef}
              className="inline-block flex-1 border-none focus:ring-0 outline-none py-2 px-3 text-sm leading-5 text-gray-900 placeholder:italic placeholder:text-slate-400 placeholder:text-sm invalid:text-red-500 valid:text-green-500"
              onKeyDown={onKeyDown}
              onBlur={(ev) => { ev.target.value = ''}}
              placeholder={placeholder}
              pattern={pattern}
            />
          </div>

  )
}), (oldProps, newProps) => {
  //console.log(oldProps.id, 'oldProps.id === newProps.id', oldProps.id === newProps.id, 'oldProps.value === newProps.value', oldProps.value === newProps.value)
  return (oldProps.id === newProps.id && JSON.stringify(oldProps.value) === JSON.stringify(newProps.value))
})

export default TagInput;
