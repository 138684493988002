import {tFixed} from "../../i18n";

export default function UserStatusBadge(status) {

  let color = "bg-orange-50 text-orange-500"
  let status_txt = "Inactive"
  if (status === 'ACTIVE') {
    color = "bg-green-50 text-green-500"
    status_txt = "Active"
  } else if (status === 'DELETED') {
    color = "bg-red-50 text-red-500"
    status_txt = "Deleted"
  }


  return <span
    className={`text-center justify-center whitespace-nowrap inline-flex items-center gap-1 rounded px-3 py-2 text-xs font-semibold ${color}`}>
            {tFixed(status_txt)}
          </span>


}
