import Map from "./Map";
import React, { useState, useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { AppStore } from "../../store/store";

import "maplibre-gl/dist/maplibre-gl.css";
import VehicleSpinner from "../../components/spinner/VehicleSpinner";

export function Maps() {
  const center = [24.745, 59.433];
  const zoom = 5;

  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [zones, setZones] = useState([]);
  const appState = useRecoilValue(AppStore);
  const { FM_API_URL } = process.env;
  const audioElement = useRef(null);

  useEffect(() => {
    const fetchAreaZone = async () => {
      try {
        setLoading(true);

        const res = await fetch(`${FM_API_URL}/parkings/liveByZone`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + appState.token,
          },
        });

        if (!res.ok) {
          throw new Error(`Error: ${res.status} - ${res.statusText}`);
        }

        const resp = await res.json();
        console.log(resp);
        // Assuming resp.data is an array, adjust this part accordingly
        const zones = resp.data.map((zone) => ({
          id: zone.id,
          aadress: zone.address,
          name: zone.name,
          coordinates: zone.geom.coordinates[0][0],
          sessions: zone.sessions,
          parkedVehiclePlate: zone.sessions.map((session) => session.subject),
        }));

        setZones(zones);
      } catch (error) {
        console.error("Error fetching vehicles:", error.message);
        // Handle errors as needed
      } finally {
        setLoading(false);
      }
    };

    const fetchVehicles = async () => {
      try {
        setLoading(true);

        const res = await fetch(`${FM_API_URL}/parkings/live`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + appState.token,
          },
        });

        if (!res.ok) {
          throw new Error(`Error: ${res.status} - ${res.statusText}`);
        }

        const resp = await res.json();

        // Assuming resp.data is an array, adjust this part accordingly
        const vehicles = resp.data.map((vehicle) => {
          // (console.log(vehicle.subject_location.coordinates[0]," datis dif"))
          if (
            vehicle.subject_location &&
            vehicle.subject_location.coordinates &&
            vehicle.subject_location.coordinates[0] !== null
          ) {
            return {
              id: vehicle.session_id,
              clientId: vehicle.client_id,
              subject: vehicle.subject,
              coordinates: vehicle.subject_location.coordinates[0],
              location: vehicle.locality_name,
              started_at: vehicle.session_started_at,
              location_code: vehicle.locality_code,
              vat: vehicle.vat_percent,
              net_price: vehicle.price_net,
              calc_time: vehicle.price_calculation_time,
            };
          } else {
            // If subject_location is null, find the corresponding zone and use its coordinates
            const zoneId = vehicle.locality_id;
            const zone = zones.find((zone) => zone.id === zoneId);
            console.log(zone);

            return {
              id: vehicle.session_id,
              clientId: vehicle.client_id,
              subject: vehicle.subject,
              coordinates: zone && zone.coordinates[0], // Default coordinates if zone not found
              location: vehicle.locality_name,
              started_at: vehicle.session_started_at,
              location_code: vehicle.locality_code,
              vat: vehicle.vat_percent,
              net_price: vehicle.price_net,
              calc_time: vehicle.price_calculation_time,
            };
          }
        });

        setVehicles(vehicles);
      } catch (error) {
        console.error("Error fetching vehicles:", error.message);
        // Handle errors as needed
      } finally {
        setLoading(false);
      }
    };

    fetchAreaZone();
    fetchVehicles();

    const intervalId = setInterval(() => {
      fetchAreaZone();
      fetchVehicles();
    }, 120000);

    return () => clearInterval(intervalId);
  }, [FM_API_URL, appState.token]);

  /// center coordinates
  const geojson = {
    type: "FeatureCollection",
    features: [
      {
        geometry: {
          type: "Point",
          coordinates: [24.745, 59.433],
        },
      },
    ],
  };
  const carStartingSound = require("url:../../img/car-starting.mp3");

  useEffect(() => {
    if (!loading && audioElement.current) {
      // Ensure that the sound plays only once when the component is loaded
      audioElement.current.play().catch((error) => {
        console.error("Error playing audio:", error.message);
      });
    }
  }, [loading]);

  return (
    <div
      className="flex w-full items-center justify-center relative"
      style={{
        maxHeight: "calc(100% - 100px)",
        minHeight: "calc(100% - 100px)",
      }}
    >
      {loading ? (
        <div>
          <audio ref={audioElement} src={carStartingSound} autoPlay />
          <VehicleSpinner />
        </div>
      ) : (
        <Map
          center={center}
          zoom={zoom}
          geojson={geojson}
          vehicles={vehicles}
          zones={zones}
          // geoAreajson={geoAreajson}
        />
      )}
    </div>
  );
}

export default Maps;
