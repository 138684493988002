import * as React from 'react';
import {ListResourceQueryResult} from "../../store/store";
import {atomFamily, useRecoilState, useRecoilValue, selectorFamily } from "recoil";

const filterParamsStore = atomFamily({
  key: 'filterParamsStore',
  default: {},
});

const filteredState = selectorFamily({
  key: 'filteredState',
  get: ({filterOpts, resourcePath}) => ({get}) => {

    const filterValues = get(filterParamsStore(resourcePath));

    const newFilterOpts = {}
    for(const [k, f] of Object.entries(filterOpts)) {
      newFilterOpts[k] = Object.assign({}, f, { value: ((filterValues[k] && filterValues[k].length) ? filterValues[k] : []) })
    }
    return newFilterOpts;
  }
});

const debounceDelay = 500;

// Debounce utility function
function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}

export function useFilter(resourcePath, listQueryHandler, filterOpts) {

  const list = useRecoilValue(ListResourceQueryResult(resourcePath))
  const [filterValues, setFilterValues] = useRecoilState(filterParamsStore(resourcePath));
  const filters = useRecoilValue(filteredState({filterOpts, resourcePath}));

  const debouncedFunction = React.useRef();
  React.useEffect(() => {
    debouncedFunction.current = debounce((qry) => {
      listQueryHandler(qry);
    }, debounceDelay);
  }, [resourcePath]);


  const handleFilterChange = (key) => {
    return (v) => {
      let newFilters = Object.assign({}, filterValues, { [key]: v })

      // Remove filters with array length == 0
      for(const k of Object.keys(newFilters)) {
        if(newFilters[k].length === 0) {
          delete newFilters[k];
        }
      }

      setFilterValues(newFilters)

      const p = { page: 0, pageSize: list.pageSize, sortBy: list.sortBy, sortDesc: list.sortDesc, search: list.search, filters: newFilters }
      debouncedFunction.current(p);

      //
    }
  }

  return { filters, handleFilterChange }
}
