import {useEffect} from "react";
import {atom, useRecoilState, useRecoilValue} from "recoil";
import {AppStore} from "./store/store";

const { FM_API_URL } = process.env;

const FleetsFilterListState = atom({
  key: "FleetsFilterListState",
  default: [],
})

export function useFleets() {

  const [fleets, setFleets] = useRecoilState(FleetsFilterListState)
  const appState = useRecoilValue(AppStore)

  useEffect(() => {
    (async () => {
      const res = await fetch(`${FM_API_URL}/fleets/all`, {

        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + appState.token
        }
      })
      const resp = await res.json()
      setFleets(resp.data)
    })();

  }, []);

  return fleets;

}
