{
  "pleaseWaitSuspense": "Odota",
  "dashboardMenuItem": "Tila",
  "logoutMenuItem": "Kirjautua ulos",
  "codesMenuItem": "Pääsykoodit",
  "logMenuItem": "Lokikirja",
  "usersMenuItem": "Käyttäjä",
  "errorOpeningLock": "Lukkoa ei voi avata!",
  "successOpeningLock": "Lukko auki!",
  "dashboardHeading": "Lukituksen tila",
  "codesHeading": "Pääsykoodit",
  "addNewCodeBtnTxt": "Lisää uusi koodi",
  "statusColumn": "Tila",
  "typeColumn": "Tyyppi",
  "codeColumn": "Koodi",
  "commentColumn": "Kommentti",
  "zoneColumn": "Alue",
  "lockColumn": "Lukko",
  "locksColumn": "Lukko",
  "logsHeading": "Lokikirja",
  "resultColumn": "Tulos",
  "timeColumn": "Aika",
  "usersHeading": "Käyttäjä",
  "addNewUserBtnTxt": "Lisää uusi käyttäjä",
  "nameColumn": "Nimi",
  "emailColumn": "Sähköposti",
  "providerNameColumn": "Kuuluu ryhmään",
  "mayManageColumn": "Voi hallita ryhmiä",
  "searchPlaceholder": "Hae...",
  "lockLastSeenAt": "Viimeksi nähty",
  "queryDoneAt": "Tarkistettu",
  "stopStatusQuery": "Lopettaa tarkistus",
  "startStatusQuery": "Aloita tarkistus",
  "openLockBtnTxt": "Avaa lukko",
  "dd.MM.yyyy 'kell' kk:mm": "dd.MM.yyyy 'kello' kk:mm",
  "dd.MM.yyyy 'kell' kk:mm:ss": "dd.MM.yyyy 'kello' kk:mm:ss",
  "ONLINE_ERROR": "Offline",
  "ONLINE_WARNING": "Online",
  "ONLINE_SUCCESS": "Online",
  "UNKNOWN_LOCK_STATE": "--",
  "CLOSED_LOCK_STATE": "Suljettu",
  "OPEN_LOCK_STATE": "Avata",
  "tableFooterShowRows": "Näytä",
  "tableFooterPage": "Sivu",
  "ENABLED": "Aktivoitu",
  "SINGLE_USE": "Kertakäyttöinen",
  "DISABLED": "Poistettu käytöstä",
  "MSISDN": "Matkapuhelin",
  "PIN" : "PIN",
  "RFID": "RFID",
  "ACCEPT": "HYVÄKSYTTY",
  "DENY": "KIELLETTY",
  "ERROR": "VIRHE",
  "btnDeleteTxt": "Poistaa",
  "orTxt": "tai",
  "closeLinkTxt": "kiinni",
  "deleteConfirmationTxt": "Haluatko varmasti poistaa?",
  "resetLinkTxt": "nollaa",
  "saveBtnTxt": "Tallentaa",
  "idField": "ID",
  "statusField": "Tila",
  "typeField": "Tyyppi",
  "codeField": "Koodi",
  "commentField": "Kommentti",
  "zoneField": "Alue",
  "lockField": "Lukko",
  "locksField": "Lukko",
  "resultField": "Tulos",
  "timeField": "Aika",
  "nameField": "Nimi",
  "emailField": "Sähköposti",
  "providerNameField": "Kuuluu ryhmään",
  "mayManageField": "Voi hallita ryhmiä",
  "validFromField": "Voimassa alkaen",
  "validToField": "Voimassa asti",
  "updatedAtField": "Päivitetty klo",
  "createdAtField": "Luotu klo",
  "dataField": "Tehtävät",
  "passwordField": "Salasana",
  "zonePlaceholderTxt": "Valitse alue, jolla koodi on voimassa",
  "codePlaceholderTxt": "Syötä koodi alla olevien ohjeiden mukaan",
  "providerPlaceholderTxt": "Valitse ryhmä, johon koodi kuuluu",
  "validToPlaceholderTxt": "Jätä kenttä tyhjäksi, jos haluat koodin olevan voimassa ikuisesti",
  "commentPlaceholderTxt": "Lisää kuvaus tähän, jotta tiedät myöhemmin, mikä koodi se on",
  "providerHelpTxt": "Ryhmä päättää kuka omistaa koodin ja tämä puolestaan​määrittää, kenellä on oikeus muuttaa koodia",
  "providerNamePlaceholderTxt": "Valitse ryhmä",
  "emailPlaceholderTxt": "Sähköpostiosoitetta käytetään kirjautumiseen",
  "namePlaceholderTxt": "Kirjoita käyttäjän nimi",
  "passwordPlaceholderTxt": "Vaihda salasana täyttämällä tekstikenttä",
  "mayManagePlaceholderTxt": "Valitse ryhmät, joita käyttäjä voi hallita",
  "typeHelpTxt": "Tyyppi määrittää kuinka lukko voidaan avata:<1><2> <strong> Matkapuhelin </strong> - Voit avata lukon soittamalla ohjaimen matkapuhelinnumeroon tai syöttämällä matkapuhelinnumerosi neljä viimeistä numeroa näppäimistöllä </2> <2> <strong> PIN </strong> - Asiakas voi avata lukituksen vain PIN-koodilla </2> <2> <strong> RFID </strong> - Asiakas voi avata lukituksen vain RFID-nauhalla/kortilla </2></1>",
  "statusHelpTxt": "Koodia voidaan käyttää vain, kun tila on <1>AKTIVOITU</1>",
  "userStatusHelpTxt": "Käyttäjä voi kirjautua sisään vain, kun tila on <1>AKTIVOITU</1>",
  "locksHelpTxt": "Vasta alueen valinnan jälkeen voit valita, mitkä lukot koodin tulee avata",
  "codeHelpTxt": "Jos yllä oleva tyyppi on: <1> <0> <strong> Matkapuhelin </strong> - Koodin tulee siis olla matkapuhelinnumero, jossa on maatunnus, esim. +358501234567 </0> <1> <strong> PIN </strong> - nelinumeroinen numero </1> <2> <strong> RFID </strong> - kahdeksannumeroinen koodi, esim. E27694A2 </2> </1>",
  "lastSignInField": "Viimeksi kirjautunut sisään klo",
  "lastSignInIPField": "Viimeinen IP",
  "providerNameHelpTxt": "Ryhmä määrittää, kenelle käyttäjä kuuluu, ja tämä puolestaan​määrittää, kenellä on oikeus muuttaa käyttäjän tietoja"

}
