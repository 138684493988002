import { Fragment, forwardRef, memo } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

export default function MultiSelect({id, value, onChange, placeholder, options, className, required, isInvalid = false, isTouched= false}) {

  // Case where value is bigger than options
  for(const i of (value || [])) {
    if(options[i]) {
      continue;
    }
    options[i] = i;
  }


  if(!className) {
    className = "relative block w-full min-h-[40px] rounded border border-gray-300 pl-3 pr-10 text-left shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50"
  }

  const extraCls = isInvalid ? ' border-red-300 focus:border-red-300 focus:ring-red-200 focus:ring-opacity-50 ': (isTouched && ' border-green-400 focus:border-green-400 focus:ring-green-200 focus:ring-opacity-50 ');


  return (
      <Listbox key={id} value={value} onChange={onChange} multiple>
        <div className="relative">
          <Listbox.Button className={`${className} ${extraCls}`}>
            <span className="block truncate space-x-2"> {(value || []).reduce((r, v) => {

              const t = options[v] ? options[v] : v;

              r.push(
                <span key={v} className="inline-flex items-center gap-1 rounded bg-gray-100 px-2 py-1 my-2 text-xs font-semibold text-gray-600">{t}</span>
              ); return r }, [])} </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="z-20 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {Object.entries(options).map(([k, v], i) => (
                <Listbox.Option
                  key={k}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={k}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {v}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
  )
}
