import React, { useEffect, useState } from "react";
import differenceInMinutes from "date-fns/differenceInMinutes";
import parseISO from "date-fns/parseISO";
import { useT } from "../../components/languages/t";
import Citybee from "../../img/citybee.png";

const VehiclePopupContent = ({ vehicle }) => {
  const [updatedTime, setUpdatedTime] = useState(new Date());

  const { t } = useT();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setUpdatedTime(new Date());
    }, 30000); // 30 seconds

    // Cleanup the timeout on component unmount or when the dependency changes
    return () => clearTimeout(timeoutId);
  }, [updatedTime]);

  const displayTimeDifference = (startedAt, updatedTime) => {
    const differenceInMinutesValue = differenceInMinutes(
      updatedTime,
      parseISO(startedAt)
    );

    if (differenceInMinutesValue >= 60) {
      const days = Math.floor(differenceInMinutesValue / (24 * 60));
      const hours = Math.floor((differenceInMinutesValue % (24 * 60)) / 60);
      const remainingMinutes = differenceInMinutesValue % 60;

      if (remainingMinutes === 0) {
        if (days > 0) {
          return `${days} ${days === 1 ? t("day") : t("days")} ${hours} ${
            hours === 1 ? t("hour") : t("hours")
          } ${t("ago")}`;
        } else {
          return `${hours} ${hours === 1 ? t("hour") : t("hours")} ${t("ago")}`;
        }
      } else {
        if (days > 0) {
          return `${days} ${days === 1 ? t("day") : t("days")} ${hours} ${
            hours === 1 ? t("hour") : t("hours")
          } ${t("and")} ${remainingMinutes} ${
            remainingMinutes === 1 ? t("minute") : t("minutes")
          } ${t("ago")}`;
        } else {
          return `${hours} ${hours === 1 ? t("hour") : t("hours")} ${t(
            "and"
          )} ${remainingMinutes} ${
            remainingMinutes === 1 ? t("minute") : t("minutes")
          } ${t("ago")}`;
        }
      }
    } else {
      return `${differenceInMinutesValue} ${
        differenceInMinutesValue === 1 ? t("minute") : t("minutes")
      } ${t("ago")}`;
    }
  };

  return (
    <div>
      <div className="flex pr-4 pl-4 flex-row items-center bg-[#F9FAFB] rounded-t-lg">
        {vehicle.clientId === "ee.citybee" && (
          <img className="p-1 w-8 h-8" src={Citybee} alt="Citybee Icon" />
        )}
        <div className="font-semibold flex items-center justify-center text-lg gap-2 p-4 text-[#1b1c20] font-medium">
          {vehicle.subject}
        </div>
      </div>
      <div className="flex gap-2 px-3 py-4 text-base text-[#1b1c20] font-medium">
        <p className="text-[#3C3B3B] font-normal">{t("Zone")}</p>
        {vehicle.location}
      </div>
      <div className="flex gap-2 items-center px-3 py-4 border-t-2 padding-18 text-base text-[#3C3B3B] font-medium">
        <p className="text-[#1b1c20] font-normal">{t("Current Price")}</p>
        <div
          className={`w-max px-3 py-2 rounded ${
            vehicle.net_price !== null && vehicle.net_price > 15
              ? "price-over-15"
              : ""
          }`}
        >
          {vehicle.net_price !== null ? (
            <p>{vehicle.net_price} €</p>
          ) : (
            <p>0.00 €</p>
          )}
        </div>
      </div>
      <div className="flex gap-2 px-3 items-center py-4 border-t-2 padding-18 text-base text-[#3C3B3B] font-medium">
        <p className="text-[#1b1c20] font-normal"> {t("Parked Time")}</p>
        {displayTimeDifference(vehicle.started_at, updatedTime)}
      </div>
    </div>
  );
};

export default VehiclePopupContent;
