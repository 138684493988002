import {useMemo} from 'react';
import {useT} from "../../components/languages/t";
import {Table} from "../../components/table/table";
import {useList} from "../../useList";
import {useLocation, useRoute} from "wouter";
import UserStatusBadge from "./userStatusBadge";
import TableHeader from "../../components/table/TableHeader";
import { UserPlusIcon } from '@heroicons/react/24/outline'
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import SearchInput from "../../components/formElements/SearchInput";
import {useSearch} from "../../components/form/useSearch";
import VehicleSpinner from "../../components/spinner/VehicleSpinner";

const re = /(\d{3,4})(\d{4})(\d{4})(\d{4})/
const re2 = new RegExp(/([a-z])(?=[0-9])/ig);
const re3 = new RegExp(/([0-9])(?=[a-z])/ig);

const UserList = () => {

  const { t, i18n } = useT();

  const { list, handleListQuery} = useList('/users/list');
  const { query, handleSearch, handleSearchEnter, clearSearch } = useSearch('/users/list', handleListQuery)
  const [_, navigate] = useLocation();
  const [match, params] = useRoute("/users/:id");

  const cols = useMemo(() => {
    return [{
      header: t(["nameColumn", "Name"]),
      accessorKey: "name"
    },
      {
        header: t(["statusColumn", "Status"]),
        id: 'status',
        accessorFn: (r) => {
          return UserStatusBadge(r.status);
        }
      },
      {
        header: t(["statusColumn", "Login Email"]),
        accessorKey: "login_email"
      },
      {
        header: t(["lastSignInColumn", "Last Sign In"]),
        id: "last_sign_in_at",
        accessorFn: (r) => {
          if(!r.last_sign_in_at) {
            return '--';
          }
          return format(parseISO(r.last_sign_in_at), 'dd.MM.yyyy HH:mm');
        }
      }, {
        header: t(["signInIpColumn", "Sign In IP"]),
        accessorKey: "last_sign_in_ip"
      }
    ]
  }, [i18n.language])


  const onRowClick = (row) => {
    navigate(`/users/${row.id}`);
  }

  console.log('list', list);

  return <><TableHeader title={t("Users")}>
    <button onClick={() => {
      navigate(`/users/new`);
    }} type="button"
            className="h-11 inline-flex items-center rounded gap-0.5 px-2 py-2.5 text-center text-sm font-medium text-blue-500 transition-all hover:bg-gray-100 focus:ring focus:ring-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400">
      <UserPlusIcon className="h-5 w-5 text-blue-500 mr-2" />{t('New User')}
    </button>
  </TableHeader>
    <div className="flex-[0_0_auto] border-b px-4 py-4 border-gray-100">
      <SearchInput query={query} handleSearch={handleSearch} handleSearchEnter={handleSearchEnter}
                   clearSearch={clearSearch} />
    </div>
    <Table data={list.data}
          columns={cols}
          totalHits={list.totalHits}
          page={list.page}
          lastPage={list.totalHits >= 0 ? list.lastPage + 1: 0}
          pageSize={list.pageSize}
          sortBy={""}
          sortDesc={false}
          filters={list.filters}
          search={list.search}
          fetchData={(p) => {
            handleListQuery(p)
          }}
          onRowClick={onRowClick}
           selectedItemId={params && params.id}
           spinner={VehicleSpinner}
    /></>
}

export default UserList;
