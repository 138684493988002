import { useT } from "../../components/languages/t";
import { useEffect, useMemo, useState } from 'react';
import { useT } from "../../components/languages/t";
import { Table } from "../../components/table/table";
import { useList } from "../../useList";
import { useLocation, useRoute } from "wouter";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import TableHeader from "../../components/table/TableHeader";
import { useSearch } from "../../components/form/useSearch";
import SearchInput from "../../components/formElements/SearchInput";
import { useFilter } from "../../components/form/useFilter";
import TableFilter from "../../components/table/TableFilter";
import VehicleSpinner from "../../components/spinner/VehicleSpinner";
import { useFleets } from "../../useFleets";


const re = /(\d{3,4})(\d{4})(\d{4})(\d{4})/
const re2 = new RegExp(/([a-z])(?=[0-9])/ig);
const re3 = new RegExp(/([0-9])(?=[a-z])/ig);

const ReportsList = () => {
  const { t, i18n } = useT();

  // Get all fleets
  const fleetsNoperators = useFleets()
  const fleets = useMemo(() => { return Object.values(fleetsNoperators || {}).reduce((r, v) => {
    if(v.type === 'FLEET') {
      r[v.id] = v.name;
    }
    return r;
  }, {}); },[fleetsNoperators])

  const operators = useMemo(() => { return Object.values(fleetsNoperators || {}).reduce((r, v) => {
    if(v.type === 'OPERATOR') {
      r[v.id] = v.name;
    }
    return r;
  }, {}); },[fleetsNoperators])

  const filterOpts = {
    fleet_id: {
      title: t(["fleetColumn", "Fleet"]),
      options: fleets
    },
    operator_id: {
      title: t(["parkingOperatorColumn", "Parking Operator"]),
      options: operators
    }
  }

  const { list, handleListQuery } = useList('/reports/list');
  const { query, handleSearch, handleSearchEnter, clearSearch } = useSearch('/reports/list', handleListQuery)
  const { filters, handleFilterChange } = useFilter('/reports/list', handleListQuery, filterOpts)
  const [_, navigate] = useLocation();
  const [match, params] = useRoute("/reports/:id");



  const cols = useMemo(() => {
    return [
      {
        header: t(["fleetColumn", "Fleet"]),
        id: 'fleet_id',
        accessorFn: (response) => {
          return <span className="whitespace-nowrap tabular-nums">{response.fleet_id.replace(/\s/g, '').replace(re2, "$1 ").replace(re3, "$1 ")}</span>
        }

      },
      {
        header: t(["OperatorColumn", "Operator"]),
        id: 'operator_id',
        accessorFn: (response) => {
          return <span className="whitespace-nowrap tabular-nums">{response.operator_id.replace(/\s/g, '').replace(re2, "$1 ").replace(re3, "$1 ")}</span>
        }

      },
      {
        header: t(["dateColumn", "Date from"]),
        id: "date_from",
        accessorFn: (response) => {
          if (!response.date_from) {
            return '--';
          }
          return format(parseISO(response.date_from), 'dd.MM.yyyy HH:mm');
        }
      },
      {
        header: t(["dateColumn", "Date to"]),
        id: "date_to",
        accessorFn: (response) => {
          if (!response.date_to) {
            return '--';
          }
          return format(parseISO(response.date_to), 'dd.MM.yyyy HH:mm');
        }
      },
      {
        header: t(["generatedAtColumn", "Generated at"]),
        id: "generated_at",
        accessorFn: (response) => {
          if (!response.generated_at) {
            return '--';
          }
          return format(parseISO(response.generated_at), 'dd.MM.yyyy HH:mm');
        }
      },
    ]
  }, [i18n.language])


  const onRowClick = (row) => {
    navigate(`/reports/${row.id}`);
  }

  console.log('list', list, 'FILTERS', filters);


  return <><TableHeader title={t("Reports")} >
    <button
      onClick={() => {
        navigate(`/reports/generate`);
      }}
      type="button"
      className="h-11 inline-flex items-center rounded gap-0.5 px-2 py-2.5 text-center text-sm font-medium text-blue-500 transition-all hover:bg-gray-100 focus:ring focus:ring-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400"
    >
      <SquaresPlusIcon className="h-5 w-5 text-blue-500 mr-2" />
      {t("Generate new Report")}
    </button>
  </TableHeader>
    <div className="flex-[0_0_auto] border-b px-4 py-4 border-gray-100">
      <SearchInput query={query} handleSearch={handleSearch} handleSearchEnter={handleSearchEnter}
        clearSearch={clearSearch} />
    </div>
    <TableFilter filters={filters} onFilterChange={handleFilterChange} />
    <Table data={list.data}
      columns={cols}
      totalHits={list.totalHits}
      page={list.page}
      lastPage={list.totalHits >= 0 ? list.lastPage + 1 : 0}
      pageSize={list.pageSize}
      sortBy={""}
      sortDesc={false}
      filters={list.filters}
      search={list.search}
      fetchData={(p) => {
        return handleListQuery(p)
      }}
      onRowClick={onRowClick}
      selectedItemId={params && params.id}
      spinner={VehicleSpinner}
    />
  </>
}

export default ReportsList;
