import React, {useEffect} from 'react';
import {
  useRecoilState,
} from 'recoil';
import {AppStore} from "../../store/store";
import {useLocation} from "wouter";

const Logout = () => {

  const [_, navigate] = useLocation();
  const [appState, setAppState] = useRecoilState(AppStore)
  //const history = useHistory()

  useEffect(() => {
    setAppState({ ...appState, token: null })
    //history.replace({ pathname: '/' })
    navigate('/')
  })


  return null

}

export default Logout
