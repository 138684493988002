import React, { useRef, useEffect, useState } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import markerImage from "../../img/markerIdle.png";
import { createPortal, render } from "react-dom";
import { createRoot } from "react-dom/client";
import hoverMarker from "../../img/hoverMarker.png";
import zoneImage from "../../img/zoneIdle.png";
import yhisZoneImage from "../../img/yhisteenus.png";
import zoneHover from "../../img/zoneHover.png";
import Supercluster from "supercluster";
import extent from "turf-extent";
import { useRoute } from "wouter";
import centerOfMass from "@turf/center-of-mass";
import T from "../../components/languages/t";
import { useT } from "../../components/languages/t";
import UpArrow from "../../img/up.svg";
import ZonePopupContent from "./ZonePopupContent";
import VehiclePopupContent from "./VehiclePopupContent";
import DownArrow from "../../img/down.svg";
import History from "../../img/history.svg";
import Close from "../../img/close.svg";
//import "./map.less";
import SearchInput from "./SearchInput";
import ZoneContainer from "./tabs/ZoneContainer";
import FleetContainer from "./tabs/FleetContainer";
import ParkingHistory from "./ParkingHistory";
import {useLocation} from "wouter";

const style = "klokantech-basic";
const { MAPS_URL } = process.env;

function Link({ children, onClick, isActive, href }) {
  const linkStyle = {
    borderRight: "1px solid #D1D5DB",
  };

  return (
    <li className="w-full">
      <div
        className={`cursor-pointer font-semibold flex items-center justify-center text-lg gap-2 p-4 text-[#1b1c20] font-medium hover:text-[#e94f0a] border-b-2 ${
          isActive ? "border-[#e94f0a]" : "border-transparent"
        } hover:border-gray-200`}
        style={linkStyle}
        onClick={onClick}
      >
        {children}
      </div>
    </li>
  );
}

const Map = React.memo(
  ({
    center: centerInit,
    zoom: zoomInit,
    geojson,
    vehicles,
    zones,
    markerPopup,
  }) => {
    const [markers, setMarkers] = useState([]);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [filteredVehicles, setFilteredVehicles] = useState([]);
    const [filteredZones, setFilteredZones] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [isInputActive, setIsInputActive] = useState(false);
    const [searchHistory, setSearchHistory] = useState([]);
    const [match, setMatch] = useState(false);
    const [zoneMatch, setZoneMatch] = useState(false);
    const [lng] = useState(centerInit ? centerInit[0] : 24.745);
    const [lat] = useState(centerInit ? centerInit[1] : 59.433);
    const [zoom] = useState(6);
    const [isFleetContainerOpen, setFleetContainerOpen] = useState(true);
    const [isZoneContainerOpen, setZoneContainerOpen] = useState(false);
    const [isContainerOpen, setContainerOpen] = useState(true);
    const [showHistory, setShowHistory] = useState(false);
    let currentPopup = null;
    const [, params] = useRoute("/map/:tab?");
    const [activeTab, setActiveTab] = useState("vehicles");
    const [_, navigate] = useLocation();

    const { t } = useT();

    const handleDocumentClick = (e) => {
      // Check if the clicked element is outside the FleetContainer and ZoneContainer
      const isOutsideFleetContainer = !e.target.closest(".fleetContainer");
      const isOutsideZoneContainer = !e.target.closest("#zoneContainer");
      const isNotMapMarker = !e.target.classList.contains("maplibregl-marker");

      if (isOutsideFleetContainer && isOutsideZoneContainer && isNotMapMarker) {
        // Remove clickedPolygon layers
        removeClickedPolygon();
      }
    };
    useEffect(() => {
      if (map.current) {
        map.current.remove(); // Clear existing markers
      }

      if (!geojson && !centerInit) return;

      // creating a map
      const mp = new maplibregl.Map({
        container: mapContainer.current,
        style: `${MAPS_URL}/styles/${style}/style.json`,
        hash: false,
        center: [lng, lat],
        zoom: zoom,
        tileSize: 120,
      });
      mp.addControl(new maplibregl.NavigationControl());
      mp.addControl(new maplibregl.FullscreenControl());

      if (!centerInit) {
        centerInit = centerOfMass(geojson).geometry.coordinates;
      }
      // Center marker
      // new maplibregl.Marker().setLngLat(centerInit).addTo(mp);

      mp.on("load", () => {
        zones.forEach((zone, index) => {
          const coordinatesData = zone.coordinates;
          const sourceId = `zoneArea-${index}`;
          const layerId = `zoneArea-id-${index}`;

          // Check if the id starts with "ee.europark"
          if (zone.id && zone.id.startsWith("ee.europark")) {
            // Check if the source already exists
            if (!mp.getSource(sourceId)) {
              mp.addSource(sourceId, {
                type: "geojson",
                data: {
                  type: "Feature",
                  geometry: {
                    coordinates: [coordinatesData],
                    type: "Polygon",
                  },
                },
              });
            }

            // Check if the layer already exists
            if (!mp.getLayer(layerId)) {
              mp.addLayer({
                id: layerId,
                type: "fill",
                source: sourceId,
                paint: {
                  "fill-color": "#337FE1",
                  "fill-opacity": 0.3,
                },
              });
            }
          }
          if (zone.id && zone.id.startsWith("ee.yt")) {
            if (!mp.getSource(sourceId)) {
              mp.addSource(sourceId, {
                type: "geojson",
                data: {
                  type: "Feature",
                  geometry: {
                    coordinates: [coordinatesData],
                    type: "Polygon",
                  },
                },
              });
            }

            // Check if the layer already exists
            if (!mp.getLayer(layerId)) {
              mp.addLayer({
                id: layerId,
                type: "fill",
                source: sourceId,
                paint: {
                  "fill-color": "#EC6707",
                  "fill-opacity": 0.3,
                },
              });
            }
          }
        });
      });
      //// create the vehicle clustering
      if (isFleetContainerOpen && vehicles && vehicles.length > 0) {
        mp.on("load", () => {
          const supercluster = new Supercluster({});

          supercluster.load(vehicles);

          mp.addSource("clusters", {
            type: "geojson",
            buffer: 1,
            data: {
              type: "FeatureCollection",
              features: supercluster.points.map((vehicle) => ({
                type: "Feature",
                properties: {
                  id: vehicle.id,
                },
                geometry: {
                  type: "Point",
                  coordinates: [vehicle.coordinates[1], vehicle.coordinates[0]],
                },
              })),
            },
            cluster: true,
            clusterMaxZoom: 12, // Maximum zoom level for clustering
            clusterRadius: 40, // Cluster radius in pixels
          });

          mp.addLayer({
            id: "clusters",
            type: "circle",
            source: "clusters",
            filter: ["has", "point_count"],
            paint: {
              "circle-color": "#337FE1", // Cluster color
              "circle-radius": [
                "step",
                ["get", "point_count"],
                20,
                5,
                30,
                10,
                50,
              ],
              "circle-pitch-alignment": "viewport",
              "circle-opacity": 0.7,
            },
          });

          mp.addLayer({
            id: "unclustered-points",
            type: "circle",
            source: "clusters",
            filter: ["!", ["has", "point_count"]],
            paint: {
              "circle-color": "#337FE1", // Set color for unclustered points
              "circle-radius": 2,
            },
          });
          mp.addLayer({
            id: "cluster-count",
            type: "symbol",
            source: "clusters",
            filter: ["has", "point_count"],
            paint: {
              "text-color": "#ffffff", // Set text color
              "text-halo-color": "#000000", // Set text halo color
              "text-halo-width": 1, // Set text halo width
            },
            layout: {
              "text-field": "{point_count_abbreviated}",
              "text-font": ["Open Sans Semibold"],
              "text-size": 14,
              "text-rotation-alignment": "viewport",
              "text-ignore-placement": true,
              "symbol-avoid-edges": true,
            },
          });
        });
      }
      const markers = [];

      const updateMarkers = () => {
        // Vehicles and Zones markers
        markers.forEach((markerItem) => {
          markerItem.markerPopup.remove();
        });

        markers.length = 0;

        if (isFleetContainerOpen && vehicles && vehicles.length > 0) {
          vehicles.forEach((vehicle) => {
            const vehicleEl = document.createElement("div");
            vehicleEl.style.opacity = 0;
            vehicleEl.style.pointerEvents = "none";

            if (vehicle.clientId === "ee.citybee") {
              vehicleEl.classList.add("citybee-logo");
            }
            const debounce = (func, delay) => {
              let timeoutId;
              return (...args) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                  func(...args);
                }, delay);
              };
            };

            const zoomHandler = debounce(() => {
              const currentZoom = mp.getZoom();
              const renderedFeatures = mp.queryRenderedFeatures({
                layers: ["unclustered-points"],
                filter: ["==", "id", vehicle.id],
              });

              const isClustered = !renderedFeatures.length;

              // Check if the point is unclustered
              if (!isClustered || currentZoom > 14) {
                // Zoomed in and unclustered
                vehicleEl.style.opacity = 1;
                vehicleEl.style.pointerEvents = "all";
              } else {
                // Zoomed out, clustered, or not unclustered
                vehicleEl.style.opacity = 0;
                vehicleEl.style.pointerEvents = "none";
              }

              lastZoom = currentZoom;
            }, 100);

            mp.on("zoom", zoomHandler);

            vehicleEl.style.backgroundImage = `url(${markerImage})`;

            // Add event listener for hover effect
            vehicleEl.addEventListener("mouseover", () => {
              vehicleEl.style.backgroundImage = `url(${hoverMarker})`;
            });

            vehicleEl.addEventListener("mouseleave", () => {
              vehicleEl.style.backgroundImage = `url(${markerImage})`;
            });

            const markerPopup = new maplibregl.Popup({
              closeOnClick: true,
              className: "popupContainer",
              focusAfterOpen: true,
            });

            /// polygon creaton function
            const handleMarkerClick = (e, vehicle) => {
              e.preventDefault();

              if (map.current.getLayer("clickedPolygon")) {
                map.current.removeLayer("clickedPolygon");
                map.current.removeLayer("outline");
                map.current.removeSource("clickedPolygon");
              }

              // event listener remove
              const fleetContainer = document.getElementById("linkContainer");

              const closeLayersOnMouseOver = () => {
                removeClickedPolygon();
                if (markerPopup.isOpen()) {
                  markerPopup.remove();
                }
              };

              if (fleetContainer) {
                fleetContainer.addEventListener(
                  "mouseover",
                  closeLayersOnMouseOver
                );
              }

              mp.once("click", () => {
                if (mp.getLayer("clickedPolygon")) {
                  mp.removeLayer("clickedPolygon");
                  mp.removeLayer("outline");
                  mp.removeSource("clickedPolygon");
                }

                const matchingZone = zones.find(
                  (zone) => zone.name === vehicle.location
                );
                const zoneCoordinates = matchingZone.coordinates;

                if (matchingZone) {
                  if (matchingZone.id.startsWith("ee.yt")) {
                    mp.addSource("clickedPolygon", {
                      type: "geojson",
                      data: {
                        type: "Feature",
                        geometry: {
                          coordinates: [zoneCoordinates],
                          type: "Polygon",
                        },
                      },
                    });
                    mp.addLayer({
                      id: "clickedPolygon",
                      type: "fill",
                      source: "clickedPolygon",
                      layout: {},
                      paint: {
                        "fill-color": "#EC6707",
                        "fill-opacity": 0.2,
                      },
                    });
                    mp.addLayer({
                      id: "outline",
                      type: "line",
                      source: "clickedPolygon",
                      layout: {},
                      paint: {
                        "line-color": "#EC6707",
                        "line-width": 2,
                      },
                    });
                  }
                }
                if (matchingZone.id.startsWith("ee.europark")) {
                  mp.addSource("clickedPolygon", {
                    type: "geojson",
                    data: {
                      type: "Feature",
                      geometry: {
                        coordinates: [zoneCoordinates],
                        type: "Polygon",
                      },
                    },
                  });
                  mp.addLayer({
                    id: "clickedPolygon",
                    type: "fill",
                    source: "clickedPolygon",
                    layout: {},
                    paint: {
                      "fill-color": "#337FE1",
                      "fill-opacity": 0.2,
                    },
                  });
                  mp.addLayer({
                    id: "outline",
                    type: "line",
                    source: "clickedPolygon",
                    layout: {},
                    paint: {
                      "line-color": "#337FE1",
                      "line-width": 2,
                    },
                  });
                }
                mp.flyTo({
                  center: [vehicle.coordinates[1], vehicle.coordinates[0]],
                  offset: [300, 0],
                  bearing: 0,
                  zoom: 14,
                  essential: true,
                });
              });

              onMarkerClick(vehicle);
            };

            const fleetContainerProps = {
              vehicles,
              onMarkerClick: handleMarkerClick,
            };

            const vehiclePortalContainer = document.createElement("div");
            const root = createRoot(vehiclePortalContainer);
            markerPopup.setDOMContent(vehiclePortalContainer);

            mp.on("load", () => {
              new maplibregl.Marker({ element: vehicleEl })
                .setLngLat([vehicle.coordinates[1], vehicle.coordinates[0]])
                .setPopup(markerPopup)
                .addTo(mp)
                .getElement()
                .addEventListener("click", (e) => {
                  e.preventDefault();
                  handleMarkerClick(e, vehicle);
                });

              setMarkers((prevMarkers) => [
                ...prevMarkers,
                { vehicleId: vehicle.id, markerPopup },
              ]);
            });
            mp.on("click", () => {
              root.render(
                createPortal(
                  <VehiclePopupContent
                    onMarkerClick={onMarkerClick}
                    {...fleetContainerProps}
                    vehicle={vehicle}
                  />,
                  vehiclePortalContainer
                )
              );
            });
          });
        }

        // Zone marker function and logic and html
        if (isZoneContainerOpen && zones && zones.length > 0) {
          // Adding the cluster on load
          mp.on("load", () => {
            const supercluster = new Supercluster({});

            supercluster.load(zones);

            // Use the center point as the cluster point
            mp.addSource("zoneCluster", {
              type: "geojson",
              buffer: 1,
              data: {
                type: "FeatureCollection",
                features: supercluster.points.map((zone) => {
                  // Calculate the center point for each zone
                  const zoneCenter = centerOfMass({
                    type: "Feature",
                    properties: {},
                    geometry: {
                      type: "MultiPoint",
                      coordinates: zone.coordinates,
                    },
                  });

                  return {
                    type: "Feature",
                    properties: {
                      id: zone.id,
                    },
                    geometry: {
                      type: "Point",
                      coordinates: zoneCenter.geometry.coordinates,
                    },
                  };
                }),
              },
              cluster: true,
              clusterMaxZoom: 12, // Maximum zoom level for clustering
              clusterRadius: 40, // Cluster radius in pixels
            });

            mp.addLayer({
              id: "zoneCluster",
              type: "circle",
              source: "zoneCluster",
              filter: ["has", "point_count"],
              paint: {
                "circle-color": "#337FE1", // Cluster color
                "circle-radius": [
                  "step",
                  ["get", "point_count"],
                  20,
                  5,
                  30,
                  10,
                  50,
                ],
                "circle-pitch-alignment": "viewport",
                "circle-opacity": 0.7,
              },
            });

            mp.addLayer({
              id: "unclustered-points",
              type: "circle",
              source: "zoneCluster",
              filter: ["!", ["has", "point_count"]],
              paint: {
                "circle-color": "#337FE1", // Set color for unclustered points
                "circle-radius": 1,
              },
            });

            mp.addLayer({
              id: "cluster-count",
              type: "symbol",
              source: "zoneCluster",
              filter: ["has", "point_count"],
              paint: {
                "text-color": "#ffffff", // Set text color
                "text-halo-color": "#000000", // Set text halo color
                "text-halo-width": 1, // Set text halo width
              },
              layout: {
                "text-field": "{point_count_abbreviated}",
                "text-font": ["Open Sans Semibold"],
                "text-size": 14,
                "text-rotation-alignment": "viewport",
                "text-ignore-placement": true,
                "symbol-avoid-edges": true,
              },
            });
          });

          zones.forEach((zone) => {
            const zoneEl = document.createElement("div");
            zoneEl.style.opacity = 0;
            zoneEl.style.pointerEvents = "none";
            // clustering zoom logic
            const debounce = (func, delay) => {
              let timeoutId;
              return (...args) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                  func(...args);
                }, delay);
              };
            };

            const zoomHandler = debounce(() => {
              const currentZoom = mp.getZoom();
              const renderedFeatures = mp.queryRenderedFeatures({
                layers: ["unclustered-points"],
                filter: ["==", "id", zone.id],
              });

              const isClustered = !renderedFeatures.length;

              // Check if the point is unclustered
              if (!isClustered || currentZoom > 14) {
                // Zoomed in and unclustered
                zoneEl.style.opacity = 1;
                zoneEl.style.pointerEvents = "all";
              } else {
                // Zoomed out, clustered, or not unclustered
                zoneEl.style.opacity = 0;
                zoneEl.style.pointerEvents = "none";
              }

              lastZoom = currentZoom;
            }, 100);

            mp.on("zoom", zoomHandler);

            zoneEl.classList.add("parking-logo");

            if (zone.id && zone.id.startsWith("ee.europark")) {
              zoneEl.style.backgroundImage = `url(${zoneImage})`;
            } else if (zone.id && zone.id.startsWith("ee.yt")) {
              zoneEl.style.backgroundImage = `url(${yhisZoneImage})`;
            }
            // Centering marker
            const centerPoint = centerOfMass({
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: [zone.coordinates],
              },
            });

            const centerMarker = centerPoint.geometry.coordinates;

            // Add event listener for hover effect
            zoneEl.addEventListener("mouseover", () => {
              zoneEl.style.backgroundImage = `url(${zoneHover})`;
            });
            if (zone.id && zone.id.startsWith("ee.europark")) {
              zoneEl.addEventListener("mouseleave", () => {
                zoneEl.style.backgroundImage = `url(${zoneImage})`;
              });
            } else if (zone.id && zone.id.startsWith("ee.yt")) {
              zoneEl.addEventListener("mouseleave", () => {
                zoneEl.style.backgroundImage = `url(${yhisZoneImage})`;
              });
            }

            const markerPopup = new maplibregl.Popup({
              closeOnClick: true,
              className: "popupContainer",
              focusAfterOpen: true,
            });

            const zoneMarkerClick = (e, zone) => {
              e.preventDefault();

              if (mp.getLayer("clickedPolygon")) {
                mp.removeLayer("clickedPolygon");
                mp.removeLayer("outline");
                mp.removeSource("clickedPolygon");
              }
              // Event listener remove
              const zoneContainer = document.getElementById("linkContainer");

              const closeLayersOnMouseOver = () => {
                removeClickedPolygon();

                if (markerPopup.isOpen()) {
                  markerPopup.remove();
                }
              };

              if (zoneContainer) {
                zoneContainer.addEventListener(
                  "mouseover",
                  closeLayersOnMouseOver
                );
              }

              /// markerpopup close
              markerPopup.on("close", () => {
                const mapInstance = map.current;

                // Check if the layer and source exist
                if (
                  mapInstance.getLayer("outline") &&
                  mapInstance.getSource("clickedPolygon")
                ) {
                  // Remove the "outline" layer first
                  mapInstance.removeLayer("outline");

                  // Remove the "clickedPolygon" layer and source
                  mapInstance.removeLayer("clickedPolygon");
                  mapInstance.removeSource("clickedPolygon");
                }
              });

              const polygonCoordinates = zone.coordinates.map((coord) => [
                coord[0],
                coord[1],
              ]);

              /// markerpopup fire once
              mp.once("click", () => {
                if (mp.getSource("clickedPolygon")) {
                  console.log("Removing existing layer and source");
                  mp.removeLayer("clickedPolygon");
                  mp.removeLayer("outline");
                  mp.removeSource("clickedPolygon");
                }
                if (zone.id && zone.id.startsWith("ee.europark")) {
                  mp.addSource("clickedPolygon", {
                    type: "geojson",
                    data: {
                      type: "Feature",
                      geometry: {
                        coordinates: [polygonCoordinates],
                        type: "Polygon",
                      },
                    },
                  });

                  mp.addLayer({
                    id: "clickedPolygon",
                    type: "fill",
                    source: "clickedPolygon",
                    layout: {},
                    paint: {
                      "fill-color": "#337FE1",
                      "fill-opacity": 0.2,
                    },
                  });
                  mp.addLayer({
                    id: "outline",
                    type: "line",
                    source: "clickedPolygon",
                    layout: {},
                    paint: {
                      "line-color": "#337FE1",
                      "line-width": 2,
                    },
                  });
                }
                if (zone.id && zone.id.startsWith("ee.yt")) {
                  mp.addSource("clickedPolygon", {
                    type: "geojson",
                    data: {
                      type: "Feature",
                      geometry: {
                        coordinates: [polygonCoordinates],
                        type: "Polygon",
                      },
                    },
                  });

                  mp.addLayer({
                    id: "clickedPolygon",
                    type: "fill",
                    source: "clickedPolygon",
                    layout: {},
                    paint: {
                      "fill-color": "#EC6707",
                      "fill-opacity": 0.2,
                    },
                  });
                  mp.addLayer({
                    id: "outline",
                    type: "line",
                    source: "clickedPolygon",
                    layout: {},
                    paint: {
                      "line-color": "#EC6707",
                      "line-width": 2,
                    },
                  });
                }

                mp.flyTo({
                  center: centerMarker,
                  zoom: 14,
                  offset: [300, 0],
                });
              });
              onMarkerClick(zone);
            };

            const zoneContainerProps = {
              zones,
              onMarkerClick: zoneMarkerClick,
            };

            const zonePortalContainer = document.createElement("div");
            const root = createRoot(zonePortalContainer);
            markerPopup.setDOMContent(zonePortalContainer);
            mp.once("load", () => {
              new maplibregl.Marker({ element: zoneEl })
                .setLngLat(centerMarker)
                .setPopup(markerPopup)
                .addTo(mp)
                .getElement()
                .addEventListener("click", (e) => {
                  e.preventDefault();
                  zoneMarkerClick(e, zone);
                });

              setMarkers((prevMarkers) => [
                ...prevMarkers,
                { zoneId: zone.id, markerPopup },
              ]);
            });
            mp.on("click", () => {
              root.render(
                createPortal(
                  <ZonePopupContent
                    onMarkerClick={onMarkerClick}
                    {...zoneContainerProps}
                    zone={zone}
                    vehicles={vehicles}
                  />,
                  zonePortalContainer
                )
              );
            });
          });
        }
      };

      mp.on("load", function () {
        // Center marker
        if (geojson) {
          mp.addSource("zone", {
            type: "geojson",
            data: geojson,
          });
          mp.addLayer({
            id: "zone",
            type: "fill",
            source: "zone",
            layout: {},
            paint: {
              "fill-color": "#89abde",
              "fill-opacity": 0.3,
            },
          });

          const bbox = extent(geojson);
          mp.cameraForBounds(bbox, { padding: 20 });
        }
      });

      map.current = mp;

      document.addEventListener("click", handleDocumentClick);

      if (params.tab === "zones") {
        setActiveTab("zones");
        setZoneContainerOpen(true);
        setFleetContainerOpen(false);
      } else {
        setActiveTab("vehicles");
        setFleetContainerOpen(true);
        setZoneContainerOpen(false);
      }
      updateMarkers();

      // Cleanup the event listener on component unmount
      return () => {
        document.removeEventListener("click", handleDocumentClick);
        markers.forEach((markerItem) => {
          markerItem.markerPopup.remove();
        });
      };
    }, [
      geojson,
      centerInit,
      vehicles,
      zones,
      isFleetContainerOpen,
      isZoneContainerOpen,
      markerPopup,
      params.tab,
    ]);

    // mouse over closes the polygon section function
    const removeClickedPolygon = () => {
      const mapInstance = map.current;

      // Check if the source exists
      if (mapInstance.getSource("clickedPolygon")) {
        // Remove the "outline" layer first
        if (mapInstance.getLayer("outline")) {
          mapInstance.removeLayer("outline");
        }
        if (mapInstance.getLayer("clickedPolygon")) {
          // Remove the "clickedPolygon" layer
          mapInstance.removeLayer("clickedPolygon");

          // Remove the "clickedPolygon" source
          mapInstance.removeSource("clickedPolygon");
        }
      }
    };

    // toggle Containers
    const handleToggleFleetContainer = () => {
      setFleetContainerOpen(!isFleetContainerOpen);
      setZoneContainerOpen(false);
      navigate('/map/vehicles');
    };

    const onZoneMarkerClick = (zone) => {
      const zoneId = zone.id;

      const existingZone = markers.find((m) => m.zoneId === zoneId);

      if (existingZone) {
        if (currentPopup) {
          currentPopup.remove();
        }
        const markerPopup = new maplibregl.Popup({
          closeOnClick: true,
          className: "popupContainer",
        });

        const zonePortalContainer = document.createElement("div");
        const root = createRoot(zonePortalContainer);
        markerPopup.setDOMContent(zonePortalContainer);

        // Set the content of the popup (you may need to adjust this based on your markerPopup structure)
        root.render(
          createPortal(
            <ZonePopupContent
              onMarkerClick={onMarkerClick}
              data={zone}
              vehicles={vehicles}
            />,
            zonePortalContainer
          )
        );

        // Set the popup latitude and longitude
        const markerCoordinates = existingZone.markerPopup.getLngLat();
        markerPopup.setLngLat(markerCoordinates);

        // Show the popup on the map
        markerPopup.addTo(map.current);

        // Update the currentPopup reference
        currentPopup = markerPopup;

        // Check if the "clickedPolygon" layer already exists and remove it
        if (map.current.getLayer("clickedPolygon")) {
          map.current.removeLayer("clickedPolygon");
          map.current.removeLayer("outline");
          map.current.removeSource("clickedPolygon");
        }

        // Create "clickedPolygon" based on the selected zone
        const polygonCoordinates = zone.coordinates.map((coord) => [
          coord[0],
          coord[1],
        ]);

        map.current.addSource("clickedPolygon", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              coordinates: [polygonCoordinates],
              type: "Polygon",
            },
          },
        });

        map.current.addLayer({
          id: "clickedPolygon",
          type: "fill",
          source: "clickedPolygon",
          layout: {},
          paint: {
            "fill-color": "#337FE1",
            "fill-opacity": 0.3,
          },
        });
        map.current.addLayer({
          id: "outline",
          type: "line",
          source: "clickedPolygon",
          layout: {},
          paint: {
            "line-color": "#337FE1",
            "line-width": 2,
          },
        });

        // Fly to the marker coordinates
        map.current.flyTo({
          center: markerCoordinates,
          zoom: 14,
          offset: [300, 0],
        });
      }
    };

    const onMarkerClick = (vehicle) => {
      const vehicleId = vehicle.id;

      // Find the marker in the markers array
      const existingMarker = markers.find((m) => m.vehicleId === vehicleId);

      if (existingMarker) {
        if (currentPopup) {
          currentPopup.remove();
        }
        const markerPopup = new maplibregl.Popup({
          closeOnClick: true,
          className: "popupContainer",
        });

        const vehiclePortalContainer = document.createElement("div");
        const root = createRoot(vehiclePortalContainer);
        markerPopup.setDOMContent(vehiclePortalContainer);

        // Set the content of the popup (you may need to adjust this based on your markerPopup structure)
        root.render(
          createPortal(
            <VehiclePopupContent
              onMarkerClick={onMarkerClick}
              data={vehicle}
            />,
            vehiclePortalContainer
          )
        );

        // Set the popup latitude and longitude
        const markerCoordinates = existingMarker.markerPopup.getLngLat();
        markerPopup.setLngLat(markerCoordinates);

        // Show the popup on the map
        markerPopup.addTo(map.current);

        // Update the currentPopup reference
        currentPopup = markerPopup;

        // Check if the "clickedPolygon" layer already exists and remove it
        if (map.current.getLayer("clickedPolygon")) {
          map.current.removeLayer("clickedPolygon");
          map.current.removeLayer("outline");
          map.current.removeSource("clickedPolygon");
        }

        // Create "clickedPolygon" based on the selected vehicle
        const matchingZone = zones.find(
          (zone) => zone.name === vehicle.location
        );

        if (matchingZone) {
          const zoneCoordinates = matchingZone.coordinates;

          map.current.addSource("clickedPolygon", {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                coordinates: [zoneCoordinates],
                type: "Polygon",
              },
            },
          });

          map.current.addLayer({
            id: "clickedPolygon",
            type: "fill",
            source: "clickedPolygon",
            layout: {},
            paint: {
              "fill-color": "#337FE1",
              "fill-opacity": 0.3,
            },
          });
          map.current.addLayer({
            id: "outline",
            type: "line",
            source: "clickedPolygon",
            layout: {},
            paint: {
              "line-color": "#337FE1",
              "line-width": 2,
            },
          });
        }

        // Fly to the marker coordinates
        map.current.flyTo({
          center: [markerCoordinates.lng, markerCoordinates.lat],
          zoom: 14,
          offset: [300, 0],
        });
      }
    };

    const handleToggleZoneContainer = () => {
      setZoneContainerOpen(!isZoneContainerOpen);
      setFleetContainerOpen(false);
      navigate('/map/zones')
    };

    const matchLogic = (searchInput, vehicle, zone) => {
      // Convert everything to lowercase for case-insensitive comparison
      const lowerCaseSearchInput = searchInput.toLowerCase();
      const lowerCaseSubject = vehicle.subject && vehicle.subject.toLowerCase();
      const lowerCaseLocation =
        vehicle.location && vehicle.location.toLowerCase();
      const lowerCaseZoneLocation =
        zone && zone.name && zone.name.toLowerCase();

      // Check if the lowercased search input is present in either lowercased subject or location for vehicles
      const vehicleSubjectMatch =
        lowerCaseSubject && lowerCaseSubject.includes(lowerCaseSearchInput);
      const vehicleLocationMatch =
        lowerCaseLocation && lowerCaseLocation.includes(lowerCaseSearchInput);

      // Check if the lowercased search input is present in the zone for zones
      const zoneMatch =
        lowerCaseZoneLocation &&
        lowerCaseZoneLocation.includes(lowerCaseSearchInput);

      // Return true if there is a match either for vehicles or zones
      return vehicleSubjectMatch || vehicleLocationMatch || zoneMatch;
    };

    const handleSearchChange = (newSearchInput) => {
      // Update the search history
      const updatedSearchHistory = [...searchHistory, newSearchInput];
      setSearchHistory(updatedSearchHistory);

      // Perform matching logic for vehicles (customize this part based on your requirements)
      const isVehicleMatch = vehicles.some((vehicle) =>
        matchLogic(newSearchInput, vehicle)
      );
      setMatch(isVehicleMatch);

      // Update the filtered vehicles based on the new search input
      const filteredVehicles = vehicles.filter((vehicle) =>
        matchLogic(newSearchInput, vehicle)
      );
      setFilteredVehicles(filteredVehicles);

      // Perform matching logic for zones (customize this part based on your requirements)
      const zoneMatch = zones.some((zone) => matchLogic(newSearchInput, zone));
      setZoneMatch(zoneMatch);

      // Update the filtered zones based on the new search input
      const filteredZones = zones.filter((zone) =>
        matchLogic(newSearchInput, zone)
      );
      setFilteredZones(filteredZones);

      setSearchInput(newSearchInput);
      setIsInputActive(true);
    };

    useEffect(() => {
      // Filter the vehicles based on the search input
      const filteredVehicles = vehicles.filter((vehicle) =>
        matchLogic(searchInput, vehicle)
      );
      setFilteredVehicles(filteredVehicles);

      // Filter the zones based on the search input
      const filteredZones = zones.filter((zone) =>
        matchLogic(searchInput, [], zone)
      );
      setFilteredZones(filteredZones);
    }, [searchInput, vehicles, zones]);

    const handleToggleContainer = () => {
      setContainerOpen(!isContainerOpen);
    };

    const toggleHistory = () => {
      setShowHistory(!showHistory);
    };

    return (
      <div className="w-full h-full relative">
        <div
          ref={mapContainer}
          className="absolute w-full"
          style={{ height: "100%" }}
        />
        <div className="absolute flex" style={{ top: "30px", left: "30px" }}>
          <div className="flex flex-col bg-white max-h-full relative p-3 rounded-xl">
            <nav className="flex w-full flex-1" style={{ minWidth: 477 }}>
              <ul
                className="-mb-px  w-full flex justify-center items-center text-sm font-medium divide-solid rounded-lg shadow-[0px_0px_4px_rgba(0,0,0,0.1)]"
                id="linkContainer"
              >
                <Link
                  onClick={handleToggleFleetContainer}
                  // isActive={isFleetContainerOpen}
                  isActive={activeTab === "vehicles"}
                  id="vehiclesLink"
                  href="/map/vehicles"
                >
                  <T>Vehicles</T>
                </Link>

                <Link
                  onClick={handleToggleZoneContainer}
                  // isActive={isZoneContainerOpen}
                  isActive={activeTab === "zones"}
                  href="/map/zones"
                >
                  <T>Zones</T>
                </Link>
                <div
                  className="p-4 transition-transform duration-300 ease-in-out transform pointer-events-auto cursor-pointer"
                  style={{
                    background: "#F9FAFB",
                    transition: "transform 0.3s ease-in-out",
                    height: "58px",
                  }}
                  onClick={handleToggleContainer}
                >
                  {isContainerOpen ? <UpArrow /> : <DownArrow />}
                </div>
              </ul>
            </nav>
            {isFleetContainerOpen && (
              <div
                className="fleetContainer transition-all"
                id="fleetContainer"
                style={{
                  display: isContainerOpen ? "block" : "none",
                  transition: "0.25s",
                }}
              >
                <div className="flex items-center justify-center pt-6 pb-3 px-5 gap-2 mt-3 border-t text-base">
                  <a
                    href="#"
                    className="text-[#3B82F6] flex flex-row gap-2 items-center justify-center"
                    onClick={toggleHistory}
                  >
                    <History />
                    {t("Show Parking History")}
                  </a>
                </div>
                {showHistory && (
                  <div className="fixed z-50 top-0 right-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center overflow-y-auto">
                    <div className="absolute rounded-l-lg top-24 right-0 bg-white rounded-md sm:w-3/5">
                      <ParkingHistory
                        showHistory={showHistory}
                        toggleHistory={toggleHistory}
                      />
                      <button
                        className="absolute text-2xl top-5 right-8"
                        onClick={toggleHistory}
                      >
                        <Close />
                      </button>
                    </div>
                  </div>
                )}

                {isFleetContainerOpen && (
                  <SearchInput
                    handleSearchChange={handleSearchChange}
                    // handleSearchEnter={handleSearchEnter}
                    filteredVehicles={filteredVehicles}
                    searchInput={searchInput}
                    searchType="vehicles"
                  />
                )}

                {activeTab === "vehicles" && filteredVehicles.length > 0 ? (
                  <FleetContainer
                    vehicles={filteredVehicles}
                    onMarkerClick={onMarkerClick}
                    searchInput={searchInput}
                  />
                ) : (
                  <div className="w-full text-[#1B1C20] flex justify-center text-14 font-proxima-nova font-semibold leading-20 break-words py-4">
                    <p>{t("No matching vehicles found")}</p>
                  </div>
                )}
              </div>
            )}
            {isZoneContainerOpen && (
              <div
                id="zoneContainer"
                style={{
                  display: isContainerOpen ? "block" : "none",
                  transition: "0.25s",
                }}
              >
                <div className="flex items-center justify-center pt-6 pb-3 px-5 gap-2 mt-3 border-t text-base">
                  <a
                    href="#"
                    className="text-[#3B82F6] flex flex-row gap-2 items-center justify-center"
                    onClick={toggleHistory}
                  >
                    <History />
                    {t("Show Parking History")}
                  </a>
                </div>
                {showHistory && (
                  <div className="fixed z-50 top-0 right-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center overflow-y-auto">
                    <div className="absolute rounded-l-lg top-24 right-0 bg-white rounded-md sm:w-3/5">
                      <ParkingHistory
                        showHistory={showHistory}
                        toggleHistory={toggleHistory}
                      />
                      <button
                        className="absolute text-2xl top-5 right-8"
                        onClick={toggleHistory}
                      >
                        <Close />
                      </button>
                    </div>
                  </div>
                )}
                {isZoneContainerOpen && (
                  <SearchInput
                    handleSearchChange={handleSearchChange}
                    filteredZones={filteredZones}
                    searchInput={searchInput}
                    searchType="zone"
                  />
                )}
                {activeTab === "zones" && filteredZones.length > 0 ? (
                  <ZoneContainer
                    searchInput={searchInput}
                    zones={filteredZones}
                    onMarkerClick={onZoneMarkerClick}
                  />
                ) : (
                  <div className="w-full text-[#1B1C20] flex justify-center text-14 font-proxima-nova font-semibold leading-20 break-words py-4">
                    <p>{t("No matching zones found")}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default Map;
