import React, { useRef, useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import markerSvg from 'data-url:./parking-icon.svg';
import extent from 'turf-extent'
import centerOfMass from '@turf/center-of-mass'

const style = 'klokantech-basic';
const { MAPS_URL } = process.env;
const __mapsurl = MAPS_URL;


export default function Map({ center: centerInit, zoom: zoomInit, geojson, vehicle }){
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng] = useState(centerInit ? centerInit[0]:  24.745);
    const [lat] = useState(centerInit ? centerInit[1]: 59.433);
    const [zoom] = useState(zoomInit ? zoomInit : 17);

    useEffect(() => {
        if (map.current) return;
        if (!geojson && !centerInit) return;
        const mp = new maplibregl.Map({
            container: mapContainer.current,
            style: `${MAPS_URL}/styles/${style}/style.json`,
            hash: false,
            center: [lng, lat],
            zoom: zoom
        });

        const el = document.createElement('div');
        el.className = 'marker';
        el.style.backgroundImage = `url(${markerSvg})`;
        el.style.width = '32px';
        el.style.height = '46px';
        el.style.marginTop = '-18px';

        if (!centerInit) {
          centerInit = centerOfMass(geojson).geometry.coordinates;
        }

        new maplibregl.Marker(el)
            .setLngLat(centerInit)
            .addTo(mp);

        if(vehicle) {
            new maplibregl.Marker()
                .setLngLat(vehicle)
                .addTo(mp);
        }


        mp.on('load', function () {
            if(geojson) {

                console.log('geojson', geojson)

                mp.addSource('zone', {
                    'type': 'geojson',
                    'data': geojson
                })
                mp.addLayer({
                    'id': 'zone',
                    'type': 'fill',
                    'source': 'zone',
                    'layout': {},
                    'paint': {
                        'fill-color': '#89abde',
                        'fill-opacity': 0.3
                    }
                });

                // Zoom into box
                const bbox = extent(geojson);
                mp.fitBounds(bbox, {padding: 20});

            }
        })


        map.current = mp;
    });
    /*
    useEffect(() => {
        console.log('geojson', geojson)
        //isSourceLoaded

    }, geojson)
    */
    return (
        <div className="w-full relative" style={ { height: 420 } }>
            <div ref={mapContainer} className="absolute w-full" style={{ height: "100%" }} />
        </div>
    );
}

/*

 .map-wrap {
  position: relative;
  width: 100%;
  height: calc(100vh - 77px);

.map {
    position: absolute;
    width: 100%;
    height: 100%;
}
* */
