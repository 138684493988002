import React, { useState, useEffect } from "react";
import { useT } from "../../../components/languages/t";
import Pagination from "react-js-pagination";
import Left from "../../../img/left.svg";
import Right from "../../../img/right.svg";

export function ZoneContainer({ zones, onMarkerClick }) {
  const itemsPerPage = 11;
  const [currentPage, setCurrentPage] = useState(1);
  const [clickedItemId, setClickedItemId] = useState(null);
  const [totalZones, setTotalZones] = useState(zones.length);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const { t } = useT();

  const handleItemClick = (zone) => {
    setClickedItemId(zone.id);
    onMarkerClick(zone);
  };

  const handleHeaderClick = (columnName) => {
    setSortColumn(columnName);
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTotalZones(zones.length);
    }, 30000); // 30 seconds

    const handleDocumentClick = (e) => {
      // Check if the clicked element is outside the FleetContainer
      if (!e.target.closest(".fleetContainerInfo")) {
        setClickedItemId(null); // Reset clickedItemId when clicking outside
      }
    };

    // Add event listener to the document
    document.addEventListener("click", handleDocumentClick);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      clearTimeout(timeoutId);
    };
  }, [zones.length]);

  const sortedItems = [...zones].sort((a, b) => {
    if (sortColumn === "name") {
      // If sorting by "Zone" column
      const comparisonResult =
        sortDirection === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);

      return comparisonResult;
    } else if (sortColumn === "parkedVehiclePlate") {
      // If sorting by "Vehicles Parked" column
      const lengthA = a.parkedVehiclePlate.length;
      const lengthB = b.parkedVehiclePlate.length;

      const comparisonResult =
        sortDirection === "asc" ? lengthA - lengthB : lengthB - lengthA;

      return comparisonResult;
    }

    // Default sorting (you can customize this based on your needs)
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <>
      <div className="grid gap-4">
        <div className="">
          <div
            className="grid px-2 gap-4 "
            style={{ gridTemplateColumns: "1fr auto", columnGap: "2rem" }}
          >
            <div
              className="w-full text-[#1B1C20] text-base font-proxima-nova font-normal underline leading-5 break-words  pointer-events-auto cursor-pointer py-4"
              onClick={() => handleHeaderClick("name")}
            >
              {t("Zone")}
              {sortColumn === "name" && (
                <span>{sortDirection === "asc" ? " ↑" : " ↓"}</span>
              )}
            </div>
            <div
              className="w-36 text-left text-[#1B1C20] text-base font-proxima-nova font-normal underline leading-5 break-words  pointer-events-auto cursor-pointer py-4"
              onClick={() => handleHeaderClick("parkedVehiclePlate")}
            >
              {t("Vehicles Parked")}
              {sortColumn === "parkedVehiclePlate" && (
                <span>{sortDirection === "asc" ? " ↑" : " ↓"}</span>
              )}
            </div>
          </div>
          <div className="fleetContainerInfo">
            {currentItems.map((zone) => (
              <a
                key={zone.id}
                href="#"
                className={`grid px-2 rounded-lg ${
                  clickedItemId === zone.id ? "active" : ""
                }`}
                onClick={() => {
                  handleItemClick(zone);
                  onMarkerClick(zone);
                }}
                style={{ gridTemplateColumns: "1fr auto", columnGap: "2rem" }}
              >
                <div
                  className="w-full text-[#1B1C20] text-14 font-proxima-nova font-semibold leading-20 break-words py-4"
                  style={{ width: 262 }}
                >
                  {zone.name}
                </div>
                <div className="w-36 text-[#1B1C20] text-14 font-proxima-nova font-normal leading-20 break-words py-4">
                  {zone.parkedVehiclePlate.length}
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between bg-white px-4 py-3 sm:px-6">
        <div className="w-full flex justify-center">
          <div>
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={zones.length}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              prevPageText={
                <Left className="-ml-0.5 h-5 w-5 pr-1" aria-hidden="true" />
              }
              nextPageText={
                <Right className="-ml-0.5 h-5 w-5 " aria-hidden="true" />
              }
              itemClass="w-10 text-center"
              hideFirstLastPages
            />
          </div>
          <div className="p-2 text-center text-14 font-proxima-nova font-normal leading-20 break-words">
            {t("Total")} <b>{totalZones}</b>
          </div>
        </div>
      </div>
    </>
  );
}

export default ZoneContainer;
