import * as React from 'react';
import {useCallback, useEffect} from "react";

import {useRecoilCallback, useRecoilState, useSetRecoilState} from "recoil";
import {
  AppStore,
  CreateResourceQuery,
  DeleteResourceQuery,
  ResourceDataStore,
  ResourceState,
  UpdateResourceQuery
} from "./store/store";

const { FM_API_URL } = process.env;

export function useResource(resourcePath) {

  const [appState, setAppState] = useRecoilState(AppStore)
  const [state, setState] = useRecoilState(ResourceState(resourcePath))
  const [storeData, setStoreData] = useRecoilState(ResourceDataStore(resourcePath))

  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {

    setIsLoading(true);

    (async () => {

      //const req_res = resourcePath.split('/').filter(e => e)[0]
      //const req_id = get(queryRequestID(req_res));

      const res = await fetch(`${FM_API_URL}${resourcePath}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + appState.token
        },
      })
      const resp = await res.json()

      if(res.status == 401) {
        setAppState({ ...appState, token: null })
      }

      if(resp.success) {
        setStoreData({...storeData, data: resp.data, related: resp.related})
      }

    })().then(() => {
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    })

  }, [resourcePath])


  const handleChanges = useCallback((fieldName) => {
    return (evOrValue) => {

      let key = null;
      let value = null;

      if(!evOrValue.target && fieldName) {
        key = fieldName
        value = evOrValue
      } else if(evOrValue.target) {
        const target = evOrValue.target;
        if(fieldName) {
          key = fieldName
          value = target.value
        } else if (target.name) {
          key = target.name
          value = target.value
        }
      }
      setState({ [key]: value });

    }
  },[resourcePath])

  const handleReset = useCallback(() => {
    console.log('handleReset')
    setState({})
  },[resourcePath])

  const handleSave = useRecoilCallback(({ snapshot }) => async () => {

    let result = null;
    setIsLoading(true);

    // Handle errors
    try {
      //If no ID then create
      if(state.data.id === null) {
        result = await snapshot.getPromise(CreateResourceQuery(resourcePath));
      } else {
        result = await snapshot.getPromise(UpdateResourceQuery(resourcePath));
      }

      console.log("SAVE RESULT", result);

      setIsLoading(false);
      setStoreData(result)
      console.log("Save success")

      return result
      /*
      toast({
        title: "Salvestatud!",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      })
      */
    } catch (e) {
      setIsLoading(false);
      console.log("Save error:", e)

      throw e;
      /*
      toast({
        title: "Viga salvestamisel!",
        description: e,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      })
      */
    }
  }, [resourcePath, storeData]);

  const handleDelete = useRecoilCallback(({ snapshot }) => async () => {
    setIsLoading(true);
    const result = await snapshot.getPromise(DeleteResourceQuery(resourcePath));
    setIsLoading(false);
    console.log('---- handleDelete ----', result)
  },[resourcePath]);

  return { state, handleChanges, handleReset, handleSave, handleDelete, isLoading, setState }
}
