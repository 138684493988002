import React from 'react';
import {useT} from "../languages/t";
import { useRoute } from "wouter";
import { useReactTable,
         flexRender,
         useSortBy,
         getCoreRowModel
} from '@tanstack/react-table'
//import { useTable, usePagination, useSortBy, useFlexLayout, useResizeColumns } from 'react-table'
import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline'
import MultiSelect from "../formElements/MultiSelect";
import Spinner from "../spinner/Spinner";

export function Table({columns, data, fetchData, onRowClick, page: controlledPage, lastPage, pageSize: controlledPageSize, sortBy: controlledSortBy,totalHits, sortDesc, filters = {}, search =  "", selectedItemId, spinner}) {

  // Loading animation
  const [isLoading, setIsLoading] = React.useState(false);
  const LoadingSpinner = spinner || Spinner;

  // TODO: This duplicates functionality below
  const [sortedColumn, setSortedColumn] = React.useState(null);

  const controlledSearch = React.useMemo(() => search, [search])

  const filtersBefore = JSON.stringify(filters, null, 2)

  const controledFilters = React.useMemo(() => {
    return JSON.stringify(filters, null, 2)
  }, [filters])

  const { t } = useT();
  const tableColumns = React.useMemo(() => columns, [columns])

  // Sorting
  const initialSortBy = (controlledSortBy ? [{
    id: controlledSortBy,
    desc: sortDesc
  }] : [{}])
  const [sortBuf, setSorting] = React.useState(initialSortBy)
  const sortId = sortBuf[0] ? sortBuf[0].id : controlledSortBy;
  const sortDescBool = sortBuf[0] ? sortBuf[0].desc : sortDesc;
  const sorting = React.useMemo(
    () => ([{
      id: sortId,
      desc: sortDescBool,
    }]),
    [sortId, sortDescBool]
  )

  // Pagination
  const [{ pageIndex, pageSize }, setPagination] = React.useState({
    pageIndex: controlledPage,
    pageSize: controlledPageSize || 10000,
  })
  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  React.useEffect(() => {
    if (sortId) {
      setSortedColumn(sortId);
    }
  }, [sortId]);

  // Resizing
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const table = useReactTable({
      columns: tableColumns,
      manualPagination: true,
      autoResetPage: false,
      pageCount: (lastPage || 1),
      data,
      //initialState: { pageIndex: controlledPage, pageSize: (controlledPageSize || 10000), sortBy: initialSortBy },
      state: {
        pagination,
        sorting
      },
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
      //onColumnFiltersChange: setFilterState,
      disableMultiSort: true,
      manualSorting: true,
      enableSorting: true,
      //enableFilters: true,
      //manualFiltering: true,
      //manualSortBy: (localSort ? false : true),
      defaultColumn,
      columnResizeMode: 'onChange',
      getCoreRowModel: getCoreRowModel(),
    }
    //,
    //useSortBy,
    //usePagination,
    //useFlexLayout,
    //useResizeColumns
  )

  /*
  if(sortBy && sortBy[0] && sortBy[0].id) {
    sortId = sortBy[0].id;
    sortDescBool = sortBy[0].desc;
    ordering = (sortDesc ? '-' : '') + sortId;
  }
  */
  //const colWidths = React.useRef(`repeat(${columns.length},minmax(150px, 1fr))`)
  const defaultColMinWith = '150px'

  const colWidths = React.useMemo(() => {
    const widths = []
    for(const col of columns) {
      const cw = (col.minWidth ? `calc(1.5rem + ${col.minWidth})` : defaultColMinWith)

      widths.push(`minmax(${cw}, 1fr)`)
    }
    return widths.join(' ')
  }, [columns])


  //console.log('!!!!!!!!!!!!!! WTFFF',columns.length,'sortId', sortId, 'sortDescBool', sortDescBool, 'sorting', sorting, 'colWidths: ', colWidths, table)

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    // Clean up sortBy
    console.log('%%%%%%TBL', controledFilters)

    //console.log('!!!fetchData',  'pageIndex:', pageIndex, 'pageSize:', pageSize, 'pageCount:', table.getPageCount(), 'sortBy:', sortId, 'sortDescBool:', sortDescBool )

    if (fetchData) {
      const fetchResult = fetchData({page: pageIndex, pageSize, sortBy: sortId, sortDesc: sortDescBool, search: controlledSearch, filters: JSON.parse(controledFilters) })
      if (fetchResult) {
        setIsLoading(true);
        fetchResult.then(() => {
          setIsLoading(false);
        }).catch(() => {
          setIsLoading(false);
        })
      }

    }
  }, [pageIndex, pageSize, sortId, sortDescBool, controlledSearch, controledFilters])

//style={{maxHeight: "calc(100% - 4.5rem)", height: "calc(100% - 4.5rem)"}}
  return (
    <div className="flex flex-1 flex-col overflow-x-auto">
      {isLoading && <div className="absolute w-full h-full bg-[rgba(255,255,255,0.8)] flex justify-center items-center"><LoadingSpinner /></div>}
      <table className="border-collapse bg-white text-left text-sm text-gray-500 grid flex-1 data-table" style={{
        gridTemplateColumns: colWidths,
        maxHeight: "calc(100% - 4.5rem)"
      }}>
        <thead className={`grid subgrid col-span-${columns.length} place-content-start sticky top-0`}>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id} className={`grid subgrid col-span-${columns.length}`}>
            {headerGroup.headers.map(header => {
              return <th {...{
                key: header.id,
                colSpan: header.colSpan,
                onClick: header.column.getToggleSortingHandler(),
                style: {
                  textDecoration: sortedColumn === header.id ? 'underline' : 'none',
                },
              }} scope="col"
                  className={`flex top-0 bg-white min-h-[4.3rem] items-center px-6 py-4 font-medium text-gray-900 border-b border-gray-150 border-b-2 ${header.column.getCanSort() && 'cursor-pointer select-none'}`}>
                <div className="flex flex-col w-full">
                  <div onClick={header.column.getToggleSortingHandler()} >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}

                  {{
                    asc: ' ↑',
                    desc: ' ↓' ,

                  }[header.column.getIsSorted()] ?? null}
                  </div>
                </div>
                {header.column.getCanResize() && (
                  <div
                    className={`right-0 bg-transparent w-[10px] h-[4.3rem] absolute top-0 z-1 touch-none`}
                  />
                )}

              </th>
            }
            )}
          </tr>
        ))}
        </thead>

        <tbody
          className={`grid subgrid col-span-${columns.length} place-content-start overflow-y-scroll `}
        >
        {table.getRowModel().rows.map(row => {
          //prepareRow(row)

          let selected = ''
          if (selectedItemId && selectedItemId == row.original.id) {
            selected = "bg-blue-50 hover:bg-blue-50"
          }



          return (
            <tr key={row.id} onClick={() => onRowClick && onRowClick(row.original)} className={`grid subgrid col-span-${columns.length} place-content-start`} >
              {row.getVisibleCells().map(cell => {
                return (
                  <td
                    {...{
                      key: cell.id,
                      //style: {
                      //  left: cell.column.getStart(),
                      //  width: cell.column.getSize(),
                      //},
                    }}
                    className={`flex flex-1 min-h-[4.3rem] max-h-[4.3rem] px-6 py-4 text-ellipsis overflow-hidden whitespace-nowrap content-baseline items-center hover:bg-gray-50 ${selected}`}>
                    {cell.getValue()}
                  </td>
                )
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
      {controlledPage !== null &&
      <div className="sticky flex flex-none py-4 max-h-[4.5rem] bg-white bottom-0 left-0 right-0">
        <nav className="flex-1 flex justify-between px-4" aria-label="Pagination">
          <ul className="inline-flex flex-1 justify-between items-center space-x-1 rounded-md text-sm">
            <li className="flex gap-2">
              <button
                 type="button"
                 className="inline-flex items-center space-x-2 rounded border border-gray-300 bg-white px-2 py-2 font-medium text-gray-500 hover:bg-gray-50"
                 onClick={() => table.setPageIndex(0)}
                 disabled={!table.getCanPreviousPage()}
              >
                <ChevronDoubleLeftIcon className="h-5 w-5 text-gray-500" />
              </button>
              <button
                type="button"
                className="inline-flex items-center space-x-2 rounded border border-gray-300 bg-white px-2 py-2 font-medium text-gray-500 hover:bg-gray-50"
                disabled={!table.getCanPreviousPage()}
                onClick={() => table.previousPage()}
              >
                <ChevronLeftIcon className="h-5 w-5 text-gray-500" />
              </button>
            </li>
            <li className="flex items-center">
          <span className="inline-flex items-center rounded-md bg-white px-4 py-2 text-gray-500">{t('Page')} <b
            className="mx-1">{table.getState().pagination.pageIndex + 1}</b> / <b className="ml-1">{table.getPageCount()}</b></span>
              {controlledPageSize &&
              <><span className="inline-flex items-center rounded-md bg-white py-2 text-gray-500">{t('show')}</span>
              <select
                className="relative block w-full min-h-[40px] rounded border border-transparent pl-2 pr-6 text-left text-sm font-bold text-gray-500 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50"
                value={pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value))
                }}>
                {[1,2,3, 5, 10, 50, 100, 250].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select></>}
            </li>
            <li className="flex gap-2">
            <div className='flex items-center'>{t("Total")} : {totalHits}</div>
              <button
                type="button"
                className="inline-flex items-center space-x-2 rounded border border-gray-300 bg-white px-2 py-2 font-medium text-gray-500 hover:bg-gray-50"
                disabled={!table.getCanNextPage()}
                onClick={() => table.nextPage()}
              >
                <ChevronRightIcon className="h-5 w-5 text-gray-500" />
              </button>
              <button
                type="button"
                className="inline-flex items-center space-x-2 rounded border border-gray-300 bg-white px-2 py-2 font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => table.setPageIndex(table.getPageCount() ? table.getPageCount() - 1 : 1)}
                disabled={!table.getCanNextPage()}
              >
                <ChevronDoubleRightIcon className="h-5 w-5 text-gray-500" />
              </button>
            </li>
          </ul>
        </nav>
      </div>}

    </div>
  );
}
