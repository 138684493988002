import { BackspaceIcon } from "@heroicons/react/24/solid";
import { useRoute } from "wouter";
import { useT } from "../../components/languages/t";
import Close from "../../img/close.svg";
import React, { useState, useEffect, useRef } from "react";
import SearchHistory from "../../components/formElements/SearchHistory";

export default function SearchInput({
  className,
  handleSearchChange,
  query,
  handleSearch,
  handleSearchEnter,
  filteredZones,
  isParkingHistoryOpen,
  filteredVehicles,
  searchInput,
  clearSearch,
  searchType,
}) {
  const [match] = useRoute("/map/:tab?");
  const [searchQuery, setSearchQuery] = useState(searchInput);
  // const [searchHistory, setSearchHistory] = useState([]);
  const [vehicleSearchQuery, setVehicleSearchQuery] = useState(searchInput);
  const [zoneSearchQuery, setZoneSearchQuery] = useState(searchInput);
  // const [isInputActive, setIsInputActive] = useState(false);
  // const [suggestions, setSuggestions] = useState([]);
  const { t } = useT();
  const inputRef = useRef(null);

  if (!className) {
    className =
      "bg-[#F9FAFB] rounded-md overflow-hidden border border-[#D1D5DB] ";
  }

  const updateSearchQuery = (value) => {
    setSearchQuery(value);
    console.log(value, "queryvalue!");
    handleSearchChange(value);

    if (searchType === "vehicles") {
      setVehicleSearchQuery(value);
      updateVehicleSuggestions(value);
    } else if (searchType === "zone") {
      setZoneSearchQuery(value);
      updateZoneSuggestions(value);
    }
  };

  const filterVehicles = (value) => {
    const lowerCaseValue = value.toLowerCase();
    return filteredVehicles.filter(
      (vehicle) =>
        vehicle.subject.toLowerCase().includes(lowerCaseValue) ||
        vehicle.location.toLowerCase().includes(lowerCaseValue)
    );
  };

  const matchZones = (value) => {
    console.log("matchzoneValue", value);
    const lowerCaseValue = value.toLowerCase();
    return filteredZones.filter((zone) =>
      zone.name.toLowerCase().includes(lowerCaseValue)
    );
  };

  const updateVehicleSuggestions = (value) => {
    const matchingFilteredVehicles = filterVehicles(value);

    const combinedSuggestions = [
      ...matchingFilteredVehicles,
      // ...matchingHistory,
    ];

    // setSuggestions(combinedSuggestions.slice(0, 5)); // Limit the number of suggestions
  };

  const updateZoneSuggestions = (value) => {
    console.log("updateZoneSuggestions", value);
    const matchingFilteredZones = matchZones(value);

    const combinedSuggestions = [
      ...matchingFilteredZones,
      //  ...matchingHistory
    ];

    // setSuggestions(combinedSuggestions.slice(0, 5)); // Limit the number of suggestions
  };

  const placeholderText = match
    ? t("Vehicle Number / Zone")
    : t("Quick search...");

  
    const clearSearchContainer = () => {
      handleSearchChange("");
  
      if (searchType === "vehicles") {
        setVehicleSearchQuery("");
        console.log("Clearing vehicle search");
      } else if (searchType === "zone") {
        setZoneSearchQuery("");
        console.log("Clearing zone search");
      }
    };

  const clearHistory = () => {
    setSearchHistory([]);
    localStorage.removeItem("searchHistory");
  };

  // Event handler for focusing on the input
  const handleInputFocus = () => {
    setIsInputActive(true);
  };

  // Event handler for blurring the input
  const handleInputBlur = () => {
    setIsInputActive(false);
  };

  return (
    <div>
      {match ? (
        <div className="pt-3">
          <div className="py-4">
            <div className="w-full h-full  flex flex-col justify-start items-start gap-10 inline-flex">
              <div className="w-full bg-[#F9FAFB] rounded-md overflow-hidden border border-[#D1D5DB] ">
                <div className="flex-1 self-stretch text-[#6B7280] text-base font-proxima-nova font-normal leading-5 break-words"></div>

                <div className="group relative">
                  {isParkingHistoryOpen ? (
                    <input
                      type="text"
                      className="block w-full border-transparent bg-transparent px-12 py-3 transition-all focus:border-primary-200 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 placeholder:text-gray-500 "
                      value={query}
                      onChange={handleSearch}
                      onKeyPress={handleSearchEnter}
                      placeholder={placeholderText}
                    />
                  ) : (
                    <input
                      type="text"
                      className="block w-full border-transparent bg-transparent px-12 py-3 transition-all focus:border-primary-200 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 placeholder:text-gray-500 "
                      value={searchType === "vehicles" ? vehicleSearchQuery : zoneSearchQuery}
                      onChange={(e) => {
                        updateSearchQuery(e.target.value);
                        // handleSuggestionClick(e.target.value);
                      }}
                      placeholder={placeholderText}
                      // onFocus={handleInputFocus}
                      // onBlur={handleInputBlur}
                    />
                  )}

                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4 pr-2.5 text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-label="Parking History icon"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2.5">
                    {isParkingHistoryOpen ? (
                      <button type="button" onClick={clearSearch}>
                        <Close className="stroke-gray-500" />
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          clearSearchContainer(searchType);
                        }}
                        disabled={searchType === "vehicles" ? !vehicleSearchQuery.trim() : !zoneSearchQuery.trim()}
                      >
                        <Close className="stroke-gray-500" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={className}>
          <div>
            <div className="group relative">
              <input
                type="text"
                className="block w-full border-transparent bg-transparent px-10 transition-all focus:border-primary-200 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 placeholder:italic placeholder:text-slate-300 "
                value={query}
                onChange={handleSearch}
                onKeyPress={handleSearchEnter}
                placeholder={t("Quick search...")}
              />
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2.5 text-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center px-2.5">
                <button type="button" onClick={clearSearch}>
                  <BackspaceIcon
                    className={`h-5 w-5 ${
                      query ? "text-blue-400" : "text-gray-200"
                    }`}
                  />
                </button>
              </div>
              {/*
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2.5">
          <span
            className="rounded border px-1.5 text-sm text-gray-400 shadow-sm transition-all group-hover:border-primary-500 group-hover:text-primary-500"><kbd>⌘</kbd> <kbd>K</kbd></span>
        </div>
         */}
            </div>
          </div>
        </div>
      )}
      {/* {isInputActive && suggestions.length > 0 && match && (
        <SearchHistory
          suggestions={suggestions}
          searchHistory={searchHistory}
          isInputActive={isInputActive}
          currentSearch={searchQuery}
          handleSuggestionClick={handleSuggestionClick}
        />
      )} */}
    </div>
  );
}
