import SearchInput from "../../routes/map/SearchInput";
import { useRoute } from "wouter";


const TableHeader = ({ title, children }) => {

  const [match] = useRoute("/map");

  return <div className={`flex flex-[0_0_auto] ${match ? 'rounded-tl-lg ' : ''} justify-between bg-gray-50 border-b border-gray-100 min-h-[4.3rem] items-center gap-4 pr-6`}>
    <div className="text-lg font-medium align-middle ml-6 text-gray-700" style={{textShadow: "#fff 0px -1px 1px, #e5e5e5 0px 1px 1px"}}>{title}</div>
    <div className="flex-1 flex justify-end -mr-3">
      {children}
    </div>
  </div>
}

export default TableHeader
