import * as flags from '../../flags/*.png';
import T, { useT } from "./t";
import {useRecoilState, useRecoilValue} from "recoil";
import {AppStore} from "../../store/store";

const langs = ['et','en']

export function Languages() {

  const { t, i18n } = useT();

  const [appState, setAppState] = useRecoilState(AppStore)

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setAppState({ ...appState, language: lng })
  };

  return (
    <nav className="flex">
      {langs.map(l => <a href="#" key={l} onClick={() => changeLanguage(l)} className="px-1"><img src={flags[l]} className={`h-8 ${i18n.language !== l && 'opacity-25'}`} /></a>)}
    </nav>
  );
}
