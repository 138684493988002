import {forwardRef, memo } from 'react'
import {ref} from "yup";
export default memo(forwardRef(function TextArea({ id, value, placeholder, onChange, required, isInvalid, isTouched }, ref) {

  const extraCls = isInvalid ? ' border-red-300 focus:border-red-300 focus:ring-red-200 focus:ring-opacity-50 ': (isTouched && ' border-green-400 focus:border-green-400 focus:ring-green-200 focus:ring-opacity-50 ');


  return <textarea id={id}
                   ref={ref}
                   className={`block w-full rounded border-gray-300 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 ${extraCls}`}
                   rows="3" placeholder={placeholder} value={value} onChange={onChange} ></textarea>
}), (oldProps, newProps) => {
  //console.log(oldProps.id, 'oldProps.id === newProps.id', oldProps.id === newProps.id, 'oldProps.value === newProps.value', oldProps.value === newProps.value)
  return (oldProps.id === newProps.id && oldProps.value === newProps.value && oldProps.isInvalid === newProps.isInvalid && oldProps.isTouched === newProps.isTouched)
})
