import * as React from 'react';
import {useCallback} from "react";

import {atomFamily, useRecoilState, useRecoilValue} from "recoil";
import {
  ListResourceQueryResult,
} from "../../store/store"

const SearchParamsStore = atomFamily({
  key: 'SearchParamsStore',
  default: "",
});

export function useSearch(resourcePath, listQueryHandler) {

  const list = useRecoilValue(ListResourceQueryResult(resourcePath))
  const [query, setQuery] = useRecoilState(SearchParamsStore(resourcePath));

  const handleSearch = useCallback((ev) => {
    const target = ev.target;
    setQuery(target.value)

  }, [resourcePath])

  const handleSearchEnter = (ev) => {
    if(ev && ev.key && ev.key === 'Enter') {
      const p = { page: 0, pageSize: list.pageSize, sortBy: list.sortBy, sortDesc: list.sortDesc, search: query, filters: list.filters }
      listQueryHandler(p);
    }
  }

  const clearSearch = useCallback(() => {
    setQuery("")
    const p = { page: 0, pageSize: list.pageSize, sortBy: list.sortBy, sortDesc: list.sortDesc, search: "", filters: list.filters }
    listQueryHandler(p);
  },[resourcePath])

  return { query, handleSearch, handleSearchEnter, clearSearch }
}
