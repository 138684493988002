import {
  atom, atomFamily,
  selector,
  selectorFamily,
  DefaultValue
} from 'recoil';

const { FM_API_URL } = process.env;

export class UnauthorizedError extends Error {
  constructor(message) {
    super(message);
    this.name = "UnauthorizedError";
  }
}

// This tracks the versions of queries so we can re-query when needed
// Applied to both lists and CRUD
const queryRequestID = atomFamily({
  key: 'queryRequestID',
  default: 0,
});

//
// List Queries and Stores
//
export const ListDataStore = atomFamily({
  key: 'ListDataStore',
  default: (resourcePath) => ListResourceQuery(resourcePath),
});

export const ListQueryState = atomFamily({
  key: "ListQueryState",
  default: {
    page: 0,
    pageSize: 50,
    sortBy: "",
    sortDesc: false,
    search: "",
    filters: {}
  },
})

export const ListResourceQueryResult = atomFamily({
  key: "ListResourceQueryResult",
  default: {
    page: 0,
    pageSize: 50,
    sortBy: "",
    sortDesc: false,
    search: "",
    filters: {},
    data: [],
    related: {}
  }
});

export const ListResourceQuery = selectorFamily({
  key: "ListResourceQuery",
  get: (resourcePath) => async ({get, getCallback }) => {
    /*
    const queryState = get(ListQueryState(resourcePath))
    const appState = get(AppStore)

    const res = await fetch(`${FM_API_URL}${resourcePath}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + appState.token
      },
      body: JSON.stringify(queryState)
    })
    const resp = await res.json()

    if(res.status == 401) {
      throw new UnauthorizedError(resp.message)
    }

    // API error message
    if (!resp.success) {
      throw resp.message;
    }

     */

    return resp

  },
})

//
// CRUD Queies and Stores
//

export const ResourceDataStore = atomFamily({
  key: 'ResourceDataStore',
  //default: (resourcePath) => GetResourceQuery(resourcePath),
  default: {
    data: {},
    changes: {},
    related: {}
  }
});

export const GetResourceQuery = selectorFamily({
  key: "GetResourceQuery",
  get: (resourcePath) => async ({get}) => {

    /*
    const appState = get(AppStore)
    const req_res = resourcePath.split('/').filter(e => e)[0]
    const req_id = get(queryRequestID(req_res));

    const res = await fetch(`${FM_API_URL}${resourcePath}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + appState.token
      },
    })
    const resp = await res.json()

    if(res.status == 401) {
      throw new UnauthorizedError(resp.message)
    }

    // API error message
    if (!resp.success) {
      throw resp.message;
    }

    return resp

     */
  }
})

export const CreateResourceQuery = selectorFamily({
  key: "CreateResourceQuery",
  get: (resourcePath) => async ({get}) => {

    const appState = get(AppStore)
    const storeData = get(ResourceDataStore(resourcePath))
    const data =  { ...storeData.data, ...storeData.changes }
    delete data.id;

    const res = await fetch(`${FM_API_URL}${resourcePath}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + appState.token
      },
      body: JSON.stringify({ data })
    })
    const resp = await res.json()

    // API error message
    if (!resp.success) {
      throw resp.message;
    }

    return resp
  }
})

export const UpdateResourceQuery = selectorFamily({
  key: "UpdateResourceQuery",
  get: (resourcePath) => async ({get}) => {
    const appState = get(AppStore)
    const storeData = get(ResourceDataStore(resourcePath))
    const res = await fetch(`${FM_API_URL}${resourcePath}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + appState.token
      },
      body: JSON.stringify(storeData)
    })
    const resp = await res.json()

    // API error message
    if (!resp.success) {
      throw resp.message;
    }

    return resp
  }
})

export const DeleteResourceQuery = selectorFamily({
  key: "DeleteResourceQuery",
  get: (resourcePath) => async ({get}) => {

    const appState = get(AppStore)

    const res = await fetch(`${FM_API_URL}${resourcePath}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + appState.token
      }
    })
    const resp = await res.json()

    // API error message
    if (!resp.success) {
      throw resp.message;
    }

    return resp
  }
})

export const ResourceState = selectorFamily({
  key: "ResourceState",
  get: (resourcePath) => ({get}) => {
    const storeData = get(ResourceDataStore(resourcePath))

    return storeData
    //const queryData = get(GetResourceQuery(resourcePath))
    //return Object.assign({}, storeData, { data: queryData.data })
  },
  set: (resourcePath) => ({get, set}, newValue) => {
    const storeData = get(ResourceDataStore(resourcePath))

    //Special case when newValue is an empty object - then we delete the changes
    for (const i in newValue) {
      set(ResourceDataStore(resourcePath), {...storeData, changes: {...storeData.changes, ...newValue}});
      return;
    }
    set(ResourceDataStore(resourcePath), {...storeData, changes: {}});

  }
})

//
// App state
//
const localStorageEffect = key => ({setSelf, onSet}) => {
  const savedValue = localStorage.getItem(key)
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet(newValue => {
    if (newValue instanceof DefaultValue) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(newValue));
    }
  });
};

export const AppStore = atom({
  key: 'AppStore',
  default: {
    token: null,
    adm : false,
    email : null,
    name: null,
    id : 0,
    logo: '',
    languages: ['en'],
    fleets: []
  },
  effects_UNSTABLE: [
    localStorageEffect('fm_app_state'),
  ]
})
