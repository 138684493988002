import T from "../components/languages/t";
import { Languages } from "../components/languages/languages";
import Logo from '../img/EPLogo.svg';
import { useRoute, useLocation, Link as WLink } from "wouter";
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline'

function Link(props) {
  const [isActive] = useRoute(props.to + '/:rest*');

  return (
    <WLink {...props}>
      <li><a className={`uppercase cursor-pointer flex items-center justify-center gap-2 px-1 py-3 text-[#1b1c20] font-medium hover:text-[#e94f0a] border-b-2 ${isActive ? 'border-[#e94f0a]' : 'border-transparent'} hover:border-gray-200 `}>{props.children}</a></li>
    </WLink>
  );
}



export function TopNav({ appState }) {

  const [_, navigate] = useLocation();
  return (
    <div className="flex items-center min-h-[100px] bg-white px-10" style={{ zIndex: "51" }}>
      <a href="#" className="w-56"><Logo /></a>
      <nav className="flex-1">
        <ul className="-mb-px flex items-center gap-4 text-sm font-medium">
          {((appState.operators || []).length > 0  || appState.can_view_sessions) && <Link to={`/map`}><T>Map</T></Link>}
          {((appState.operators || []).length > 0  || appState.can_view_sessions) && <Link to={`/parking`}><T>Parking Sessions</T></Link>}
          {(appState.fleets || []).length > 0 && <Link to={`/fines`}><T>Fines</T></Link>}
          {(appState.fleets || []).length > 0 && <Link to={`/vehicles`}><T>Vehicles</T></Link>}
          <Link to={`/users`}><T>Users</T></Link>
          {((appState.operators || []).length > 0  || appState.can_view_sessions) && <Link to={`/reports`}><T>Reports</T></Link>}
        </ul>
      </nav>
      <Languages></Languages>
      <button type="button"
        onClick={() => { navigate('/logout') }}
        className="-mr-6 ml-4 inline-flex items-center rounded gap-0.5 px-2 py-2.5 text-center text-sm font-medium text-blue-500 transition-all hover:bg-gray-100 focus:ring focus:ring-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400">
        <ArrowRightOnRectangleIcon className="h-[40px] w-[40px] text-gray-500 p-2" />
      </button>
    </div>
  );
}

