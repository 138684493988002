import { InformationCircleIcon, ExclamationCircleIcon} from "@heroicons/react/24/outline";
function SupportText({ helpMessage, errorMessage }) {

  if(!!errorMessage) {
    return <div className="flex text-sm text-red-500 pt-2 items-center leading-5">
      <ExclamationCircleIcon className="h-5 w-5 min-w-[1.25rem] text-red-500 mr-2" /> {errorMessage}
    </div>
  }

  return <div className="flex text-sm text-gray-400 pt-2 items-center leading-5">
    <InformationCircleIcon className="h-5 w-5 min-w-[1.25rem] text-gray-400 mr-2" /> {helpMessage}
  </div>
}

export default SupportText
