import React, { useEffect, useState } from "react";
import { useT } from "../../components/languages/t";
import Citybee from "../../img/citybee.png";
import Bolt from "../../img/bolt.png";
import {displayTimeDifference} from "./VehiclesLive";

const { FM_API_URL, PRICE_THRESHOLD } = process.env;

const VehiclePopupContent = ({ data }) => {

  const [updatedTime, setUpdatedTime] = useState(new Date());

  const { t } = useT();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setUpdatedTime(new Date());
    }, 30000); // 30 seconds

    // Cleanup the timeout on component unmount or when the dependency changes
    return () => clearTimeout(timeoutId);
  }, [updatedTime]);
  
  return (
    <div className="min-w-[200px]">
      <div className="flex pr-4 pl-4 flex-row items-center bg-[#F9FAFB] rounded-t-lg">
        {data.fleet_id === "ee.citybee" && (
          <img className="p-1 w-8 h-8" src={Citybee} alt="Citybee Icon" />
        )}
        {data.fleet_id === "ee.cachet" && (
          <img
            className="p-1 w-8 h-8"
            src={Bolt}
            alt="bolt Icon"
          />
        )}
        <div className="font-semibold flex items-center justify-center text-lg gap-2 p-4 text-[#1b1c20] font-medium">
          {data.subject}
        </div>
      </div>
      <div className="flex gap-2 px-3 py-4 text-base text-[#1b1c20] font-medium">
        <p className="text-[#3C3B3B] font-normal">{t("Zone")}</p>
        {data.locality_name}
      </div>
      <div className="flex gap-2 items-center px-3 py-4 border-t-2 padding-18 text-base text-[#3C3B3B] font-medium">
        <p className="text-[#1b1c20] font-normal">{t("Current Price")}</p>
        <div
          className={`w-max px-3 py-2 rounded ${
            data.price_net !== null && (data.price_net > (data.price_reminder_threshold || PRICE_THRESHOLD))
              ? "text-[#ef4444] bg-[#fef2f2]"
              : ""
          }`}
        >
          {data.price_net !== null ? (
            <p>{data.price_net} €</p>
          ) : (
            <p>0.00 €</p>
          )}
        </div>
      </div>
      <div className="flex gap-2 px-3 items-center py-4 border-t-2 padding-18 text-base text-[#3C3B3B] font-medium">
        <p className="text-[#1b1c20] font-normal"> {t("Parked Time")}</p>
        {displayTimeDifference(data.session_started_at, updatedTime, t)}
      </div>
    </div>
  );
};

export default VehiclePopupContent;
