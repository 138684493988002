export default {
  "AT": {"isoCode": "AT", "name": "Austria"},
  "BE": {"isoCode": "BE", "name": "Belgium"},
  "BG": {"isoCode": "BG", "name": "Bulgaria"},
  "HR": {"isoCode": "HR", "name": "Croatia"},
  "CY": {"isoCode": "CY", "name": "Cyprus"},
  "CZ": {"isoCode": "CZ", "name": "Czech Republic"},
  "DK": {"isoCode": "DK", "name": "Denmark"},
  "EE": {"isoCode": "EE", "name": "Estonia"},
  "FI": {"isoCode": "FI", "name": "Finland"},
  "FR": {"isoCode": "FR", "name": "France"},
  "DE": {"isoCode": "DE", "name": "Germany"},
  "GR": {"isoCode": "GR", "name": "Greece"},
  "HU": {"isoCode": "HU", "name": "Hungary"},
  "IE": {"isoCode": "IE", "name": "Ireland, Republic of (EIRE)"},
  "IT": {"isoCode": "IT", "name": "Italy"},
  "LV": {"isoCode": "LV", "name": "Latvia"},
  "LT": {"isoCode": "LT", "name": "Lithuania"},
  "LU": {"isoCode": "LU", "name": "Luxembourg"},
  "MT": {"isoCode": "MT", "name": "Malta"},
  "NL": {"isoCode": "NL", "name": "Netherlands"},
  "PL": {"isoCode": "PL", "name": "Poland"},
  "PT": {"isoCode": "PT", "name": "Portugal"},
  "RO": {"isoCode": "RO", "name": "Romania"},
  "SK": {"isoCode": "SK", "name": "Slovakia"},
  "SI": {"isoCode": "SI", "name": "Slovenia"},
  "ES": {"isoCode": "ES", "name": "Spain"},
  "SE": {"isoCode": "SE", "name": "Sweden"},
  "GB": {"isoCode": "GB", "name": "United Kingdom (Great Britain)"},

  "GI": {"isoCode": "GI", "name": "Gibraltar"},
  "AX": {"isoCode": "AX", "name": "Åland Islands"},

  "AD": {"isoCode": "AD", "name": "Andorra"},
  "LI": {"isoCode": "LI", "name": "Liechtenstein"},
  "MC": {"isoCode": "MC", "name": "Monaco"},
  "SM": {"isoCode": "SM", "name": "San Marino"},
  "VA": {"isoCode": "VA", "name": "Vatican City"},

  "JE": {"isoCode": "JE", "name": "Jersey"},
  "GG": {"isoCode": "GG", "name": "Guernsey"},
}
