import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import differenceInMinutes from "date-fns/differenceInMinutes";
import format from "date-fns/format";
import { useRecoilState, useRecoilValue } from "recoil";
import { AppStore } from "../../../store/store";
import parseISO from "date-fns/parseISO";
import { useT } from "../../../components/languages/t";
import Left from "../../../img/left.svg";
import Right from "../../../img/right.svg";

const { PRICE_THRESHOLD } = process.env;

export function FleetContainer({ vehicles, onMarkerClick }) {
  const itemsPerPage = 11;

  const [currentPage, setCurrentPage] = useState(1);
  const [clickedItemId, setClickedItemId] = useState(null);
  const [updatedTime, setUpdatedTime] = useState(new Date());
  const { t, i18n } = useT();
  const [appState, setAppState] = useRecoilState(AppStore);
  const [priceReminderThreshold, setPriceReminderThreshold] = useState(
    appState.price_reminder_threshold || PRICE_THRESHOLD
  );

  const [sortDirection, setSortDirection] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemClick = (vehicle) => {
    setClickedItemId(vehicle.id);
    onMarkerClick(vehicle);
  };

  const handleHeaderClick = (columnName) => {
    setSortColumn(columnName);
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
  };

  useEffect(() => {
    const handleDocumentClick = (e) => {
      // Check if the clicked element is outside the FleetContainer
      if (!e.target.closest(".fleetContainerInfo")) {
        setClickedItemId(null); // Reset clickedItemId when clicking outside
      }
    };

    // Add event listener to the document
    document.addEventListener("click", handleDocumentClick);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setUpdatedTime(new Date());
    }, 30000); // 30 seconds

    // Cleanup the timeout on component unmount or when the dependency changes
    return () => clearTimeout(timeoutId);
  }, [updatedTime, vehicles.length]);

  const displayTimeDifference = (startedAt, updatedTime) => {
    const differenceInMinutesValue = differenceInMinutes(
      updatedTime,
      parseISO(startedAt)
    );

    if (differenceInMinutesValue >= 60) {
      const days = Math.floor(differenceInMinutesValue / (24 * 60));
      const hours = Math.floor((differenceInMinutesValue % (24 * 60)) / 60);
      const remainingMinutes = differenceInMinutesValue % 60;

      if (remainingMinutes === 0) {
        if (days > 0) {
          return `${days} ${days === 1 ? t("day") : t("days")} ${hours} ${
            hours === 1 ? t("hour") : t("hours")
          } ${t("ago")}`;
        } else {
          return `${hours} ${hours === 1 ? t("hour") : t("hours")} ${t("ago")}`;
        }
      } else {
        if (days > 0) {
          return `${days} ${days === 1 ? t("day") : t("days")} ${hours} ${
            hours === 1 ? t("hour") : t("hours")
          } ${t("and")} ${remainingMinutes} ${
            remainingMinutes === 1 ? t("minute") : t("minutes")
          } ${t("ago")}`;
        } else {
          return `${hours} ${hours === 1 ? t("hour") : t("hours")} ${t(
            "and"
          )} ${remainingMinutes} ${
            remainingMinutes === 1 ? t("minute") : t("minutes")
          } ${t("ago")}`;
        }
      }
    } else {
      return `${differenceInMinutesValue} ${
        differenceInMinutesValue === 1 ? t("minute") : t("minutes")
      } ${t("ago")}`;
    }
  };
  const sortedItems = [...vehicles].sort((a, b) => {
    const valueA = a[sortColumn];
    const valueB = b[sortColumn];

    // Helper function to handle null and undefined values
    const handleNullOrUndefined = (value) =>
      value == null ? "" : value.toString();

    // Replace null values with a string representation for sorting
    const stringValueA = handleNullOrUndefined(valueA);
    const stringValueB = handleNullOrUndefined(valueB);

    // Compare the string values using localeCompare
    const comparisonResult =
      sortDirection === "asc"
        ? stringValueA.localeCompare(stringValueB)
        : stringValueB.localeCompare(stringValueA);

    // If the values are not strings, perform additional numeric comparison
    if (!isNaN(valueA) && !isNaN(valueB)) {
      return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
    }

    return comparisonResult;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  // appState.price_reminder_threshold

  return (
    <>
      <div className="grid gap-4">
        <div className="grid gap-4">
          <div className="">
            <div className="grid grid-cols-5-custom items-center px-2">
              <div
                className="w-full text-[#1B1C20] text-base font-proxima-nova font-normal underline leading-5 break-words pointer-events-auto cursor-pointer py-4"
                style={{ width: 80 }}
                onClick={() => handleHeaderClick("subject")}
              >
                {t("Plate N")}
                {sortColumn === "subject" && (
                  <span>{sortDirection === "asc" ? " ↑" : " ↓"}</span>
                )}
              </div>
              <div
                className="w-full text-[#1B1C20] text-base font-proxima-nova font-normal underline leading-5 break-words pointer-events-auto cursor-pointer py-4"
                style={{ width: 120 }}
                onClick={() => handleHeaderClick("location_code")}
              >
                {t("Zone Code")}
                {sortColumn === "location_code" && (
                  <span>{sortDirection === "asc" ? " ↑" : " ↓"}</span>
                )}
              </div>
              <div
                className="w-full text-[#1B1C20] text-base font-proxima-nova font-normal underline leading-5 break-words  pointer-events-auto cursor-pointer py-4"
                style={{ width: 172 }}
                onClick={() => handleHeaderClick("location")}
              >
                {t("Zone Address")}
                {sortColumn === "location" && (
                  <span>{sortDirection === "asc" ? " ↑" : " ↓"}</span>
                )}
              </div>
              <div
                className="w-full text-[#1B1C20] text-base font-proxima-nova font-normal underline leading-5 break-words pointer-events-auto cursor-pointer py-4 "
                style={{ width: 120 }}
                onClick={() => handleHeaderClick("started_at")}
              >
                {t("Parked Time")}
                {sortColumn === "started_at" && (
                  <span>{sortDirection === "asc" ? " ↑" : " ↓"}</span>
                )}
              </div>
              <div
                className="w-full text-center text-[#1B1C20] text-base font-proxima-nova font-normal underline leading-5 break-words pointer-events-auto cursor-pointer py-4"
                onClick={() => handleHeaderClick("net_price")}
              >
                {t("Netto")}
                {sortColumn === "net_price" && (
                  <span>{sortDirection === "asc" ? " ↑" : " ↓"}</span>
                )}
              </div>
            </div>
            <div className="fleetContainerInfo">
              {currentItems.map((vehicle) => (
                <a
                  key={vehicle.id}
                  href="#"
                  onClick={() => {
                    handleItemClick(vehicle);
                    onMarkerClick(vehicle);
                  }}
                  className={`grid grid-cols-5-custom px-2  items-center rounded-lg ${
                    clickedItemId === vehicle.id ? "active" : ""
                  }`}
                >
                  <div
                    className="w-full text-[#1B1C20] text-14 font-proxima-nova font-semibold leading-20 break-words py-4"
                    style={{ width: 80 }}
                  >
                    {vehicle.subject}
                  </div>
                  <div
                    className="w-full text-[#1B1C20] text-14 font-proxima-nova font-normal leading-20 break-words py-4"
                    style={{ width: 120 }}
                  >
                    {vehicle.location_code}
                  </div>
                  <div
                    className="w-full text-[#1B1C20] text-14 font-proxima-nova font-normal leading-20 break-words py-4"
                    style={{ width: 172 }}
                  >
                    {vehicle.location}
                  </div>
                  <div className="w-full text-[#1B1C20] text-14 font-proxima-nova font-normal leading-20 break-words py-4">
                    <div className="w-max " style={{ width: 120 }}>
                      {displayTimeDifference(vehicle.started_at, updatedTime)}
                    </div>
                  </div>
                  <div className="w-full flex justify-center items-center text-[#1B1C20] align-center text-center text-14 font-proxima-nova font-normal leading-20 break-words p-4">
                    <div
                      className={`w-max px-3 py-2 rounded ${
                        vehicle.net_price !== null &&
                        vehicle.net_price > priceReminderThreshold
                          ? "price-over-15"
                          : ""
                      }`}
                    >
                      {/* Display the calculated net price with VAT */}
                      {vehicle.net_price !== null ? (
                        <p>{vehicle.net_price} €</p>
                      ) : (
                        <p>0.00 €</p>
                      )}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between bg-white px-4 py-3 sm:px-6">
          <div className="w-full flex justify-center gap-y-4">
            <div>
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={vehicles.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                prevPageText={
                  <Left className="-ml-0.5 h-5 w-5 pr-1" aria-hidden="true" />
                }
                nextPageText={
                  <Right className="-ml-0.5 h-5 w-5 " aria-hidden="true" />
                }
                itemClass="w-10 text-center"
                hideFirstLastPages
              />
            </div>
            <div className="p-2 text-center text-14 font-proxima-nova font-normal leading-20 break-words">
              {t("Total")} <b>{vehicles.length}</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FleetContainer;
