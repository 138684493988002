import {useT} from "../../components/languages/t";
import { useState, useMemo } from 'react'
import {useResource} from "../../useResource";
import {displayStatusBadge} from "./vehicleStatusBadge";
import {useList, useReloadList} from "../../useList";
import {Table} from "../../components/table/table";
import FormButtonRow from "../../components/form/FormButtonRow";
import FormDetails from "../../components/form/FormDetails";
import FormTitle from "../../components/form/FormTitle";
import Form from "../../components/form/Form";
import { useEffect } from 'react';
import {useLocation} from "wouter";

import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import UserStatusBadge from "../users/userStatusBadge";
import TextArea from "../../components/formElements/TextArea";
import Stats from "../../components/stats/Stats";
import StatItem from "../../components/stats/StatItem";
import TextInput from "../../components/formElements/TextInput";
import SelectBox from "../../components/formElements/Select";
import euCountries from "../../components/euCountries";
import {useT} from "../../components/languages/t";
import * as y from "yup";
import {useValidate} from "../../components/form/useValidate";
import Spinner from "../../components/spinner/Spinner";
import VehicleSpinner from "../../components/spinner/VehicleSpinner";
import FormSaveMessage from "../../components/form/FormSaveMessage";

const re = /(\d{3,4})(\d{4})(\d{4})(\d{4})/
const re2 = new RegExp(/([a-z])(?=[0-9])/ig);
const re3 = new RegExp(/([0-9])(?=[a-z])/ig);

const subject_statuses = {
  'INACTIVE' : displayStatusBadge('INACTIVE'),
  'ACTIVE': displayStatusBadge('ACTIVE')
}

const country_list = Object.values(euCountries).reduce((r, v) => {  r[v.isoCode] = v.name; return r }, {})

const vehicleSchema = y.object({
  status: y.string().oneOf(['INACTIVE', 'ACTIVE']),
  subject: y.string().required().label("Vehicle"),
  country: y.string().required().length(2).label("Country"),
  fleet_id: y.string().required().label("Fleet")
});

const VehicleDetail = (props) => {

  const { t, i18n } = useT();

  const [_, navigate] = useLocation();

  const {state, handleChanges, handleReset, handleSave, handleDelete, isLoading} = useResource(`/cars/${props.params.vehicleId}`)
  const data = Object.assign({}, state.data, state.changes);

  const { errors } = useValidate(state, vehicleSchema)

  const {list: fines, handleListQuery, clearListQuery} = useList('/fines/list')
  const { list: vehicles, reloadListQuery} = useReloadList('/cars/list')

  const [editMode, setEditMode] = useState(false)
  const [serverResult, setServerResult] = useState(null);

  useEffect(() => {

    if(props.params.vehicleId === 'new' && editMode === false) {
      setEditMode(true);
    }

  }, [
    props.params.vehicleId
  ])

  const cols = useMemo(() => {
      return [{
        header: t(["numberColumn", "Fine Number"]),
        id: "number",
        accessorFn: (r) => {
          return <span className="whitespace-nowrap tabular-nums">{r.number.replace(re,'$1 $2 $3 $4')}</span>
        }
      }, {
        header: t(["statusColumn", "Status"]),
        accessorKey: "status"
      }, {
        header: t(["issuedAtColumn", "Issued At"]),
        id: "issued_at",
        accessorFn: (r) => {
          if(!r.issued_at) {
            return '--';
          }
          return format(parseISO(r.issued_at), 'dd.MM.yyyy HH:mm');
        }
      }, {
        header: t(["locationColumn", "Location"]),
        accessorKey: "locality_name"
      }, {
        header: t(["fineAmountColumn", "Fine Amount"]),
        accessorKey: "amount_due"
      }, {
        header: t(["paidAmountColumn", "Paid Amount"]),
        accessorKey: "amount_paid"
      }
      ]
    },
    [i18n.language])


  const fleets = Object.values(state.related.fleets || {}).reduce((r, v) => {  r[v.id] = v.name; return r }, {})


  useEffect(() => {

    if(data.subject) {
      console.log('init handleListQuery',data.subject )
      handleListQuery({
        filters: { 'subject': data.subject }
      })
    } else {
      clearListQuery();
    }
    setServerResult(null)
  },[data.subject])


  if (Object.keys(data).length === 0 || isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center max-h-full min-h-full bg-white p-6 place-items-center">
        <VehicleSpinner />
      </div>
    );
  }

  const onRowClick = (row) => {
    navigate(`/fines/${row.id}`);
  }

  if(Object.keys(data).length === 0) {
    return <div className="flex flex-1 items-center justify-center max-h-full min-h-full bg-white p-6 place-items-center">
      <Spinner />
    </div>
  }

  console.log('VehicleDetail', props, data, editMode, fleets)

  return <Form state={state}>
    <FormButtonRow state={state} errors={errors} editMode={editMode} onEditMode={() => { setEditMode(!editMode) }} onSave={() => { handleSave().then(() => {
      reloadListQuery();
    }).catch((e) => {
      setServerResult({ title: t('Error saving Vehicle'), error: e })
    }) }} onCancel={handleReset} onDelete={() => { handleDelete().then(() => {
      reloadListQuery();
      navigate('/vehicles')
    }) }}></FormButtonRow>
    <FormTitle title={String(data.subject).replace(/\s/g, '').replace(re2, "$1 ").replace(re3, "$1 ")} subtitle={data.fleet_name}/>
    <FormSaveMessage {...serverResult} />
    <FormDetails editMode={editMode}  data={{
      "status": {
        "label": t("Status"),
        "edit": <SelectBox id="status" value={data.status} placeholder="Please select a status" options={subject_statuses} onChange={(v) => { handleChanges('status')(v) }}  />,
        "display": displayStatusBadge(data.status),
        "required": true,
        "errorMessage": errors['status']
      },
      "subject": {
        "label": t("Vehicle"),
        "edit": <TextInput id="subject" value={data.subject} placeholder="Please fill the vehicle reg. number" isInvalid={!!errors['subject']} isTouched={!!state.changes['subject']} onChange={handleChanges('subject')}  />,
        "required": true,
        "errorMessage": errors['subject']
      },
      "country": {
        "label": t("Country"),
        "edit": <SelectBox id="country" value={data.country} placeholder="Country vehicle is registered at" isInvalid={!!errors['country']} isTouched={!!state.changes['country']} options={country_list} onChange={(v) => { handleChanges('country')(v) }}  />,
        "required": true,
        "errorMessage": errors['country']
      },
      "fleet": {
        "label": t("Fleet"),
        "edit": <SelectBox id="fleet_id" value={data.fleet_id} placeholder="Please choose a fleet" isInvalid={!!errors['fleet']} isTouched={!!state.changes['fleet']} options={fleets} onChange={(v) => { handleChanges('fleet_name')(fleets[v]); handleChanges('fleet_id')(v) }}  />,
        "required": true,
        "errorMessage": errors['country']
      },
      "description": {
        "label": t("Description"),
        "display": data.description,
        "edit": <TextArea id="description" value={data.description} placeholder="Please fill in a description" onChange={handleChanges('description')} />
      },
    }}/>
    <div className="-mx-3">
      <Stats>
        <StatItem title={t("Total Fines")} value={data.total_fines} />
        <StatItem title={t("Unpaid Fines")} value={data.unpaid_fines} />
        <StatItem title={t("Amount Due")} value={`${data.amount_due} €`} />
        <StatItem title={t("Amount Paid")} value={`${data.amount_paid} €`} />
      </Stats>
    </div>
    <div className="-mx-6">
    <Table data={fines.data}
           columns={cols}
           totalHits={fines.totalHits}
           page={fines.page}
           lastPage={fines.totalHits >= 0 ? fines.lastPage + 1: 0}
           pageSize={fines.pageSize}
           sortBy={""}
           sortDesc={false}
           fetchData={(p) => {
             if(data.number_plate) {
               p['filters'] = Object.assign({},p['filters'], { 'vehicle_reg': data.number_plate })
               return handleListQuery(p)
             }
           }}
           onRowClick={onRowClick}/>
    </div>
  </Form>

}

export default  VehicleDetail;
